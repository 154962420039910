import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_helpdesk_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { createTicketFailure, createTicketSuccess } from '../../actions/helpdeskServiceActions/createTicketAction';

function* createTicket({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_helpdesk_service,
            endPoint: endpoints.CreateTicket,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("CREATE_TICKET_SUCCESS status :", response.status);
        console.log("CREATE_TICKET_SUCCESS data :", response.data);
        console.log("CREATE_TICKET_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        yield put(createTicketSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("CREATE_TICKET_FAILURE error :", error.response?.status);
        console.log("CREATE_TICKET_FAILURE error :", error.message);
        console.log('====================================');
        yield put(createTicketFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* createTicketSaga() {
    yield takeLatest(actionTypes.CREATE_TICKET_REQUEST, createTicket);
}

export default createTicketSaga;