import { actionTypes } from '../../types';

export const emailInvoice = (payload) => ({
    type: actionTypes.EMAIL_INVOICE_REQUEST,
    payload,
});

export const emailInvoiceSuccess = (payload) => ({
    type: actionTypes.EMAIL_INVOICE_SUCCESS,
    payload,
});

export const emailInvoiceFailure = (payload) => ({
    type: actionTypes.EMAIL_INVOICE_FAILURE,
    payload,
});

export const emailInvoiceReset = () => ({
    type: actionTypes.EMAIL_INVOICE_RESET,
});

export const emailInvoiceResetAll = () => ({
    type: actionTypes.EMAIL_INVOICE_RESET_ALL,
}); 