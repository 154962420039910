import React from 'react';
import { useNavigate } from 'react-router-dom';
import { base_url_strapi_service } from '../../../store/constants';

const CheckoutCategoriesCard = ({ image, title, isTitleShow }) => {
    const navigate = useNavigate()
    return (
        <div className="overflow-hidden relative flex flex-col justify-center items-center">
            <img src={base_url_strapi_service + image} className="w-[33.9rem] h-[37.2rem] object-cover rounded-[1rem]" />
            <h2 className={`urbanist-bold text-white text-center text-[3.2rem] absolute top-[35%] w-full h-full ${isTitleShow ? "hidden" : ""}`}>
                {title}
            </h2>
            <div className={`urbanist-bold text-white text-center text-[2.4rem] mt-[1rem] ${!isTitleShow ? "hidden" : ""}`}>
                {title}
            </div>
        </div>
    );
};

export default CheckoutCategoriesCard;
