import { actionTypes } from '../../types';

export const Geocode = (payload) => ({
    type: actionTypes.GEOCODE_REQUEST,
    payload,
});

export const GeocodeSuccess = (payload) => ({
    type: actionTypes.GEOCODE_SUCCESS,
    payload,
});

export const GeocodeFailure = (payload) => ({
    type: actionTypes.GEOCODE_FAILURE,
    payload,
});

export const GeocodeReset = () => ({
    type: actionTypes.GEOCODE_RESET,
});

export const GeocodeResetAll = () => ({
    type: actionTypes.GEOCODE_RESET_ALL,
}); 