import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_leadgen_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { contactUsFailure, contactUsSuccess } from '../../actions/leadgenServiceActions/contactUsActions';
// import store from '../../configStore';

function* contactUs({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_leadgen_service,
            endPoint: endpoints.ContactUs,
            data: payload,
            isAuth: false,
        });

        console.log('====================================');
        console.log("CONTACT_US_SUCCESS status :", response.status);
        console.log("CONTACT_US_SUCCESS data :", response.data);
        console.log("CONTACT_US_SUCCESS message :", response.data.statusmessage);
        console.log('====================================');
        yield put(
            contactUsSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("CONTACT_US_FAILURE status :", error?.response?.status);
        console.log("CONTACT_US_FAILURE data:", error?.response?.data);
        console.log("CONTACT_US_FAILURE error :", error);
        console.log('====================================');
        yield put(contactUsFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* contactUsSaga() {
    yield takeLatest(actionTypes.CONTACT_US_REQUEST, contactUs);
}

export default contactUsSaga;