import React, { useEffect, useRef, useState } from 'react'
import FeaturedArticlesCard from '../FeaturedArticles/FeaturedArticlesCard'
import { CommunityImg } from '../../../static/constants/imgConstants';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const AllBlogsComponent = ({ cards, initialTextAlign, loadMoreBlogs }) => {

    const navigate = useNavigate();

    const { category } = useParams()

    const priorityCards = cards?.filter(member => [5, 6, 7].includes(member.sequence));
    const otherCards = cards?.filter(member => ![5, 6, 7].includes(member.sequence));

    const communitySubCategoriesReducerData = useSelector((state) => state.communitySubCategoriesReducer?.data);


    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState("All");
    const [selectedAuthor, setSelectedAuthor] = useState();

    const [sortOrder, setSortOrder] = useState("NewestToOldest");

    const filterRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(false);
            }
        };

        if (showFilter) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showFilter]);


    const handleSort = (order) => {
        setSortOrder(order);
    };

    const sortCards = (cardsToSort) => {
        return [...cardsToSort]?.sort((a, b) => {
            const now = moment();
            const dateA = moment(a.published_at);
            const dateB = moment(b.published_at);

            let filteredCards = [...cardsToSort];

            /* if (author) {
                filteredCards = filteredCards.filter(card => card.author?.name === author);
            } */


            if (sortOrder === "NewestToOldest") {
                return dateB - dateA;
            } else if (sortOrder === "OldestToNewest") {
                return dateA - dateB;
            } else if (sortOrder === "ThisWeek") {
                filteredCards = filteredCards.filter((card) =>
                    moment(card.published_at).isSame(now, "week")
                );
            } else if (sortOrder === "ThisMonth") {
                filteredCards = filteredCards.filter((card) =>
                    moment(card.published_at).isSame(now, "month")
                );
            } else if (sortOrder === "ThisYear") {
                filteredCards = filteredCards.filter((card) =>
                    moment(card.published_at).isSame(now, "year")
                );
            }



            return filteredCards.sort((a, b) => {
                const dateA = moment(a.published_at);
                const dateB = moment(b.published_at);

                if (sortOrder === "NewestToOldest") {
                    return dateB - dateA;
                }
                else if (sortOrder === "OldestToNewest") {
                    return dateA - dateB;
                }

                else {
                    return 0; // No sorting
                }
            });
        });
    };

    const handleByAuthor = (cards, author) => {

        let filteredCards = [...cards];

        if (author) {
            filteredCards = filteredCards.filter(card => card.author?.name === author);
        }

        return filteredCards
    }

    const sortedPriorityCards = sortCards(priorityCards);
    const sortedOtherCards = sortCards(otherCards);
    const filteredPriorityCards = handleByAuthor(sortedPriorityCards, selectedAuthor);
    const filteredOtherCards = handleByAuthor(sortedOtherCards, selectedAuthor);


    return (
        <div className="px-[4rem] my-[10rem]">
            <div className="container-lg">
                <div className="FeaturedArticles text-white w-full h-full justify-center items-center relative">
                    {showFilter && (
                        <div ref={filterRef} className="absolute top-[14rem] right-0 border-2 border-gray w-[45%] bg-black rounded-[2rem] px-[4rem] py-[2rem] z-[20]">
                            <div className="">
                                <div className="text-[1.8rem]">Category</div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-[1.6rem] text-center mt-[0.5rem]">
                                    {/* <div className={`border-2 rounded-full px-[0.5rem] w-full cursor-pointer ${filter == "All" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`} onClick={() => { setFilter("All"); navigate(`/community`); }}>All</div> */}
                                    {communitySubCategoriesReducerData?.filter((item) => { return item.sub_category_name })?.map((items) => {
                                        return (
                                            <div
                                                className={`border-2 border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${filter == items.sub_category_name || items.sub_category_name == category ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"} flex justify-center items-center`}
                                                onClick={() => {
                                                    setFilter(items.sub_category_name);
                                                    setSelectedAuthor();
                                                    navigate(`/community/${items.sub_category_name}`);
                                                }}
                                            >
                                                {items.sub_category_name}
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            <div className="my-[2rem]">
                                <div className="text-[1.8rem]">Sort by Date</div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-[1.6rem] text-center mt-[0.5rem]">
                                    <div
                                        className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${sortOrder == "NewestToOldest" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                        onClick={() => handleSort("NewestToOldest")}
                                    >
                                        Newest to Oldest
                                    </div>
                                    <div
                                        className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${sortOrder == "OldestToNewest" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                        onClick={() => handleSort("OldestToNewest")}
                                    >
                                        Oldest to Newest
                                    </div>
                                    <div
                                        className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${sortOrder == "ThisWeek" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                        onClick={() => handleSort("ThisWeek")}
                                    >
                                        This Week
                                    </div>
                                    <div
                                        className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${sortOrder == "ThisMonth" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                        onClick={() => handleSort("ThisMonth")}
                                    >
                                        This Month
                                    </div>
                                    <div
                                        className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${sortOrder == "ThisYear" ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                        onClick={() => handleSort("ThisYear")}
                                    >
                                        This Year
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="text-[1.8rem]">Sort by Author</div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-[1.6rem] text-center mt-[0.5rem]">
                                    {Array.from(
                                        new Set(cards?.filter(item => item?.author).map(item => item?.author?.name))
                                    ).map((authorName, index) => (
                                        <div
                                            key={index}
                                            className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer ${selectedAuthor === authorName ? "border-[#FFD3D7] text-[#FFD3D7]" : "border-gray"}`}
                                            onClick={() => { setSelectedAuthor(authorName); }}
                                        >
                                            {authorName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-[2rem]">
                                <div className="text-[1.8rem]">Sort by Popularity</div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-[1.6rem] text-center mt-[0.5rem]">
                                    <div className={`border border-gray rounded-full px-[0.5rem] w-full cursor-pointer`}>Most Read</div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                    <div className="flex justify-between items-center">
                        <div className={`flex justify-start items-center my-[1rem] h-full [@media(max-width:440px)]:text-[3rem] text-[6rem] md:text-[9rem] lg:text-[13.3rem] ${initialTextAlign == "left" ? "text-center lg:text-left" : "text-center"}`}>
                            <h2 className="bordered-font text-transparent urbanist-bold">{cards[0]?.sub_category?.TitlePrimary}&nbsp;</h2>
                            <span className="text-[#FAFF00] urbanist-extrabold">{cards[0]?.sub_category?.TitleSecondary}</span>
                        </div>
                        <div className="cursor-pointer" onClick={() => setShowFilter(!showFilter)}>
                            <img src={CommunityImg.filter} alt="" className="w-[3.9rem] h-[4rem]" />
                        </div>
                    </div>
                    {cards?.length > 0 ? (
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 min-h-[50rem]">
                            {(selectedAuthor ? filteredPriorityCards : sortedPriorityCards)?.map((member, index) => {
                                if (!loadMoreBlogs && index < 9) {
                                    return <FeaturedArticlesCard key={index} member={member} />
                                } else if (loadMoreBlogs) {
                                    return <FeaturedArticlesCard key={index} member={member} />
                                }
                            }
                            )}
                            {(selectedAuthor ? filteredOtherCards : sortedOtherCards)?.map((member, index) => {
                                if (!loadMoreBlogs && index < 5) {
                                    return <FeaturedArticlesCard key={index} member={member} />
                                } else if (loadMoreBlogs) {
                                    return <FeaturedArticlesCard key={index} member={member} />
                                }
                            }
                            )}
                        </div>
                    ) : (
                        <div className="text-white text-[3rem] text-center">
                            No Articles Available
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default AllBlogsComponent;
