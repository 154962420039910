import { actionTypes } from '../../types';

export const offerSearch = (payload) => ({
    type: actionTypes.OFFER_SEARCH_REQUEST,
    payload,
});

export const offerSearchSuccess = (payload) => ({
    type: actionTypes.OFFER_SEARCH_SUCCESS,
    payload,
});

export const offerSearchFailure = (payload) => ({
    type: actionTypes.OFFER_SEARCH_FAILURE,
    payload,
});

export const offerSearchReset = () => ({
    type: actionTypes.OFFER_SEARCH_RESET,
});

export const offerSearchResetAll = () => ({
    type: actionTypes.OFFER_SEARCH_RESET_ALL,
}); 