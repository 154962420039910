import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime, resendOtp }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setIsResendDisabled(false);
        }
    }, [timeLeft]);

    const handleResend = () => {
        console.log('OTP resent');
        setTimeLeft(initialTime);
        setIsResendDisabled(true);
        resendOtp();
    };

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    return (
        <div className='flex my-[2rem] items-center'>
            <button 
                className={`${timeLeft == 0 ? "text-[#0000FF]" : "text-black"} hover:text-sky-500 hover:decoration-sky-500 text-[2rem] urbanist-semibold text-center underline decoration-2 border-0 outline-0 hover:border-0 hover:outline-0 focus:border-0 focus:outline-0 mx-auto ${isResendDisabled ? "cursor-wait" : "cursor-pointer"}`}
                onClick={handleResend} 
                disabled={isResendDisabled}
            >
                Resend OTP &nbsp; 00:{formatTime(timeLeft)}
            </button>
        </div>
    );
};

export default CountdownTimer;
