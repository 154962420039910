import React from 'react';
import { DownloadAppLandingImg } from '../../../static/constants/imgConstants';
import "./AppBanner3.css"

const AppBanner3 = () => {
  return (
    <div className='app-banner-3-bg px-[4rem]'>
      <div className="container-lg">
        <div className="h-max text-white flex flex-col md:flex-row items-center justify-center p-4 my-[5rem]">
          <div className="md:w-1/2 flex justify-center md:justify-end md:px-16">
            <img
              src={DownloadAppLandingImg.AppBanner3}
              alt="Dance"
              className="w-full h-full"
            />
          </div>
          <div className="mt-8 md:mt-0 md:w-1/2 text-start">
            <h1 className="text-[6.4rem] font-bold text-[#80C241]">
              Group Sessions for fun <br /> & fitness
            </h1>
            <p className="text-[2.4rem] text-[#FFFEFE]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br /> sed do eiusmod tempor
            </p>
            <a href="https://play.google.com/store/apps" target='_blank' className="">
              <button className="mt-8 p-3 bg-[#FAFF00] rounded-full urbanist-semibold text-black text-[1.7rem] px-[3.9rem] py-[1.4rem] md:px-12 lg:px-16">
                Join Us
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBanner3;
