import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { subscriptionFailure, subscriptionSuccess } from '../../actions/SubscriptionManagementActions/subscriptionActions';
// import store from '../../configStore';

function* subscription({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.Subscription + `?sortfield=Title&pagesize=10&pagenumber=1&order=1`,
            isAuth: false,
        });

        console.log('====================================');
        console.log("SUBSCRIPTION_SUCCESS status :", response.status);
        console.log("SUBSCRIPTION_SUCCESS data :", response.data);
        console.log("SUBSCRIPTION_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            subscriptionSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("SUBSCRIPTION_FAILURE status :", error?.response?.status);
        console.log("SUBSCRIPTION_FAILURE data:", error?.response?.data);
        console.log("SUBSCRIPTION_FAILURE error :", error);
        console.log('====================================');
        yield put(subscriptionFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* subscriptionSaga() {
    yield takeLatest(actionTypes.SUBSCRIPTION_REQUEST, subscription);
}

export default subscriptionSaga;