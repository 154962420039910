import { actionTypes } from '../../types';

export const pauseSubscription = (payload) => ({
    type: actionTypes.PAUSE_SUBSCRIPTION_REQUEST,
    payload,
});

export const pauseSubscriptionSuccess = (payload) => ({
    type: actionTypes.PAUSE_SUBSCRIPTION_SUCCESS,
    payload,
});

export const pauseSubscriptionFailure = (payload) => ({
    type: actionTypes.PAUSE_SUBSCRIPTION_FAILURE,
    payload,
});

export const pauseSubscriptionReset = () => ({
    type: actionTypes.PAUSE_SUBSCRIPTION_RESET,
});

export const pauseSubscriptionResetAll = () => ({
    type: actionTypes.PAUSE_SUBSCRIPTION_RESET_ALL,
}); 