import React, { useState } from "react";
import OTPInput from "react-otp-input";
import "./LoginSec.css";
import { useNavigate } from "react-router-dom";
import { useToast } from '../../../hooks/useToast';
import { useDispatch } from "react-redux";
import { generateOtp } from "../../../store/actions/authActions/generateOTPActions";
import { setVerifiedPhoneNo } from "../../../store/actions/utilsActions/utilsActions";
import { NavbarImg } from "../../../static/constants/imgConstants";
import EnterEmailSec from "../EnterEmailSec/EnterEmailSec";
import EnterOtpSec from "../EnterOtpSec/EnterOtpSec";

const LoginSec = () => {
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const Toast = useToast();
    const [emailPhone, setEmailPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [isOTPSecOpen, setIsOTPSecOpen] = useState(false);

    return (
        <div className="w-full bg-[#1E1E1E] h-[100vh]">
            <div className="lg:container-lg bg-black lg:bg-[#F0F2F5] h-full flex justify-between items-center">
                <div className="flex flex-col justify-center items-center w-full lg:w-[55%] text-[1.4rem] poppins-regular px-[10%] text-[#5A5A5A]">
                    <div className="cursor-pointer" onClick={() => Navigate("/")}>
                        <img src={NavbarImg.LogoLight} alt="" className="lg:hidden w-[22rem] h-[2.7rem] my-[3.2rem]" />
                    </div>
                    <svg
                        width="360"
                        height="71"
                        viewBox="0 0 360 71"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hidden lg:inline my-[3.2rem] cursor-pointer"
                        onClick={() => Navigate("/")}
                    >
                        <path
                            d="M90.9699 23.3197C85.4999 23.3197 83.4299 25.8497 83.4299 31.7797V31.8297H99.2799V38.9497H83.4299V52.0597H74.3999V31.9297C74.3999 21.8097 80.3399 15.9297 90.6599 15.9297H99.6899V23.3097H90.9699V23.3197Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M110.06 52.0597V15.9297H118.94V52.0597H110.06Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M160.9 15.9404V23.5304H149.55V52.0704H140.62V23.5304H129.32V15.9404H160.91H160.9Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M184.39 52.0597V46.5397L203.85 23.5197H184.96V15.9297H216.08V21.4497L196.62 44.4697H216.39V52.0597H184.39Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M274.82 52.0596V31.4696C274.82 21.6096 281.68 15.3096 291.54 15.3096C301.4 15.3096 308.31 21.6096 308.31 31.4696V52.0596H299.48V31.4696C299.48 26.0996 296.18 22.9496 291.53 22.9496C286.88 22.9496 283.68 26.0996 283.68 31.4696V52.0596H274.8H274.82Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M326.2 52.3597C321.2 52.3597 319.26 49.7797 319.26 45.6397V22.9497C319.26 18.8097 321.2 16.1797 326.2 16.1797H345.34V23.2197H332.29C329.43 23.2197 327.11 25.5397 327.11 28.3997V30.7497H344.21V37.7897H327.11V40.1397C327.11 42.9997 329.43 45.3197 332.29 45.3197H345.66V52.3597H326.2Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M168.43 52.0597C171.02 52.0597 173.12 49.9599 173.12 47.3697C173.12 44.7795 171.02 42.6797 168.43 42.6797C165.84 42.6797 163.74 44.7795 163.74 47.3697C163.74 49.9599 165.84 52.0597 168.43 52.0597Z"
                            fill="#0C0C0C"
                        />
                        <path
                            d="M55.6998 34.0004C55.6998 40.4904 52.7098 46.2904 48.0198 50.0804L43.8798 44.0504C46.6698 41.5904 48.4298 38.0004 48.4298 33.9904C48.4298 28.1004 44.6298 23.0904 39.3398 21.3004L40.8498 14.1504C49.4298 16.6604 55.6998 24.6004 55.6998 34.0004Z"
                            fill="#76CEE4"
                        />
                        <path
                            d="M37.44 13.4603L35.93 20.6203C35.63 20.6003 35.32 20.5903 35.02 20.5903C29.22 20.5903 24.29 24.2703 22.41 29.4303L15.25 27.9203C17.85 19.4603 25.71 13.3203 35.02 13.3203C35.84 13.3203 36.65 13.3703 37.44 13.4603Z"
                            fill="#F27421"
                        />
                        <path
                            d="M22.8698 39.6803L16.7398 43.6803C15.1998 40.7903 14.3398 37.5003 14.3398 34.0003C14.3398 33.0903 14.3998 32.2003 14.5098 31.3203L21.6598 32.8303C21.6298 33.2203 21.6098 33.6103 21.6098 34.0103C21.6098 36.0403 22.0598 37.9703 22.8698 39.6903V39.6803Z"
                            fill="#F1E912"
                        />
                        <path
                            d="M25.9199 43.8899L21.7899 49.9099C20.6099 48.9299 19.5499 47.8199 18.6099 46.6099L24.7099 42.6299L25.9099 43.8899H25.9199Z"
                            fill="#FF082C"
                        />
                        <path
                            d="M35.0199 54.6804C37.0302 54.6804 38.6599 53.0507 38.6599 51.0404C38.6599 49.0301 37.0302 47.4004 35.0199 47.4004C33.0096 47.4004 31.3799 49.0301 31.3799 51.0404C31.3799 53.0507 33.0096 54.6804 35.0199 54.6804Z"
                            fill="#80C241"
                        />
                        <path
                            d="M224.87 34.0603C224.87 23.2303 233.07 15.3604 244.36 15.3604C255.65 15.3604 263.98 23.2303 263.98 33.9903C263.98 44.7503 255.78 52.7604 244.36 52.7604C232.94 52.7604 224.87 44.8903 224.87 34.0603ZM255.71 34.0603C255.71 27.1703 250.99 22.7703 244.36 22.7703C237.73 22.7703 233.07 27.1703 233.07 33.9903C233.07 40.8103 237.86 45.4104 244.42 45.4104C250.98 45.4104 255.71 40.8803 255.71 34.0603Z"
                            fill="#0C0C0C"
                        />
                    </svg>
                    <h2 className="text-[3.6rem] poppins-semibold text-white lg:text-[#000]">
                        Welcome
                    </h2>
                    <h3 className="text-[1.8rem] mt-[1rem] text-white lg:text-[#000]">
                        Login into your account
                    </h3>
                    {isOTPSecOpen ?
                        <div className="w-full">
                            <EnterOtpSec setOtp={setOtp} otp={otp} />
                        </div>
                        :
                        <div className="w-full my-[4rem]">
                            <EnterEmailSec setEmailPhone={setEmailPhone} emailPhone={emailPhone} setIsOTPSecOpen={setIsOTPSecOpen} />
                        </div>
                    }
                    {/* <button
                        className="w-full h-[5.5rem] bg-[#20DC49] lg:bg-white rounded-[1rem] text-center border-[.1rem] border-[#5A5A5A] lg:hover:bg-[#FFFFFF]"
                        onClick={() => {

                        }}
                        disabled={!otp && !emailPhone}
                    >
                        Log In
                    </button> */}
                    <div className="lg:hidden backdrop-blur-sm bg-[#FFF2F2]/20 pt-[4rem] pr-[4rem] pl-[4rem] pb-[7rem] rounded-[1rem] my-[9rem]">
                        <div className="bg-[#20DC49] w-[23rem] h-[4.5rem] rounded-[1rem] px-4 mb-4 flex align-center">
                            <h2 className="my-auto text-[2rem]">
                                👍
                                <span className="text-[1.4rem] roboto-medium">Login Today</span>
                            </h2>
                        </div>
                        <div className="left-[2rem] text-[2rem] w-[45rem] poppins-regular text-white">
                            Today, we create innovative solutions to the challenges that
                            consumers face in both their everyday lives and events.
                        </div>
                    </div>
                </div>
                <div className="login-bg-img w-[0%] lg:w-[45%] h-full flex items-end justify-start">
                    <div className="backdrop-blur-sm bg-[#FFF2F2]/20 pt-[4rem] pr-[4rem] pl-[4rem] pb-[7rem] rounded-[1rem] ml-[3.6rem] mb-[5rem]">
                        <div className="bg-[#20DC49] w-[23rem] h-[4.5rem] rounded-[0.8rem] px-4 mb-4 flex align-center">
                            <h2 className="my-auto text-[2rem]">
                                👍
                                <span className="text-[1.4rem] roboto-medium">Login Today</span>
                            </h2>
                        </div>
                        <div className="left-[2rem] text-[2rem] w-[45rem] poppins-regular text-white">
                            Today, we create innovative solutions to the challenges that
                            consumers face in both their everyday lives and events.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginSec;