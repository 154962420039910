import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_gx_class_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { gxSessionFailure, gxSessionSuccess } from '../../actions/GxSessionManagementServiceActions/gxSessionAction';

function* gxSession({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_gx_class_management_service,
            endPoint: endpoints.gxsession + `?searchkey=venueId&searchvalue=${payload.venueId}&sortfield=sessionDate&pagesize=10&%20pagenumber=1&order=1`,
            // data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("GX_SESSION_SUCCESS status :", response.status);
        console.log("GX_SESSION_SUCCESS data :", response.data);
        console.log("GX_SESSION_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        yield put(gxSessionSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("GX_SESSION_FAILURE error :", error.response?.status);
        console.log("GX_SESSION_FAILURE error :", error.message);
        console.log('====================================');
        yield put(gxSessionFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* gxSessionSaga() {
    yield takeLatest(actionTypes.GX_SESSION_REQUEST, gxSession);
}

export default gxSessionSaga;