import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiToken = (payload) => ({
    type: actionTypes.STRAPI_TOKEN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiTokenSuccess = (payload) => ({
    type: actionTypes.STRAPI_TOKEN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiTokenFailure = (payload) => ({
    type: actionTypes.STRAPI_TOKEN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiTokenReset = () => ({
    type: actionTypes.STRAPI_TOKEN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiTokenResetAll = () => ({
    type: actionTypes.STRAPI_TOKEN_RESET_ALL,
}); 