import React, { useEffect } from 'react'
import Footer3 from '../../components/common/Footer3/Footer3'
import PackageImgAndDetails from '../../components/common/PackageImgAndDetails/PackageImgAndDetails'
import PackageStartDate from '../../components/common/PackageStartDate/PackageStartDate'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { refreshToken } from '../../store/actions/authActions/refreshTokenActions'
// import { logout } from '../../store/actions/utilsActions/utilsActions'

const PackagesDetails = () => {

    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    const utilReducers = useSelector((state) => state.utilReducers);
    const getSubscriptionCategoryNameReducerData = useSelector((state) => state.getSubscriptionCategoryNameReducer?.data);
    // const refreshTokenReducerStatus = useSelector((state) => state.refreshTokenReducer.status);


    const { package_type, plan_duration } = useParams();

    /* useEffect(() => {
        dispatch(refreshToken({
            refreshToken: utilReducers?.refreshToken,
            accessToken: utilReducers?.accessToken
        }))
    }, [])

    useEffect(() => {
        if (refreshTokenReducerStatus != 200) {
            dispatch(logout());
            navigate("/");
        }
    }, [refreshTokenReducerStatus]) */




    return (
        <div className='packages-details-background bg-[#1E1E1E] px-[4rem]'>
            <div className="container-lg flex flex-col justify-center items-center lg:items-start w-full pt-[8rem]">
                <div className="urbanist-semibold text-white text-[4.8rem] my-[3rem]">
                    {package_type == "fit-prime" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan" : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan"}
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start w-full">
                    <PackageImgAndDetails points={utilReducers?.plan?.description} image={utilReducers?.plan?.posterimageurl} />
                    <PackageStartDate offerDuration={utilReducers?.plan?.totalDuration} data={utilReducers?.plan} />
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default PackagesDetails