import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetFitnessAssessment, GetFitnessAssessmentReset, GetFitnessAssessmentResetAll } from '../../../store/actions/FitnessAssessementServiceActions/GetFitnessAssessmentAction'
import moment from 'moment'
import { useToast } from '../../../hooks/useToast'

const AssessmentReports = () => {

  const dispatch = useDispatch()
  const Toast = useToast();
  const [overview, setOverview] = useState(false);
  const [reportId, setReportId] = useState("");
  const hasShownProfileToast = useRef(false);


  const utilReducers = useSelector((state) => state.utilReducers);
  const GetFitnessAssessmentReducerData = useSelector((state) => state.GetFitnessAssessmentReducer?.data);
  const GetFitnessAssessmentReducerStatus = useSelector((state) => state.GetFitnessAssessmentReducer?.status);
  const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);

  useEffect(() => {
    const customerProfile = CustomerProfileReducerData?.[0];
    dispatch(GetFitnessAssessmentResetAll());
    dispatch(GetFitnessAssessment({ userId: utilReducers?.userId }))

    if ((!customerProfile?.mobileNumber?.number || !customerProfile?.email) && !hasShownProfileToast.current) {
      alert("Update your profile first!");
      hasShownProfileToast.current = true;
      return;
    }
  }, [CustomerProfileReducerData, utilReducers?.userId, dispatch])

  useEffect(() => {
    if (GetFitnessAssessmentReducerStatus == 200) {
      dispatch(GetFitnessAssessmentReset());
    } else if (GetFitnessAssessmentReducerStatus) {
      dispatch(GetFitnessAssessmentReset());
    }
  }, [GetFitnessAssessmentReducerStatus])

  return (
    <div className='bg-[#000000] h-full'>
      <div className="">
        <div className="container-lg bg-[#1E1E1E] px-[4rem] w-full mt-[10rem] rounded-[0.8rem]">
          <h2 className="text-white text-[3rem] font-bold"><span className="cursor-pointer hover:text-[#FAFF00]" onClick={() => setOverview(false)}>Assessment Reports </span>{overview ? <span className="">{`>`} Assessment Overview</span> : null}</h2>
        </div>
        {overview ?
          GetFitnessAssessmentReducerData.filter((item) => item.id == reportId).map((items) => {
            return (
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-between items-center w-[60%] my-[3rem]">
                  <div className="text-[2rem] text-white font-bold">
                    {items.reportName}
                  </div>
                  <div className="text-[2rem] text-[#F9D774] font-bold">
                    {items.totalScore} %
                  </div>
                </div>
                <div className="flex justify-between items-center w-[60%] my-[3rem]">
                  <div className="text-[2rem] text-white font-bold">
                    Assessment Date
                  </div>
                  <div className="text-[2rem] text-white font-bold">
                    {moment(items?.assessmentDate).format("MMM Do YY")}
                  </div>
                </div>
                <div className="flex justify-between items-center w-[60%] my-[3rem]">
                  <div className="text-[2rem] text-white font-bold">
                    Assessment Venue Name
                  </div>
                  <div className="text-[2rem] text-white font-bold">
                    {items.assessmentVenueName}
                  </div>
                </div>
                <div className="flex justify-between items-center w-[60%] bg-[#4b4b4b] rounded-[2rem]">
                  {items?.summaryData?.map((summary_data) => {
                    return (
                      <div className="flex justify-between items-center my-[3rem]">
                        <div className="mx-10">
                          {summary_data?.value?.map((value) => {
                            return (
                              <div className="text-[2rem] text-[#F9D774] font-bold">
                                {value}
                              </div>
                            )
                          })
                          }
                          <div className="text-[2rem] text-white font-bold my-[1rem]">
                            {summary_data.name}
                          </div>
                          <div className={`text-[1.6rem] text-white w-[60%]`}>
                            {summary_data.name === "Body Fat" ? "Total fat in our body, including fat around organs and under the skin" : "Includes muscle, bone, body water & other tissue weight"}
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            )
          })
          :
          <table class="table-fixed w-full">
            <thead>
              <tr className=''>
                <th className='text-[1.6rem] text-white py-[1rem]'>Report Name</th>
                <th className='text-[1.6rem] text-white py-[1rem]'>Assessment Date</th>
                <th className='text-[1.6rem] text-white py-[1rem]'>Total Progress</th>
              </tr>
            </thead>
            <tbody className='bg-[#1E1E1E] hover:bg-black rounded-[2rem]'>
              {GetFitnessAssessmentReducerData.length > 0 ?
                GetFitnessAssessmentReducerData?.map((data) => {
                  return (
                    <tr className='border-t-4 border-black cursor-pointer hover:bg-[#4b4b4b] my-[1rem]' onClick={() => { setReportId(data.id); setOverview(true) }}>
                      <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                        {data.reportName}
                      </td>
                      <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                        {moment(data?.assessmentDate).format("MMM Do YY")}
                      </td>
                      <td className='text-[1.8rem] text-[#F9D774] font-[600] py-[2rem] text-center'>
                        {data.totalScore} %
                      </td>
                    </tr>
                  )
                })
                :
                <tr className='border-t-4 border-black'>
                  <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                  <td className='text-[1.6rem] text-white py-[2rem] text-center'>No Assessment Available</td>
                  <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}

export default AssessmentReports