import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { generateOtp } from '../../../store/actions/authActions/generateOTPActions';
import { useToast } from '../../../hooks/useToast';
import {setEmail, setVerifiedPhoneNo} from '../../../store/actions/utilsActions/utilsActions';

const EnterMobilePopup = ({ show, onClose, setHandleOutsideClick }) => {
    const dispatch = useDispatch();
    const Toast = useToast();
    const [number, setNumber] = useState('');

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setHandleOutsideClick(true);
        }
    };

    return show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10" onClick={(e) => handleOutsideClick(e)}>
            <div className="bg-[#343434] rounded-[3.5rem] text-white w-[72rem] h-[45.5rem] items-center px-[6rem]">
                <h2 className="text-start sm:text-center urbanist-bold text-[3.6rem] my-[4.8rem]">Log in to your account</h2>
                <div className="flex flex-col justify-start items-start mt-[5rem] mb-[3rem]">
                    <label for="mob-input" className='text-white urbanist-semibold text-[2.4rem] mb-[1.3rem]'>Mobile/Email</label>
                    <input
                        type="text"
                        id='mob-input'
                        className="bg-[#343434] border-[.1rem] border-white outline-none rounded-[1rem] text-[2.6rem] w-full h-[7rem] px-[2.6rem]"
                        value={number}
                        onChange={(e) => { setNumber(e.target.value) }}
                    />
                </div>
                <div className="flex justify-center items-center">
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => {
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            const phonePattern = /^(?!0000000000)\d{10}$/;
                            if (emailPattern.test(number)) {
                                dispatch(setEmail(number))
                                dispatch(
                                    generateOtp({
                                        email: number,
                                        otpGenAttempt: 1
                                    })
                                )
                            } else if (phonePattern.test(number)) {
                                dispatch(setVerifiedPhoneNo({
                                    number: number,
                                    isVerified: false
                                }));
                                dispatch(
                                    generateOtp({
                                        mobileNumber: {
                                            countryCode: "+91",
                                            number: number
                                        },
                                        otpGenAttempt: 1
                                    })
                                )
                            } else {
                                Toast("Please enter a valid email or mobile number");
                                return;
                            };
                            onClose();

                        }}
                    >
                        Get OTP
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EnterMobilePopup
