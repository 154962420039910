import React from 'react'
import MembershipPackages from '../../components/common/MembershipPackages/MembershipPackages';
import NearbyGym from '../../components/common/NearbyGym/NearbyGym';
import WhyChoose from '../../components/common/WhyChoose/WhyChoose';
import Footer3 from '../../components/common/Footer3/Footer3';
import FitCentersLanding from '../../components/common/FitCentersLanding/FitCentersLanding';
import DownloadApp from '../../components/common/DownloadApp/DownloadApp';

function FitCenters() {
    return (
        <div className="bg-[#1E1E1E]">
            <div className="fit-center-bg">
                <FitCentersLanding />
                <MembershipPackages />
                <NearbyGym />
            </div>
            <WhyChoose />
            <div className="hidden md:block">
                <DownloadApp />
            </div>
            <Footer3 />
        </div>
    )
}

export default FitCenters
