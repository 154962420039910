import { actionTypes } from '../../types';

export const unPauseSubscription = (payload) => ({
    type: actionTypes.UN_PAUSE_SUBSCRIPTION_REQUEST,
    payload,
});

export const unPauseSubscriptionSuccess = (payload) => ({
    type: actionTypes.UN_PAUSE_SUBSCRIPTION_SUCCESS,
    payload,
});

export const unPauseSubscriptionFailure = (payload) => ({
    type: actionTypes.UN_PAUSE_SUBSCRIPTION_FAILURE,
    payload,
});

export const unPauseSubscriptionReset = () => ({
    type: actionTypes.UN_PAUSE_SUBSCRIPTION_RESET,
});

export const unPauseSubscriptionResetAll = () => ({
    type: actionTypes.UN_PAUSE_SUBSCRIPTION_RESET_ALL,
}); 