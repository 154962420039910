import React from 'react';
import { TiStarFullOutline, TiStarHalf, TiStarOutline } from "react-icons/ti";
import { RiDoubleQuotesL } from "react-icons/ri";

function TrainerOverviewCard({ name, feedback }) {
    const ratingIcons = [];
    const maxRating = 5;  // Maximum rating is 5

    // Extract the rating value safely, default to 0 if the structure is not as expected
    const ratingValue = feedback[5]?.value?.[0] ?? 0;

    // Ensure the rating value is within a valid range
    const normalizedRating = Math.min(Math.max(ratingValue, 0), 5);

    // Extract full part and decimal part of the rating
    const fullStars = Math.floor(normalizedRating);  // Number of full stars
    const hasHalfStar = normalizedRating % 1 >= 0.5;  // Check if there should be a half star

    // Push full stars
    for (let i = 0; i < fullStars; i++) {
        ratingIcons.push(<TiStarFullOutline key={`full-${i}`} color='#FF4300' size={18} />);
    }

    // Push half star if applicable
    if (hasHalfStar) {
        ratingIcons.push(<TiStarHalf key={`half`} color='#FF4300' size={18} />);
    }

    // Push empty stars to complete the 5-star scale
    const remainingStars = maxRating - fullStars - (hasHalfStar ? 1 : 0);
    for (let i = 0; i < remainingStars; i++) {
        ratingIcons.push(<TiStarOutline key={`empty-${i}`} color='#FF4300' size={18} />);
    }

    console.log("ratingValue:", ratingValue);
    console.log("normalizedRating:", normalizedRating);

    return (
        <div className='min-h-full h-[50rem] relative'>
            <div className="container-lg px-[3rem] absolute sm:flex flex-col justify-end items-start bottom-[23rem] sm:bottom-[11rem]">
                <div className="text-white montserrat-bold text-[4.1rem] sm:text-[2.5rem]">
                    {name}
                </div>
                <div className="flex my-[2.2rem]">
                    {ratingIcons}
                </div>
            </div>
            {feedback ? (
                <div className="bg-[#000000]/80 flex flex-col justify-start items-start absolute bottom-0 h-[20rem] sm:h-[12rem] w-full">
                    <div className="flex flex-col justify-between relative mt-5 mx-10">
                        <RiDoubleQuotesL size={28} color='white' className='absolute' />
                        {feedback.filter((item) => item.name === "specilization").map((data, index) => (
                            <div key={index} className="text-white questrial-regular text-[2.6rem] sm:text-[1.6rem] mx-20 my-2">
                                {data.value.map((value, i) => (
                                    <div key={i}>
                                        {value}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {feedback.filter((item) => item.name === "certifications").map((data, index) => (
                            <div key={index} className="text-white questrial-regular text-[2.6rem] sm:text-[1.6rem] mx-20 my-2">
                                {data.value.map((value, i) => (
                                    <div key={i}>
                                        {value}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex justify-between">
                    <RiDoubleQuotesL size={28} color='white' />
                    <div className="text-white questrial-regular text-[2.6rem] sm:text-[1.6rem] mx-[1.6rem] my-2">
                        {"Customer feedback here Customer feedback here Customer feedback here "}
                    </div>
                </div>
            )}

        </div>
    );
}

export default TrainerOverviewCard;
