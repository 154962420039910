import React from "react";
import FeaturedArticlesCard from "./FeaturedArticlesCard";

function FeaturedArticles({ cards, initialTextAlign, isBlogPage }) {

    return (
        <div className="px-[4rem] my-[10rem]">
            <div className="container-lg">
                <div className="FeaturedArticles text-white w-full h-full justify-center items-center">
                    <div className={`flex justify-center items-center my-[1rem] h-full [@media(max-width:340px)]:text-[5.6rem] text-[6rem] md:text-[9rem] lg:text-[13.3rem] ${initialTextAlign == "left" ? "text-center lg:text-left" : "text-center"}`}>
                        <h2 className="bordered-font text-transparent urbanist-bold">Featured &nbsp;</h2>
                        <span className="text-[#FAFF00] urbanist-extrabold">Articles </span>
                    </div>
                    {cards?.length > 0 ?
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
                            {
                                cards?.map((member, index) => {
                                    if (index <= 4 && index > 0) {
                                        return <FeaturedArticlesCard key={index} member={member} />
                                    } else {
                                        return
                                    }
                                }
                                )
                            }
                        </div>
                        :
                        <div className="text-white text-[3rem] text-center">
                            No Articles Available
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default FeaturedArticles