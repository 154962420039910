import React from "react";
import FeaturedArticlesCard from "../FeaturedArticles/FeaturedArticlesCard";

function Workout({ cards, title1, isBlogPage }) {
    return (
        <div className="px-[4rem]">
            <div className="container-lg">
                <div className="FeaturedArticles text-white w-full h-full justify-center items-center">
                    <div className="text-[#ffffff] text-[4.8rem] text-center my-[2rem]">
                        <h2 className="urbanist-bold">{title1}</h2>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
                        {cards.map((member, index) => (
                            <FeaturedArticlesCard key={index} member={member} isBlogPage={isBlogPage} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Workout