import React from "react";
import AboutUsGridSec1 from "./AboutUsGridSec1";
import AboutUsGridSec2 from "./AboutUsGridSec2";
import AboutUsGridSec3 from "./AboutUsGridSec3";
import AboutUsGridSec4 from "./AboutUsGridSec4";
import AboutUsGridSec5 from "./AboutUsGridSec5";
import './AboutUsGrid.css'

const AboutUsGrid = () => {
  return (
    <div className="bg-black text-white">
      <div className="h-full">
        <AboutUsGridSec1 />
        <AboutUsGridSec2 />
        <AboutUsGridSec3 />
        <AboutUsGridSec4 />
        <AboutUsGridSec5 />
      </div>
    </div>
  );
};

export default AboutUsGrid;
