import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { strapiTokenFailure, strapiTokenSuccess } from '../../actions/StrapiServiceActions/strapiTokenAction';

function* strapiToken({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiToken,
            data: payload,
            // isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_TOKEN_SUCCESS status :", response.status);
        console.log("STRAPI_TOKEN_SUCCESS data :", response.data);
        console.log("STRAPI_TOKEN_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            strapiTokenSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_TOKEN_FAILURE status :", error?.response?.status);
        console.log("STRAPI_TOKEN_FAILURE data:", error?.response?.data);
        console.log("STRAPI_TOKEN_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiTokenFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* strapiTokenSaga() {
    yield takeLatest(actionTypes.STRAPI_TOKEN_REQUEST, strapiToken);
}

export default strapiTokenSaga;