import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_user_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { refreshTokenFailure, refreshTokenSuccess } from '../../actions/authActions/refreshTokenActions';
import { actionTypes } from '../../types';
import { setAccessToken, setRefreshToken } from '../../actions/utilsActions/utilsActions';

function* refreshToken({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_user_management_service,
            endPoint: endpoints.RefreshToken,
            data: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("REFRESH_TOKEN_SUCCESS status :", response.status);
        console.log("REFRESH_TOKEN_SUCCESS data :", response.data);
        console.log("REFRESH_TOKEN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(refreshTokenSuccess({
            status: response?.status,
            data: response?.data
        }));
        yield put(setAccessToken(response?.data?.token?.accessToken));
        yield put(setRefreshToken(response?.data?.token?.refreshToken))
    } catch (error) {
        console.log('====================================');
        console.log("REFRESH_TOKEN_FAILURE error :", error.response?.status);
        console.log("REFRESH_TOKEN_FAILURE error :", error.response);
        console.log('====================================');
        yield put(setAccessToken(''));
        yield put(refreshTokenFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* refreshTokenSaga() {
    yield takeLatest(actionTypes.REFRESH_TOKEN_REQUEST, refreshToken);
}

export default refreshTokenSaga;
