import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_customer_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { updateProfileFailure, updateProfileSuccess } from '../../actions/CustomerManagementServiceActions/updateProfileAction';

function* updateProfile({ payload }) {
    try {
        // Make an API call to generate OTP
        const response = yield call(AxiosService, {
            method: 'put',
            base_url: base_url_customer_management_service,
            endPoint: endpoints.UpdateProfile,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("UPDATE_PROFILE_SUCCESS status :", response.status);
        console.log("UPDATE_PROFILE_SUCCESS data :", response.data);
        console.log("UPDATE_PROFILE_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        // Dispatch success action with the response data
        yield put(updateProfileSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message ? response?.message : '',
        }));
    } catch (error) {
        console.log('====================================');
        console.log("UPDATE_PROFILE_FAILURE error :", error?.response);
        console.log("UPDATE_PROFILE_FAILURE error :", error?.response?.data);
        console.log('====================================');
        // Dispatch failure action with the error message
        yield put(updateProfileFailure({
            status: error?.response.status,
            message: error?.response?.statusText
        }));
    }
}

function* updateProfileSaga() {
    yield takeLatest(actionTypes.UPDATE_PROFILE_REQUEST, updateProfile);
}

export default updateProfileSaga;