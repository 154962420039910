import { actionTypes } from '../../types';

export const gxSession = (payload) => ({
    type: actionTypes.GX_SESSION_REQUEST,
    payload,
});

export const gxSessionSuccess = (payload) => ({
    type: actionTypes.GX_SESSION_SUCCESS,
    payload,
});

export const gxSessionFailure = (payload) => ({
    type: actionTypes.GX_SESSION_FAILURE,
    payload,
});

export const gxSessionReset = () => ({
    type: actionTypes.GX_SESSION_RESET,
});

export const gxSessionResetAll = () => ({
    type: actionTypes.GX_SESSION_RESET_ALL,
}); 