import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input';
import CountdownTimer from '../../utils/CountdownTimer/CountdownTimer';
import { useDispatch, useSelector } from 'react-redux';
import { generateOtp, generateOtpReset } from '../../../store/actions/authActions/generateOTPActions';
import { useToast } from '../../../hooks/useToast';
import { validateOtp, validateOtpReset } from '../../../store/actions/authActions/validateOtpActions';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { setEmail, setResendOtpTime, setUpdateProfile } from '../../../store/actions/utilsActions/utilsActions';
import moment from 'moment';

const EnterOtpSec = ({ setOtp, otp }) => {
    const [screenSize] = useState(window.innerWidth);

    const dispatch = useDispatch();
    const Toast = useToast();
    const Navigate = useNavigate()

    const utilReducers = useSelector((state) => state.utilReducers);
    const GenerateOtpReducerStatus = useSelector((state) => state.generateOtpReducer?.status);
    const GenerateOtpReducerMessage = useSelector((state) => state.generateOtpReducer?.message);
    const validateOtpReducerStatus = useSelector((state) => state.validateOtpReducer?.status);
    const validateOtpReducerData = useSelector((state) => state.validateOtpReducer?.data);
    const validateOtpReducerMessage = useSelector((state) => state.validateOtpReducer?.message);
    const validateOtpReducerLoading = useSelector((state) => state.validateOtpReducer?.loading);

    console.log("utilReducers.resendOtpTime :", utilReducers.resendOtpTime);

    function isBefore15Minutes() {

        const currentTime = new Date();
        const givenTime = new Date(utilReducers.resendOtpTime);

        // Calculate the difference in milliseconds
        const timeDifference = currentTime - givenTime;

        console.log("utilReducers timeDifference :", timeDifference);


        // Return time deffrence
        return timeDifference;
    }

    const resendOtp = () => {
        console.log("resendOtp function works");
        let tempOtpGenAttempt = 2
        if (!utilReducers.resendOtpTime) {
            dispatch(setResendOtpTime(new Date()))
            tempOtpGenAttempt = 2
        } else if (isBefore15Minutes() < 15 * 60 * 1000) {
            tempOtpGenAttempt = 3
        } else {
            dispatch(setResendOtpTime(null))
            tempOtpGenAttempt = 1
        }
        if (utilReducers.email) {
            dispatch(generateOtp({
                email: utilReducers.email,
                otpGenAttempt: tempOtpGenAttempt
            }))
        } else if (utilReducers.phoneNumber.number) {
            dispatch(generateOtp({
                mobileNumber: {
                    countryCode: "+91",
                    number: utilReducers.phoneNumber.number
                },
                otpGenAttempt: tempOtpGenAttempt
            }))
        }
    }

    useEffect(() => {
        if (GenerateOtpReducerStatus == 200) {
            dispatch(generateOtpReset());
            Toast(GenerateOtpReducerMessage)
        } else if (GenerateOtpReducerStatus) {
            Toast(GenerateOtpReducerMessage)
            dispatch(generateOtpReset());
        }
    }, [GenerateOtpReducerStatus])

    useEffect(() => {
        if (validateOtpReducerStatus == 200) {
            Toast(validateOtpReducerMessage)
            // dispatch(setEmail(validateOtpReducerData.))
            dispatch(validateOtpReset());
            dispatch(setUpdateProfile(true));
            Navigate("/");
        } else if (validateOtpReducerStatus) {
            Toast(validateOtpReducerMessage)
            dispatch(validateOtpReset());
        }
    }, [validateOtpReducerStatus])

    console.log("validateOtpReducerData :", validateOtpReducerData);
    console.log("GenerateOtpReducerMessage", GenerateOtpReducerMessage);




    return (
        <div>
            <div className="">
                <label className="text-start text-white lg:text-black" htmlFor="otp">OTP</label>
                <div className="login-otp w-full flex justify-between items-center mt-[.8rem]">
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        id='otp-input'
                        numInputs={4}
                        renderSeparator={<span></span>}
                        inputStyle={{
                            borderRadius: "1rem",
                            borderWidth: '1px',
                            borderColor: "#D9D9D9",
                            backgroundColor: screenSize < 992 ? "#000000" : "#FFFFFF",
                            padding: "1.5rem",
                            width: "7rem",
                            fontFamily: "urbanist",
                            fontWeight: 600,
                            fontSize: "2.6rem",
                            outline: "none",
                            color: screenSize < 992 ? "#ffffff" : "#5A5A5A"
                        }}
                        shouldAutoFocus={true}
                        inputType={"number"}
                        renderInput={(props) => <input className='' {...props} />}
                    />
                </div>
            </div>
            <div className="text-center my-[3.8rem]">
                {/* <div className="underline text-white lg:decoration-[#0237F3] lg:text-[#0237F3]" onClick={() => intervalId}>
                    Resend OTP 00:{interval}
                </div> */}
                <CountdownTimer initialTime={30} resendOtp={() => resendOtp()} />
            </div>
            <button
                className="w-full h-[5.5rem] bg-[#20DC49] lg:bg-white rounded-[1rem] text-center border-[.1rem] border-[#5A5A5A] lg:hover:bg-[#FFFFFF]"
                onClick={() => {
                    dispatch(validateOtp({ "userId": utilReducers?.userId, "otp": otp }))
                }}
                disabled={!otp}
            >
                {
                    validateOtpReducerLoading ?
                        <div className="flex justify-center items-center w-full">
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#000000"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                        :
                        "Log In"
                }
            </button>
        </div>
    )
}

export default EnterOtpSec