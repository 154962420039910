import { put, call, takeLatest } from 'redux-saga/effects';
import { UAT_PAY_API_URL } from '../../constants';
import { AxiosPGService } from '../../services';
import { actionTypes } from '../../types';
import { makePaymentFailure, makePaymentSuccess } from '../../actions/paymentGatewayActions/makePaymentActions';

function* makePayment({ payload }) {
    try {
        const response = yield call(AxiosPGService, {
            method: 'post',
            url: UAT_PAY_API_URL,
            data: {request: payload.PhonePeRequestJSONBase64},
            header: {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    "X-VERIFY": payload.PhonePeHash,
                }
            },
        });
        console.log('====================================');
        console.log("GENERATE_OTP_SUCCESS status :", response.status);
        console.log("GENERATE_OTP_SUCCESS data :", response.data);
        console.log("GENERATE_OTP_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(makePaymentSuccess({
            status: response?.status,
            data: response?.data
        }))
    } catch (error) {
        console.log('====================================');
        console.log("GENERATE_OTP_FAILURE error :", error.response?.status);
        console.log("GENERATE_OTP_FAILURE message :", error.message);
        console.log("GENERATE_OTP_FAILURE error :", error);
        console.log('====================================');
        yield put(makePaymentFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* makePaymentSaga() {
    yield takeLatest(actionTypes.MAKE_PAYMENT_REQUEST, makePayment);
}

export default makePaymentSaga;