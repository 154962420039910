import React, { Fragment, useEffect, useMemo, useState } from 'react'
import NearbyGymCard from './NearbyGymCard'
import { NearbyGymCardImg } from '../../../static/constants/imgConstants'
import { useDispatch, useSelector } from 'react-redux';
import { searchByLocation, searchByLocationReset } from '../../../store/actions/venueManagementServiceActions/searchByLocationActions';
import { Geocode, GeocodeReset } from '../../../store/actions/GoogleApiActions/GeocodeActions';
import { Oval } from 'react-loader-spinner';
import "./NearbyGym.css"
import { useToast } from '../../../hooks/useToast';
import "swiper/css";
import { register } from 'swiper/element/bundle';
import 'swiper/element/css/pagination';
import Slider1 from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NearbyGym() {
    register();

    const dispatch = useDispatch();
    const Toast = useToast()

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    const GeocodeReducerData = useSelector((state) => state.GeocodeReducer?.data);
    const GeocodeReducerStatus = useSelector((state) => state.GeocodeReducer?.status);
    const GeocodeReducerLoading = useSelector((state) => state.GeocodeReducer?.loading);
    const searchByLocationReducersData = useSelector((state) => state.searchByLocationReducers?.data);
    const searchByLocationReducersStatus = useSelector((state) => state.searchByLocationReducers?.status);
    const searchByLocationReducersLoading = useSelector((state) => state.searchByLocationReducers?.loading);

    const latLong = {
        lat: "12.887",
        long: "77.639"
    }

    const [zipCode, setZipCode] = useState(0);
    const [zipCodeError, setZipCodeError] = useState(false);

    useMemo(() => {
        if (zipCode.length < 6) {
            setZipCodeError(true)
        } else {
            setZipCodeError(false)
        }
        if (zipCode.length === 0) {
            setZipCodeError(false)
            dispatch(searchByLocation({ lat: latLong.lat, long: latLong.long }));
        }
    }, [zipCode])

    const [screenSize] = useState(window.innerWidth);

    // useEffect(() => {
    //     dispatch(searchByLocation({ lat: latLong.lat, long: latLong.long }));
    // }, [])

    useEffect(() => {
        if (searchByLocationReducersStatus == 200) {
            dispatch(searchByLocationReset());
        } else if (searchByLocationReducersStatus) {
            dispatch(searchByLocationReset());
        }
    }, [searchByLocationReducersStatus])


    useEffect(() => {
        if (GeocodeReducerStatus == 200) {
            dispatch(searchByLocation({ lat: GeocodeReducerData?.map((items) => { return items?.geometry.location.lat }), long: GeocodeReducerData?.map((items) => { return items?.geometry.location.lng }) }))
            dispatch(GeocodeReset());
        } else if (GeocodeReducerStatus) {
            dispatch(GeocodeReset());
        }
    }, [GeocodeReducerStatus])

    console.log("NearbyGym screenSize", screenSize);


    return (
        <div className='near-by-gym-section'>
            <div className="near-by-gym-section-gradient min-h-max py-[4rem] px-[4rem]">
                <div className="container-lg flex flex-col justify-center items-center">
                    <div className="text-white montserrat-bold text-[6.5rem]">
                        Find the nearest Fit.Zone to you
                    </div>
                    <div class="flex w-full md:w-[50%] lg:w-[34%] my-10 mx-auto urbanist-semibold text-[2rem] relative">
                        <input
                            className='text-white flex px-[2.1rem] py-[1rem] rounded-[1rem] bg-black border border-white w-full outline-none'
                            type="number"
                            placeholder="Enter pincode"
                            value={zipCode == 0 ? "" : zipCode}
                            onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                    setZipCode(e.target.value)
                                }
                            }
                            }
                            onKeyDown={(e) => {
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    e.preventDefault();
                                }
                            }}
                            onWheel={(e) => e.target.blur()}
                        />
                        <button
                            className='bg-white rounded-r-[1rem] rounded-l-[1rem] hover:bg-[#ffff]/90 min-w-max absolute right-0 py-[1rem]'
                            onClick={() => { if (zipCode == 0) { Toast("Please enter Valid Pin Code") } else dispatch(Geocode({ zipCode })) }}
                        >
                            <span className='p-[1.3rem] text-nowrap right-0'>
                                Find a gym near you
                            </span>
                        </button>
                    </div>
                    <span className="text-[1.8rem] text-[#C43045]">
                        {zipCodeError ? "minimum 6 number required" : ""}
                    </span>

                    {/* <div className="flex flex-row justify-between items-center w-full mt-6">
                            {!GeocodeReducerLoading && !searchByLocationReducersLoading ?
                                searchByLocationReducersData?.venues?.length > 0 ?
                                    <Fragment>
                                        <div className="flex xl:hidden flex-row justify-between items-center w-full flex-wrap">
                                            {
                                                searchByLocationReducersData?.venues?.filter((item, index) => (item.category == "premium" || item.category == "champion"))?.map((items, index) => {
                                                    return index < 4 && (
                                                        <NearbyGymCard
                                                            title={items.category}
                                                            icon={items.category == "premium" ? NearbyGymCardImg.premium : NearbyGymCardImg.champion}
                                                            heading={items.name}
                                                            workoutType={items.description}
                                                            students={"60"}
                                                            time={"2H"}
                                                            image={items.venuePosterImage}
                                                            id={items.id}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="hidden xl:flex flex-row justify-between items-center w-full flex-wrap">
                                            {
                                                searchByLocationReducersData?.venues?.filter((item, index) => (item.category == "premium" || item.category == "champion"))?.map((items, index) => {
                                                    return index < 3 && (
                                                        <NearbyGymCard
                                                            title={items.category}
                                                            icon={items.category == "premium" ? NearbyGymCardImg.premium : NearbyGymCardImg.champion}
                                                            heading={items.name}
                                                            workoutType={items.description}
                                                            students={"60"}
                                                            time={"2H"}
                                                            image={items.venuePosterImage}
                                                            id={items.id}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Fragment>
                                    :
                                    <div className="montserrat-regular text-[2.5rem] text-white w-full text-center">
                                        No Venues Available
                                    </div>
                                :
                                <div className="flex justify-center items-center w-full">
                                    <Oval
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#FAFF00"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                            }
                        </div> */}
                    {/* : */}
                    {!searchByLocationReducersLoading ?
                        <div className="w-full mt-20">
                            <Slider1 {...settings} className="overflow-hidden my-[3rem]">
                                {/* <swiper-container keyboard="true" pagination="true"> */}
                                {
                                    searchByLocationReducersData?.venues?.length > 0 ?

                                        searchByLocationReducersData?.venues?.filter((item, index) => (item.category == "premium" || item.category == "champion"))?.map((items, index) => {
                                            return (
                                                // <swiper-slide>
                                                <div className="">
                                                    <NearbyGymCard
                                                        title={items.category}
                                                        icon={items.category == "premium" ? NearbyGymCardImg.premium : NearbyGymCardImg.champion}
                                                        heading={items.name}
                                                        workoutType={items.description}
                                                        students={"60"}
                                                        time={"2H"}
                                                        image={items.venuePosterImage}
                                                        id={items.id}
                                                    />
                                                </div>
                                                // </swiper-slide>
                                            )
                                        })
                                        :
                                        <div className="montserrat-regular text-[2.5rem] text-white w-full text-center">
                                            No Venues Available
                                        </div>

                                }
                            </Slider1>
                            {/* </swiper-container> */}
                        </div>
                        :
                        <div className="flex justify-center items-center w-full">
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#FAFF00"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    }
                    {/* } */}
                </div>
            </div>
        </div>
    )
}

export default NearbyGym
