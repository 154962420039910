import React from 'react'

const VideoPlayer3Slider = ({ image }) => {
    return (
        <div className="flex justify-center items-center">
            <img src={image} alt="" className="w-[100rem] h-[50rem]" />
        </div>
    )
}

export default VideoPlayer3Slider