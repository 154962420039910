import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_reward_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { fitcoinhistoryFailure, fitcoinhistorySuccess } from '../../actions/RewardManagementServiceActions/fitcoinhistoryAction';

function* fitcoinhistory({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_reward_management_service,
            endPoint: endpoints.fitcoinhistory + `${`?customerid=${payload.userId}&pagesize=10&pagenumber=1&order=1`}`,
            isAuth: true
        });

        console.log('====================================');
        console.log("FITCOIN_HISTORY_SUCCESS response :", response);
        console.log("FITCOIN_HISTORY_SUCCESS status :", response?.status);
        console.log("FITCOIN_HISTORY_SUCCESS data :", response?.data);
        console.log("FITCOIN_HISTORY_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            fitcoinhistorySuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("FITCOIN_HISTORY_FAILURE status :", error?.response?.status);
        console.log("FITCOIN_HISTORY_FAILURE data:", error?.response?.data);
        console.log("FITCOIN_HISTORY_FAILURE error :", error);
        console.log('====================================');
        yield put(fitcoinhistoryFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* fitcoinhistorySaga() {
    yield takeLatest(actionTypes.FITCOIN_HISTORY_REQUEST, fitcoinhistory);
}

export default fitcoinhistorySaga;