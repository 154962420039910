import React, { useEffect, useState } from 'react'
import ClientFeeBackCard from './ClientFeeBackCard'
import { useDispatch, useSelector } from 'react-redux';
import { strapiClientFeedbackReset } from '../../../store/actions/StrapiServiceActions/strapiClientFeedbackAction';
import "swiper/css";
import "./ClientFeedback.css"
import 'swiper/element/css/pagination';

function ClientFeedback() {

    const dispatch = useDispatch();
    const [screenSize] = useState(window.innerWidth);

    const strapiClientFeedbackReducerData = useSelector((state) => state.strapiClientFeedbackReducer?.data);
    const strapiClientFeedbackReducerStatus = useSelector((state) => state.strapiClientFeedbackReducer?.status);

    useEffect(() => {
        if (strapiClientFeedbackReducerStatus == 200) {
            dispatch(strapiClientFeedbackReset());
        } else if (strapiClientFeedbackReducerStatus) {
            dispatch(strapiClientFeedbackReset());
        }
    }, [strapiClientFeedbackReducerStatus])

    return (
        <div className={`client-feedback px-[4rem]`}>
            <div className="container-lg">
                <div className="montserrat-bold text-[2.4rem] md:text-[6.5rem] text-white text-center my-5">
                    Client’s Feedback
                </div>
                <div className={`client-feedback-swiper flex justify-center items-center ${strapiClientFeedbackReducerData?.length > 0 ? "h-[60rem]" : "h-[20rem]"} gap-[5.8rem]`}>
                    {screenSize < 900 ?
                        <swiper-container pagination="true">
                            {strapiClientFeedbackReducerData?.length > 0 ?
                                strapiClientFeedbackReducerData?.map((items) => {
                                    return (
                                        <swiper-slide className='flex flex-wrap justify-center items-center'>
                                            <div className="my-20 w-[36rem] mx-[18%] sm:mx-[27%] md:mx-[30%]">
                                                <ClientFeeBackCard
                                                    feedback={items.content}
                                                    rating={items.Stars}
                                                    userPic={items.image.url}
                                                    userName={items.title}
                                                />
                                            </div>
                                        </swiper-slide>
                                    )
                                })
                                :
                                <div className="text-center text-white text-[4rem] w-full my-auto overflow-hidden">No Feedback Data Available at this time</div>
                            }
                        </swiper-container>
                        :
                        <div className="flex justify-between items-center w-full xl:mx-[10.9rem] 2xl:mx-[19.9rem]">
                            {strapiClientFeedbackReducerData?.length > 0 ?
                                strapiClientFeedbackReducerData?.map((items) => {
                                    return (
                                        <div className="my-20 w-[36rem] mx-[1rem] lg:mx-0">
                                            <ClientFeeBackCard
                                                feedback={items.content}
                                                rating={items.Stars}
                                                userPic={items.image.url}
                                                userName={items.title}
                                            />
                                        </div>
                                    )
                                })
                                :
                                <div className="text-center text-white text-[4rem] w-full my-auto overflow-hidden">No Feedback Data Available at this time</div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ClientFeedback
