import React from 'react'
// import LandingImg1 from '../../../static//images//home-landing-crousal-item1.png'
import "./HomeLandingCarousel.css"
import { useNavigate } from 'react-router-dom'
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight, BsCopy } from "react-icons/bs";
import { useToast } from "../../../hooks/useToast";
import { useDispatch } from 'react-redux';
import { setSelectedPlan } from '../../../store/actions/utilsActions/utilsActions';

function HomeLandingCrousalItem1({ genericoffersItems }) {

    const navigate = useNavigate()
    const Toast = useToast()
    const dispatch = useDispatch()

    console.log("genericoffersItems :", genericoffersItems?.posterimageurlWebsite);


    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text); // Copy text to clipboard
            Toast('Copied to clipboard!', 'success'); // Set success message
        } catch (err) {
            Toast('Failed to copy', 'error'); // Error handling
        }
    };

    return (
        <div
            className={`px-[4rem]`}
            style={{ backgroundImage: `url("${!genericoffersItems?.offerCode ? genericoffersItems?.offers[0].posterimageurlWebsite : genericoffersItems?.posterimageurlWebsite}")`, minHeight: "80rem", height: "98vh", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
            onClick={() => { if (!genericoffersItems?.offerCode) { dispatch(setSelectedPlan(genericoffersItems)); navigate(`/fit-center/${genericoffersItems.subscriptionCategoryCode == "CHAMPION" ? "fit-champ" : "fit-prime"}/${genericoffersItems.id}`) } else { return } }}
        >
            {!genericoffersItems?.offerCode ?
                null
                :
                <div className="flex justify-between items-end h-full relative">
                    <div
                        className="absolute flex justify-start items-end bg-[#333333] text-[#fff] right-0 bottom-[1.4rem] px-[2rem] py-[.6rem] rounded-[1rem] cursor-pointer"
                        onClick={() => { handleCopy(genericoffersItems?.offerCode) }}
                    >
                        <div className="home-landing-crousal-item1-text urbanist-semibold text-[2rem] mr-[4rem] cursor-pointer" >
                            {genericoffersItems?.offerName}
                        </div>
                        <div className="flex items-center home-landing-crousal-item1-text text-[1.8rem] urbanist-regular cursor-pointer hs-tooltip">
                            {genericoffersItems?.offerCode}
                            <BsCopy className="ml-[1.2rem]" />
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}

export default HomeLandingCrousalItem1
