import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_order_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { emailInvoiceFailure, emailInvoiceSuccess } from '../../actions/orderManagementActions/emailInvoiceAction';

function* emailInvoice({ payload }) {
    try {
        // Make an API call to generate OTP
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_order_management_service,
            endPoint: endpoints.emailInvoice + `?orderid=${payload.orderId}&customerid=${payload.userId}`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("EMAIL_INVOICE_SUCCESS status :", response.status);
        console.log("EMAIL_INVOICE_SUCCESS data :", response.data);
        console.log("EMAIL_INVOICE_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        // Dispatch success action with the response data
        yield put(emailInvoiceSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("EMAIL_INVOICE_FAILURE error :", error.response?.status);
        console.log("EMAIL_INVOICE_FAILURE error :", error.message);
        console.log('====================================');
        // Dispatch failure action with the error message
        yield put(emailInvoiceFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* emailInvoiceSaga() {
    yield takeLatest(actionTypes.EMAIL_INVOICE_REQUEST, emailInvoice);
}

export default emailInvoiceSaga;