import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiArticles = (payload) => ({
    type: actionTypes.STRAPI_ARTICLES_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiArticlesSuccess = (payload) => ({
    type: actionTypes.STRAPI_ARTICLES_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiArticlesFailure = (payload) => ({
    type: actionTypes.STRAPI_ARTICLES_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiArticlesReset = () => ({
    type: actionTypes.STRAPI_ARTICLES_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiArticlesResetAll = () => ({
    type: actionTypes.STRAPI_ARTICLES_RESET_ALL,
}); 