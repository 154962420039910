import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_fitness_assessement_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { GetFitnessAssessmentFailure, GetFitnessAssessmentSuccess } from '../../actions/FitnessAssessementServiceActions/GetFitnessAssessmentAction';

function* GetFitnessAssessment({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_fitness_assessement_service,
            endPoint: endpoints.GetFitnessAssessment + `?UserId=${payload.userId}`,
            // data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("GET_FITNESS_ASSESSMENT_SUCCESS status :", response.status);
        console.log("GET_FITNESS_ASSESSMENT_SUCCESS data :", response.data);
        console.log("GET_FITNESS_ASSESSMENT_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        yield put(GetFitnessAssessmentSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("GET_FITNESS_ASSESSMENT_FAILURE error :", error.response?.status);
        console.log("GET_FITNESS_ASSESSMENT_FAILURE error :", error.message);
        console.log('====================================');
        yield put(GetFitnessAssessmentFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* GetFitnessAssessmentSaga() {
    yield takeLatest(actionTypes.GET_FITNESS_ASSESSMENT_REQUEST, GetFitnessAssessment);
}

export default GetFitnessAssessmentSaga;