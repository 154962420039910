import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Create a context for the toast
const ToastContext = createContext();

// Custom hook to use the toast context
export const useToast = () => {
    return useContext(ToastContext);
};

// Toast component
const Toast = ({ message, type, duration, onClose, onHover }) => {
    const typeClasses = {
        info: 'bg-blue-500',
        success: 'bg-green-500',
        error: 'bg-red-500',
    };

    const [progress, setProgress] = useState(30);
    const intervalRef = useRef(null);

    useEffect(() => {
        const interval = 100;
        intervalRef.current = setInterval(() => {
            setProgress((prev) => {
                if (prev > 0) {
                    return prev - (100 * interval) / duration;
                } else {
                    clearInterval(intervalRef.current);
                    onClose();
                    return 0;
                }
            });
        }, interval);

        return () => clearInterval(intervalRef.current);
    }, [duration, onClose]);

    const handleMouseEnter = () => {
        onHover(true);
        clearInterval(intervalRef.current);
    };

    const handleMouseLeave = () => {
        onHover(false);
        const interval = 100;
        intervalRef.current = setInterval(() => {
            setProgress((prev) => {
                if (prev > 0) {
                    return prev - (100 * interval) / duration;
                } else {
                    clearInterval(intervalRef.current);
                    onClose();
                    return 0;
                }
            });
        }, interval);
    };

    return (
        <div
            className={`text-white p-4 rounded shadow-lg flex flex-col justify-between ${typeClasses[type]} relative mt-[5rem] text-[1.6rem] montserrat-semibold px-[2.4rem]`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span>{message}</span>
            {/* <div
                className="absolute bottom-0 left-0 h-1 bg-white"
                style={{ width: `${progress}%` }}
            ></div> */}
            <button onClick={onClose} className="absolute top-2 right-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
        </div>
    );
};

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'success', 'error']),
    duration: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
};

// Toast provider component
export const ToastProvider = ({ children, defaultPosition = 'top-center', defaultDuration = 5000 }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = useCallback((message, type = 'info', duration = defaultDuration, position = defaultPosition) => {
        const id = Date.now();
        setToasts((prevToasts) => [...prevToasts, { id, message, type, duration, position }]);
    }, [defaultDuration, defaultPosition]);

    const removeToast = useCallback((id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, []);

    const handleHover = (hovering) => {
        if (hovering) {
            // Pause all toast intervals
            toasts.forEach((toast) => {
                clearInterval(toast.intervalId);
            });
        } else {
            // Resume all toast intervals
            toasts.forEach((toast) => {
                const interval = 100;
                toast.intervalId = setInterval(() => {
                    toast.progress = toast.progress - (100 * interval) / toast.duration;
                    if (toast.progress <= 0) {
                        clearInterval(toast.intervalId);
                        removeToast(toast.id);
                    }
                }, interval);
            });
        }
    };

    const positionClasses = {
        'top-left': 'top-5 left-5',
        'top-center': 'top-5 left-1/2 transform -translate-x-1/2',
        'top-right': 'top-5 right-5',
        'bottom-left': 'bottom-5 left-5',
        'bottom-center': 'bottom-5 left-1/2 transform -translate-x-1/2',
        'bottom-right': 'bottom-5 right-5',
    };

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            {Object.keys(positionClasses).map((pos) => (
                <div key={pos} className={`fixed z-[999] space-y-3 ${positionClasses[pos]}`}>
                    {toasts.filter((toast) => toast.position === pos).map((toast) => (
                        <Toast
                            key={toast.id}
                            message={toast.message}
                            type={toast.type}
                            duration={toast.duration}
                            onClose={() => removeToast(toast.id)}
                            onHover={handleHover}
                        />
                    ))}
                </div>
            ))}
        </ToastContext.Provider>
    );
};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
    defaultPosition: PropTypes.oneOf([
        'top-left',
        'top-center',
        'top-right',
        'bottom-left',
        'bottom-center',
        'bottom-right',
    ]),
    defaultDuration: PropTypes.number,
};
