import React, { useEffect, useState } from 'react';
import FAQSectionItem from './FAQSectionItem';

const FAQSection = ({ data }) => {
    const groupBySubCategory = (data) => {
        return data.length > 0 ? data?.reduce((acc, item) => {
            if (!acc[item?.SubCategory]) {
                acc[item?.SubCategory] = [];
            }
            acc[item?.SubCategory].push(item);
            return acc;
        }, {})
            :
            null;
    };

    const [groupedData, setGroupedData] = useState(null);

    useEffect(() => {
        if (data && data.length > 0) {
            const grouped = groupBySubCategory(data);
            setGroupedData(grouped);
        } else {
            setGroupedData(null); // Handle empty or undefined data
        }
    }, [data]);

    return (
        <div>
            {groupedData ?
                Object.keys(groupedData)?.length > 0 ? (
                    Object.keys(groupedData)?.map((subCategory, index) => (
                        <div key={subCategory}>
                            <div className="bg-[#4F4F4F] rounded-[1.8rem]">
                                <div className="my-[3.6rem] mx-[3.2rem] text-white dm-sans-bold text-[2.4rem]">
                                    {index + 1}. {subCategory}
                                </div>
                            </div>
                            <div className="bg-[#21211F] md:mt-[4.4rem] mb-[9.6rem] flex flex-col justify-center items-center px-[4rem] sm:px-[10rem] py-[6rem] rounded-b-[2rem] md:rounded-[2rem]">
                                {groupedData[subCategory]?.map((item, idx) => {
                                    return (
                                        <FAQSectionItem faqSectionData={item} index={idx} key={idx} />
                                    )
                                })}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                        No data Available at this time! Please try again later...
                    </div>
                )
                :
                <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                    Loading data...
                </div>
            }
        </div>
    );
};

export default FAQSection;
