import React, { useEffect, useState } from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import "./MembershipPackages.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPlanType, setSelectedPlan, setUpdateProfile } from '../../../store/actions/utilsActions/utilsActions';
import UserProfilePopup2 from '../UserProfilePopup2/UserProfilePopup2';
import { customerProfileReset } from '../../../store/actions/CustomerManagementServiceActions/customerProfileAction';

const MembershipPackagesCard = ({ planDuration, price, billedAmount, points, package_type, id, items, isHome }) => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const [userProfilePopup2, setUserProfilePopup2] = useState(false)


    const utilReducers = useSelector((state) => state.utilReducers);
    const customerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);
    const customerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);


    const parsePoints = (htmlString) => {
        // Create a temporary container to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Extract paragraphs and return an array of text content
        const paragraphs = Array.from(tempDiv.getElementsByTagName('p')).map(p => p.innerHTML);
        // console.log("htmlString :", htmlString);
        // console.log("paragraphs :", paragraphs);
        return paragraphs;
    }

    // Get the parsed points as an array of strings
    const parsedPoints = parsePoints(points);

    useEffect(() => {
        if (customerProfileReducerStatus == 200) {
            dispatch(customerProfileReset())
        } else if (customerProfileReducerStatus) {
            dispatch(customerProfileReset());
        }
    }, [customerProfileReducerStatus])

    console.log("planDuration", planDuration);
    console.log("billedAmount", billedAmount);


    return (
        <div
            className={package_type === "fit-prime" ?
                `${planDuration == 3 ?
                    "membership-packages-card" : planDuration == 1 ?
                        "bg-[#333333]" : planDuration == 6 ?
                            "bg-[#3B3B3B]" : "bg-[#616161D4]/80"}
                p-8 rounded-[1.6rem] ${planDuration == 3 ? "h-[40rem]" : "h-[39rem]"} w-[28.3rem] 
                flex flex-col justify-between`
                :
                `${planDuration == 3 ?
                    "membership-packages-card-champ" : planDuration == 1 ?
                        "bg-[#333333]" : planDuration == 6 ?
                            "bg-[#3B3B3B]" : "bg-[#616161D4]/80"}
                p-8 rounded-[1.6rem] ${planDuration == 3 ? "h-[40rem]" : "h-[39rem]"} w-[28.3rem] 
                flex flex-col justify-between`
            }
        >
            <div className=" overflow-y-hidden">
                <div className={`${planDuration == 3 ? "text-black" : "text-white"} urbanist-semibold text-[2rem]`}>
                    {planDuration} Month Plan
                </div>
                <div className={`${planDuration == 3 ? "text-black" : "text-white"} ${isHome ? "monoton-regular text-[4rem] overflow-hidden" : "monoton-regular text-[4rem] overflow-hidden"} `}>
                    {!isHome && <span className="font-[400]">₹</span>}{billedAmount}/-
                </div>
                <div className={`${planDuration == 3 ? "text-black" : "text-white"} urbanist-medium text-[1.6rem]`}>
                    Billed {price?.toFixed()} per month, bill annually
                </div>
                <div className="flex flex-col justify-center items-start my-5">
                    {parsedPoints.map((point, index) => {
                        if (index > 3) {
                            return null
                        } else {
                            return (
                                <div key={index} className={`flex items-center mb-2 ${planDuration == 3 ? "" : "text-white"}`}>
                                    <FaCircleCheck className='mr-[1rem] check-icon' color={`${planDuration == 3 ? "#000" : "#ffff"}`} />
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </div>
                            )
                        }
                    }
                    )}
                </div>
            </div>
            <div
                className="flex justify-center items-center mb-5"
                onClick={() => {
                    const customerProfile = customerProfileReducerData?.[0];
                    dispatch(setSelectedPlan(items));
                    dispatch(setPlanType(package_type));
                    dispatch(setUpdateProfile(true));
                    if ((!customerProfile?.mobileNumber?.number || !customerProfile?.email) && utilReducers?.accessToken) {
                        setUserProfilePopup2(true);
                    } else {
                        Navigate(`/fit-center/${package_type}/${id}`)
                    }
                }}
            >
                <button className={`${planDuration == 3 ? "bg-black text-white" : "bg-[#FAFF00]"} p-4 w-full poppins-medium text-[1.6rem] rounded-[1.2rem]`}>
                    Buy Now
                </button>
            </div>
            <UserProfilePopup2 show={userProfilePopup2} onClose={() => { setUserProfilePopup2(false) }} setUserProfilePopup2={setUserProfilePopup2} package_type={package_type} id={id} />
        </div>
    )
}

export default MembershipPackagesCard