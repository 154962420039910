import React from "react";
import { AboutUsImg } from "../../../static/constants/imgConstants";

function AboutUsGridSec3() {
    return (
        <div className="about-us-grid-sec3 bg-[#000] sm:px-[4rem]">
            <div className="sm:container-lg flex flex-col lg:flex-row h-full">
                <div className="w-full lg:w-[50%] flex flex-col sm:flex-row justify-center pt-[4rem] lg:pt-[10%] px-[4rem] sm:px-0">
                    <div className="w-full sm:w-[50%] flex flex-col justify-start items-start mr-[2rem]">
                        <h3 className="text-[6rem] md:text-[4.3rem] text-[#D0D0D0] mb-2 urbanist-bold overflow-hidden">25%</h3>
                        <div className="w-full bg-[#D0D0D0] h-1 mb-4"><div className="bg-[#21AB64] h-full" style={{ width: "73%" }} /></div>
                        <h2 className="text-[2.5rem] md:text-[2.5rem] mb-4 text-[#5e5e5e] urbanist-bold">Growth in Member
                            <br />Engagement</h2>
                        <p className="text-[1.7rem] md:text-[1.7rem] text-[#666666] urbanist-medium">
                            Our partner gyms have reported a
                            25% increase in member engagement
                            within the first six months of collaboration
                        </p>
                    </div>
                    <div className="w-full sm:w-[50%] flex flex-col justify-start items-start mr-[2rem]">
                        <h3 className="text-[6rem] md:text-[4.3rem] text-[#D0D0D0] mb-2 urbanist-bold overflow-hidden">40%</h3>
                        <div className="w-full bg-[#D0D0D0] h-1 mb-4"><div className="bg-[#21AB64] h-full" style={{ width: "83%" }} /></div>
                        <h2 className="text-[2.5rem] md:text-[2.5rem] mb-4 text-[#5e5e5e] urbanist-bold overflow-hidden">Higher Visibility</h2>
                        <p className="text-[1.7rem] md:text-[1.7rem] text-[#666666] urbanist-medium overflow-hidden">
                            Our promotional campaigns have
                            increased partner gyms' visibility by
                            40% across digital and social media platforms.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-[50%] h-[38rem] sm:h-full flex justify-center">
                    <div className="w-full lg:w-[50%] flex flex-col justify-center items-start bg-[#F1E912] pl-[8rem] pr-[4rem] lg:pr-0 py-[4rem] lg:py-auto">
                        <h3 className="text-[3.4em] mb-[2rem] text-black urbanist-bold overflow-visible">We take care of you.</h3>
                        <p className="text-[1.7rem] md:text-[1.7rem] text-[#666666] urbanist-medium leading-[2.5rem] overflow-hidden">
                            At Fit.Zone, we are dedicated to building strong, mutually beneficial partnerships. We promise to provide unwavering support and commitment to your success, offering resources, marketing assistance, and collaborative opportunities to enhance our partnership. Our goal is to ensure a seamless and productive relationship that drives growth and delivers exceptional value to both parties. Together, we’ll achieve great results and make a lasting impact in the fitness community.
                        </p>
                    </div>
                    <div className="lg:w-[50%] flex flex-col justify-start items-start" />
                </div>
            </div>
            <div className="sm:hidden">
                <div className="w-[36.9] h-[45.2]">
                    <img src={AboutUsImg.AboutUsImg4} alt="" className="w-full h-full" />
                </div>
                <div className="ml-[5.9rem]">
                    <div className="text-white text-[2.5rem] urbanist-bold">
                        Arvind Ji
                    </div>
                    <div className="text-[#21AB64] text-[1.2rem] urbanist-medium">
                        CEO, Firzone.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsGridSec3;
