import React from 'react'
import Footer3 from '../../components/common/Footer3/Footer3'
import PayStepForm from '../../components/common/PayStepForm/PayStepForm'

const PurchasePackages = () => {
    return (
        <div className='packages-details-background bg-[#1E1E1E]'>
            <PayStepForm />
            <Footer3 />
        </div>
    )
}

export default PurchasePackages