import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../hooks/useToast';
import { customerProfile } from '../../../store/actions/CustomerManagementServiceActions/customerProfileAction';
import { logout } from '../../../store/actions/utilsActions/utilsActions';
import { useNavigate } from 'react-router-dom';

const MyDashboard = () => {

    const dispatch = useDispatch();
    const Toast = useToast()
    const Navigate = useNavigate();


    const utilReducers = useSelector((state) => state.utilReducers);
    const customerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);
    const customerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);

    useEffect(() => {
        dispatch(customerProfile(utilReducers?.userId));
    }, [])

    useEffect(() => {
        if (customerProfileReducerStatus == 200) {
            return
        } else if (customerProfileReducerStatus == 403) {
            Toast("Please login again to see profile");
            dispatch(logout());
            Navigate("/");
        } else if (customerProfileReducerStatus) {
            return
        }
    }, [customerProfileReducerStatus])


    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        centerPadding: '0',
    }


    return (
        <div className=''>
            <div className="container-lg px-[4rem] bg-[#1E1E1E] min-h-screen flex justify-start items-start">
                <div className="w-full bg-[#000000] text-[#ffffff] mt-[10rem] rounded-[2rem]">
                    <h1 className="text-[3rem] md:text-[4rem] text-start px-[4rem] p-4">
                        Welcome &nbsp;
                        {
                            customerProfileReducerData[0]?.firstName ?
                                customerProfileReducerData[0]?.firstName + " " + customerProfileReducerData[0].lastName
                                :
                                customerProfileReducerData[0]?.email
                        }
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default MyDashboard
