import React, { useEffect, useState } from 'react';
import Workout from '../../components/common/Workout/Workout';
import ExploreCategories from '../../components/common/ExploreCategories/ExploreCategories';
import Footer3 from '../../components/common/Footer3/Footer3';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url_strapi_service } from '../../store/constants';
import { community } from '../../store/actions/StrapiServiceActions/communityAction';
import { useDispatch, useSelector } from 'react-redux';
import { communitySubCategories } from '../../store/actions/StrapiServiceActions/communitySubCategoriesAction';
import ReactPlayer from 'react-player';
import Markdown from 'react-markdown';
import moment from 'moment';

function BlogDetail() {

    const location = useLocation();

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const data = location.state?.data || {}

    const communityReducerData = useSelector((state) => state.communityReducer?.data);
    const communitySubCategoriesReducerData = useSelector((state) => state.communitySubCategoriesReducer?.data);

    useEffect(() => {
        dispatch(community());
        dispatch(communitySubCategories())
    }, [])

    console.log("BlogDetail communitySubCategoriesReducerData :", communitySubCategoriesReducerData);

    console.log("BlogDetail data :", data);


    return (
        <div className='bg-[#000000] md:bg-[#1E1E1E]'>
            <div className='container-lg  px-[4rem]'>
                <div className='w-full flex justify-between items-center mt-[7rem]'>
                    <div className='flex gap-4 '>
                        <h1 className="bordered-font text-transparent text-[5.4rem] md:text-[9.4rem]">{data[0]?.TitlePrimary}</h1> <h1 className="text-[#FAFF00] text-[5.4rem] md:text-[9.4rem]">{data[0]?.TitleSecondary}</h1>
                    </div>
                </div>
                <div className='w-full md:flex justify-between'>
                    <div className='w-full md:w-[52%]'>
                        {data[0].slug == "motion_and_muscle" ?
                            <div className="w-[52rem] sm:w-full rounded-[2.2rem] flex justify-center items-center">
                                <ReactPlayer
                                    url={base_url_strapi_service + data[0]?.video?.url}
                                    controls={true}
                                    width={"75.1rem"}
                                    light={<img src={"https://s3-alpha-sig.figma.com/img/8e53/d9e6/a3d5d96230d1ce9ab563c328d251a8b3?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XXKV~pwZsRbDEQTxU9qqIymAeEcDJJhOXr0ddQRRwoaVLcKGSsyx1v1Wi190xXsZj5jSXOOBsl7DIRCSkGnrdfqxf8lebGLK7KN9RtFENMXcxLcQEMIQb0og5QzL8VaWFt9fpav9lVgUJZOnjUJs9s4RGr~efDzZczFd1D9rQ~ev8GHvp~uZ63gzktN-8zLsCBPYkYMsIBEV0mDaLnVlAn0itVQn8EgbmNqCfZ-2DbpqRsWA9FleTfT~C0EI0ONZ6itD-wOj1UH-QJGFocAKcW8cGy-6bg-nwVn3zjOOPK1JCQfHRjma84CPHR3lkbYjlwgw6ncAcJ2GM2SAxdzPdg__"} alt='Thumbnail' className='w-full h-full rounded-[2.2rem]' />}
                                    playing={true}
                                    height={"38.6rem"}
                                    playbackRate={1}
                                    progressInterval={500}
                                />
                            </div>
                            :
                            <div className='w-full h-auto '>
                                <img src={base_url_strapi_service + data[0]?.image.url} alt="Wishlist" className="w-full h-auto" />
                            </div>
                        }
                        <div className='w-full my-[3rem] text-[1.8rem] text-[#ffffff] overflow-hidden' style={{ maxHeight: "400px" }}>
                            <Markdown className="text-white text-[2rem] my-5" children={data[0]?.content} />
                        </div>
                        <div className='flex justify-center my-[4rem]' onClick={() => navigate(`/community/blogs/${data[0].sub_category.sub_category_name}`, { state: { data: data[0] } })}>
                            <button className='bg-[#FAFF00] text-[#486077] text-[1.7rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'>
                                Read More
                            </button>
                        </div>
                    </div>

                    <div className='w-full md:w-[42%]'>
                        <h1 className='text-[#ffffff] text-[3rem] text-center'>Featured Articles</h1>
                        <div className='w-full grid grid-cols-2 my-[2rem] gap-4'>
                            {data?.length > 0 && data.map((card, index) => {
                                if (index <= 5) {
                                    return (
                                        <div key={index} className='overflow-hidden'>
                                            <div className=''>
                                                <div className='w-full h-auto '>
                                                    <img src={base_url_strapi_service + card?.image?.url} alt="Blog Image" className='w-full h-auto object-cover' />
                                                </div>
                                                <div className='w-[30.8rem]'>
                                                    <p className='text-[#ffffff] text-[2rem]'>{card.description}</p>
                                                    <div className='flex justify-between text-[#A5A5A5] text-[1.6rem] mt-[1rem]'>
                                                        <p>{moment(card.createdAt).format("DD-MM-YYYY")}</p>
                                                        <p>3 min read</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return
                                }
                            })}
                        </div>
                        <div className='flex justify-center my-[4rem]' onClick={() => navigate(`/community/Featured Articles`)}>
                            <button className='bg-[#FAFF00] text-[#486077] text-[1.7rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'>
                                Read More
                            </button>
                        </div>
                    </div>
                </div>

                <div className=''>
                    <Workout cards={communityReducerData.length > 0 ? communityReducerData?.filter((item, index) => { return index > 5 && index < 14 }) : []} isMarginTrue={true} title1={"More Featured posts from " + data[0]?.sub_category?.sub_category_name} />
                </div>
                <div className='py-[4rem]'>
                    <ExploreCategories data={communitySubCategoriesReducerData} selectedCategory={data[0]?.sub_category?.slug} />
                </div>
                <Footer3 />
            </div>
        </div>
    )
}

export default BlogDetail