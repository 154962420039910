import React from "react";
import DownloadAppLanding from "../../components/common/DownloadAppLanding/DownloadAppLanding";
import AppStats from "../../components/common/AppStats/AppStats";
import DownloadMigBox from "../../components/common/DownloadMigBox/DownloadMigBox";
import NearbyGym from "../../components/common/NearbyGym/NearbyGym";
import AppBanner from "../../components/common/AppBanner/AppBanner";
import VideoPlayer4 from "../../components/common/VideoPlayer4/VideoPlayer4";
import AppBanner2 from "../../components/common/AppBanner2/AppBanner2";
import AppBanner3 from "../../components/common/AppBanner3/AppBanner3";
import DownloadApp2 from "../../components/common/DownloadApp2/DownloadApp2";
import Footer3 from "../../components/common/Footer3/Footer3";
import EStoreCarousal from "../../components/common/EStoreCarousal/EStoreCarousal";

const AppStore = () => {
  return (
    <div className="bg-[#1E1E1E]">
      <DownloadAppLanding />
      <AppStats />
      <DownloadMigBox />
      <NearbyGym />
      <AppBanner />
      <VideoPlayer4 />
      <AppBanner2 />
      <EStoreCarousal />
      <AppBanner3 />
      <DownloadApp2 />
      <Footer3 /> 
    </div>
  );
};

export default AppStore;
