import React from 'react'

function VideoPlayerCardMini({ images, title }) {
    return (
        <div className="p-[2.2rem] related">
            <img src={images} alt="" className="w-full h-full rounded-[1.5rem]" />
            <div className="absolute bottom-10 right-8 bg-black text-white text-[2rem] poppins-semibold p-2 px-4 rounded-[1.8rem]">
                58:24
            </div>
            <div className="absolute left-[3rem] bottom-0 text-white text-[4.5rem] poppins-semibold mb-[1.5rem]">
                {title}
            </div>
            <div className="absolute left-[3rem] bottom-0 text-[1.3rem] poppins-regular text-white">
                250 est calories
            </div>
        </div>
    )
}

export default VideoPlayerCardMini
