import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { pauseSubscriptionFailure, pauseSubscriptionSuccess } from '../../actions/SubscriptionManagementActions/pauseSubscriptionAction';

function* pauseSubscription({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.PauseSubscription + `?subscriptionrecordid=${payload.subscriptionId}&CustomerId=${payload.userId}&startDate=${payload.startDate}&endDate=${payload.endDate}`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("PAUSE_SUBSCRIPTION_SUCCESS status :", response.status);
        console.log("PAUSE_SUBSCRIPTION_SUCCESS data :", response.data);
        console.log("PAUSE_SUBSCRIPTION_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            pauseSubscriptionSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("PAUSE_SUBSCRIPTION_FAILURE status :", error?.response?.status);
        console.log("PAUSE_SUBSCRIPTION_FAILURE data:", error?.response?.data);
        console.log("PAUSE_SUBSCRIPTION_FAILURE error :", error);
        console.log('====================================');
        yield put(pauseSubscriptionFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* pauseSubscriptionSaga() {
    yield takeLatest(actionTypes.PAUSE_SUBSCRIPTION_REQUEST, pauseSubscription);
}

export default pauseSubscriptionSaga;