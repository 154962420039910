import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoardFile, getLeaderBoardFileReset } from '../../../store/actions/FileManagementServiceActions/getLeaderBoardFileAction';

const AppStats = () => {

  const dispatch = useDispatch();

  const getLeaderBoardFileReducerData = useSelector((state) => state.getLeaderBoardFileReducer?.data);
  const getLeaderBoardFileReducerStatus = useSelector((state) => state.getLeaderBoardFileReducer?.status);

  const [appDownloadStats, setAppDownloadStats] = useState([]);

  const getTitleByCode = (code) => {
    const found = appDownloadStats?.find(item => item.code === code);
    return found ? found.titleText : "--";  // Return titleText if found, otherwise null
  };

  useEffect(() => {
    dispatch(getLeaderBoardFile())
  }, [])

  useEffect(() => {
    if (getLeaderBoardFileReducerStatus == 200) {
      if (getLeaderBoardFileReducerData?.length > 0) {
        let tempStat = [];
        tempStat = getLeaderBoardFileReducerData?.map((item) => ({ "code": item.code, "titleText": item.titletext }))
        setAppDownloadStats(tempStat);
      }
      dispatch(getLeaderBoardFileReset())
    } else if (getLeaderBoardFileReducerStatus) {
      dispatch(getLeaderBoardFileReset())
    }
  }, [getLeaderBoardFileReducerStatus])

  return (
    <div className='bg-[#1E1E1E] px-[4rem]'>
      <div className="container-lg">
        <div className="flex flex-col sm:flex-row items-center justify-around my-[10rem] sm:my-[12rem]">
          <div className="flex flex-col items-center sm:items-start">
            <h2 className="urbanist-semibold text-[9rem] leading-[11.4rem] text-[#29CC6A] ">{getTitleByCode('appdownload')}</h2>
            <p className="urbanist-medium text-[4rem] text-[#29CC6A]">Downloads</p>
          </div>
          <div className="flex flex-col items-center sm:items-start my-[13.3rem] md:my-0">
            <h2 className="urbanist-semibold text-[9rem] leading-[11.4rem] text-[#FC5555]">{getTitleByCode('Reviews')}</h2>
            <p className="urbanist-medium text-[4rem] text-[#FC5555]">5 Star Reviews</p>
          </div>
          <div className="flex flex-col items-center sm:items-start">
            <h2 className="urbanist-semibold text-[9rem] leading-[11.4rem] text-[#0099FF]">{getTitleByCode('Followers')}</h2>
            <p className="urbanist-medium text-[4rem] text-[#0099FF]">Followers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStats;
