import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_file_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { uploadProfilePicFailure, uploadProfilePicSuccess } from '../../actions/FileManagementServiceActions/uploadProfilePicAction';

function* uploadProfilePic({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_file_management_service,
            endPoint: endpoints.UploadProfilePic,
            data: payload,
            isAuth: true,
            ContentType: 'multipart/form-data',
        });

        console.log('====================================');
        console.log("UPLOAD_PROFILE_PIC_SUCCESS status :", response.status);
        console.log("UPLOAD_PROFILE_PIC_SUCCESS data :", response.data);
        console.log("UPLOAD_PROFILE_PIC_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(uploadProfilePicSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.status
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("UPLOAD_PROFILE_PIC_FAILURE error :", error.response?.status);
        console.log("UPLOAD_PROFILE_PIC_FAILURE message :", error.message);
        console.log("UPLOAD_PROFILE_PIC_FAILURE error :", error);
        console.log('====================================');
        yield put(uploadProfilePicFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* uploadProfilePicSaga() {
    yield takeLatest(actionTypes.UPLOAD_PROFILE_PIC_REQUEST, uploadProfilePic);
}

export default uploadProfilePicSaga;