import React, { useEffect, useState } from 'react'
import SupportLanding from '../../components/common/SupportLanding/SupportLanding'
import Footer3 from '../../components/common/Footer3/Footer3'
import SupportNav from '../../components/common/SupportNav/SupportNav'
import FAQSection from '../../components/common/FAQSection/FAQSection'
import { useDispatch, useSelector } from 'react-redux'
import { Strapifaqs, StrapifaqsReset } from '../../store/actions/StrapiServiceActions/StrapifaqsAction.js'

const Support = () => {

    const dispatch = useDispatch()

    const strapifaqsReducerData = useSelector((state) => state.strapifaqsReducer.data);
    const strapifaqsReducerStatus = useSelector((state) => state.strapifaqsReducer.status);
    
    const [activeTab, setActiveTab] = useState("Fitness")

    useEffect(() => {
        dispatch(Strapifaqs({ category: "fitness" }));
    }, [])

    useEffect(() => {
        if (strapifaqsReducerStatus == 200) {
            dispatch(StrapifaqsReset());
        } else if (strapifaqsReducerStatus) {
            dispatch(StrapifaqsReset());
        }
    }, [strapifaqsReducerStatus])

    useEffect(() => {
        if (activeTab === "Center") {
            dispatch(Strapifaqs({ category: "center" }));
        } else if (activeTab === "MYACCOUNT") {
            dispatch(Strapifaqs({ category: "my-account" }));
        } else if (activeTab === "HomeFitness") {
            dispatch(Strapifaqs({ category: "home-fitness" }));
        } else if (activeTab === "Fitness") {
            dispatch(Strapifaqs({ category: "fitness" }));
        }
    }, [activeTab])

    return (
        <div className='bg-black md:bg-black h-[98vh]'>
            <div className="support-section">
                <div className='px-[4rem]'>
                    <div className="container-lg mb-[4rem]">
                        <SupportLanding />
                        <SupportNav setActiveTab={setActiveTab} />
                    </div>
                </div>
            </div>
            <div className="px-[4rem]">
                <div className="container-lg my-[4rem] flex justify-center items-center">
                    <FAQSection data={strapifaqsReducerData} />
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default Support