import React from 'react'
import { DownloadAppImg } from '../../../static/constants/imgConstants';
import "./DownloadApp.css"

const DownloadApp = () => {
    return (
        <div className='download-app h-[120rem] sm:h-[60rem] xl:h-[75rem] 2xl:h-[80rem] select-none bg-[#1E1E1E] px-[4rem] relative'>
            <div className='hidden md:flex w-full h-[4rem] bg-[#1E1E1E] absolute top-0 left-0 z-[7]' />
            <div className='hidden md:flex w-full h-[2rem] bg-[#1E1E1E] absolute bottom-0 left-0 z-[7]' />
            <div className="container-lg flex flex-col md:flex-row justify-between items-center">
                <div className="mt-[8rem] flex flex-col sm:justify-start justify-center sm:items-start items-center">
                    <div className="sm:hidden h-[57.7rem] rounded-[3rem]">
                        <img src={DownloadAppImg.downloadApp} alt="" className="h-full w-full rounded-[3rem]" />
                    </div>
                    <div className="montserrat-semibold text-[4.8rem] lg:text-[6.4rem] text-white  w-full my-10 md:my-0">
                        Experience Fitness in <br /> Your Pocket
                    </div>
                    <div className="urbanist-regular md:text-[3.2rem] text-[2rem] text-white my-10 mx-2">
                        Download the Fit.zone app now!
                    </div>
                    <div className="flex flex-row justify-start items-center">
                        <a href="https://www.apple.com/in/app-store/" target="_blank" className="">
                            <img src={DownloadAppImg.appleIcon} alt="" className="w-[23rem] h-[7.9rem] cursor-pointer" />
                        </a>
                        <a href="https://play.google.com/store/apps" target="_blank" className="">
                            <img src={DownloadAppImg.googleIcon} alt="" className="w-[23rem] h-[9.4rem] cursor-pointer" />
                        </a>
                    </div>
                </div>
                <div className="hidden md:block w-[45%] rounded-[3rem] z-[8]">
                    <img src={DownloadAppImg.downloadApp2} alt="" className="w-full h-full" />
                </div>
            </div>
        </div>
    )
}

export default DownloadApp