import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastProvider } from "./hooks/useToast";
import store, { persistor } from "./store/configStore";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Home from "./hoc/Home/Home";
import Navbar from "./components/common/Navbar/Navbar";
import FitCenters from "./hoc/FitCenters/FitCenters";
import Breadcrumb from "./components/utils/Breadcrumb/Breadcrumb";
import OurStory from "./hoc/OurStory/OurStory";
import AppStore from "./hoc/AppStore/AppStore";
import AboutUs from "./hoc/AboutUs/AboutUs";
import NotFoundPage from "./components/utils/NotFoundPage/NotFoundPage";
import PackagesDetails from "./hoc/PackagesDetails/PackagesDetails";
import NavbarMini from "./components/common/NavbarMini/NavbarMini";
import PurchasePackages from "./hoc/PurchasePackages/PurchasePackages";
import GymPage from "./hoc/GymPage/GymPage";
import Support from "./hoc/Support/Support";
import ScrollToTop from "./components/utils/ScrollToTop/ScrollToTop";
import LoginSec from "./components/common/LoginSec/LoginSec";
import Profile from "./hoc/Profile/Profile";
import useScrollDirection from "./hooks/useScrollDirection";
import Community from "./hoc/Community/Community";
import AllBlogs from "./hoc/AllBlogs/AllBlogs";
import CategoryBlogs from "./hoc/CategoryBlogs/CategoryBlogs";
import BlogDetail from "./hoc/BlogDetail/BlogDetail";
import "./App.css";

function App() {
  const location = useLocation();
  const scrollDirection = useScrollDirection(); // Use the custom hook to get scroll direction

  const showNavbar = location.pathname.includes("/profile") || location.pathname.includes("/login");
  const showBreadcrumb = location.pathname.includes("/fit-center") || location.pathname.includes("/help") || location.pathname.includes("/fit-partner") || location.pathname.includes("/our-story") || location.pathname.includes("/community");
  const showNavbarMini = location.pathname.split("/")[location.pathname.split("/").length - 1] === "fit-prime" || location.pathname.split("/")[location.pathname.split("/").length - 1] === "fit-champ";

  // Hide NavbarMini and Breadcrumb on scroll up
  const isNavbarMiniVisible = scrollDirection === 'up' && showNavbarMini;
  const isBreadcrumbVisible = scrollDirection === 'up' && showBreadcrumb;

  window.addEventListener('keydown', function (event) {
    if ((event.ctrlKey === true || event.metaKey === true) && (event.which === 61 || event.which === 107 || event.which === 187 || event.which === 189 || event.which === 109)) {
      event.preventDefault();
    }
  }, false);

  window.addEventListener('wheel', function (event) {
    if (event.ctrlKey === true) {
      event.preventDefault();
    }
  }, { passive: false });


  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastProvider defaultPosition="top-center" defaultDuration={5000}>
          <div className="App relative" data-test="component-app">
            <Navbar show={!showNavbar} />
            <div className="hidden md:block">
              <NavbarMini show={isNavbarMiniVisible} />
            </div>
            {/* Show breadcrumbs on certain routes */}
            <div className="hidden md:block">
              <Breadcrumb show={isBreadcrumbVisible} isTop={!showNavbarMini} />
            </div>
            <Routes>
              <Route path="/login" element={<LoginSec />} />
              <Route path="/" element={<Home />} />
              <Route path="/fit-center/:package_type" element={<FitCenters />} />
              <Route path="/fit-center/:package_type/gym/:gym_id" element={<GymPage />} />
              <Route path="/fit-center/:package_type/:package_id" element={<PackagesDetails />} />
              <Route path="/fit-center/:package_type/:package_id/:pay_step" element={<PurchasePackages />} />
              <Route path="/our-story" element={<OurStory />} />
              <Route path="/app-store" element={<AppStore />} />
              <Route path="/fit-partner" element={<AboutUs />} />
              <Route path="/help" element={<Support />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/community" element={<Community />} />
              <Route path="/community/:category" element={<AllBlogs />} />
              <Route path="/community/blogs/:category" element={<CategoryBlogs />} />
              <Route path="/community/blogs/:category/:blogId" element={<BlogDetail />} />
              {/* Add more routes here */}
              <Route element={<NotFoundPage />} /> {/* This will catch all unmatched routes */}
            </Routes>
            {/* ScrollToTop component inside App component */}
            <ScrollToTop />
          </div>
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
