import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import generateOtpReducer from "./authReducers/generateOtpReducer";
import utilReducers from "./utilReducers/utilReducers";
import validateOtpReducer from "./authReducers/validateOtpReducer";
import subscriptionReducers from "./SubscriptionManagementReducers/subscriptionReducers";
import searchByLocationReducers from "./venueManagementServiceReducers/searchByLocationReducers";
import downloadOrderInvoicePdfReducer from "./orderManagementReducers/downloadOrderInvoicePdfReducer";
import emailInvoiceReducer from "./orderManagementReducers/emailInvoiceReducer";
import orderCreateReducers from "./orderManagementReducers/orderCreateReducers";
import orderTransactionStatusReducer from "./orderManagementReducers/orderTransactionStatusReducer";
import contactUsReducers from "./leadgenServiceReducers/contactUsReducers";
import refreshTokenReducer from "./authReducers/refreshTokenReducer";
import makePaymentReducer from "./paymentGatewayReducers/makePaymentReducer";
import checkPaymentStatusReducer from "./paymentGatewayReducers/checkPaymentStatusReducer";
import GeocodeReducer from "./GoogleApiReducers/GeocodeReducer";
import customerProfileReducer from "./CustomerManagementServiceReducers/customerProfileReducer";
import uploadProfilePicReducer from "./FileManagementServiceReducers/uploadProfilePicReducer";
import updateProfileReducer from "./CustomerManagementServiceReducers/updateProfileReducer";
import subscriptionRecordReducer from "./SubscriptionManagementReducers/subscriptionRecordReducer";
import CMSProductsReducer from "./DotCMSServiceReducers/CMSProductsReducer";
import allOrdersReducer from "./orderManagementReducers/allOrdersReducer";
import createTicketReducer from "./helpdeskServiceReducers/createTicketReducer";
import strapiTokenReducer from "./StrapiServiceReducers/strapiTokenReducer";
import strapiArticlesReducer from "./StrapiServiceReducers/strapiArticlesReducer";
import strapiClientFeedbackReducer from "./StrapiServiceReducers/strapiClientFeedbackReducer";
import strapiPressAndMediaReducer from "./StrapiServiceReducers/strapiPressAndMediaReducer";
import strapiOurFitTeamReducer from "./StrapiServiceReducers/strapiOurFitTeamReducer";
import strapiFounderReducer from "./StrapiServiceReducers/strapiFounderReducer";
import GetFitnessAssessmentReducer from "./FitnessAssessementServiceReducers/GetFitnessAssessmentReducer";
import getTicketByUserIdReducer from "./helpdeskServiceReducers/getTicketByUserIdReducer";
import pauseSubscriptionReducer from "./SubscriptionManagementReducers/pauseSubscriptionReducer";
import unPauseSubscriptionReducer from "./SubscriptionManagementReducers/unPauseSubscriptionReducer";
import subscriptionUpgradeSummaryReducer from "./SubscriptionManagementReducers/subscriptionUpgradeSummaryReducer";
import venueStaffReducer from "./VenueStaffManagementServiceReducers/venueStaffReducer";
import strapifaqsReducer from "./StrapiServiceReducers/strapifaqsReducer";
import gxSessionReducer from "./GxSessionManagementServiceReducers/gxSessionReducer";
import getReferralCodeReducer from "./authReducers/getReferralCodeReducer";
import referFriendReducer from "./authReducers/referFriendReducer";
import fitcoinhistoryReduecer from "./RewardManagementServiceReducers/fitcoinhistoryReduecer";
import subscribeReducer from "./leadgenServiceReducers/subscribeReducer";
import getLeaderBoardFileReducer from "./FileManagementServiceReducers/getLeaderBoardFileReducer";
import trainerRatingReducer from "./userFeedbackManagementServiceReducers/trainerRatingReducer";
import communitySubCategoriesReducer from "./StrapiServiceReducers/communitySubCategoriesReducer";
import communityReducer from "./StrapiServiceReducers/communityReducer";
import getVenueDetailsReducer from "./venueManagementServiceReducers/getVenueDetailsReducer";
import offerSearchReducer from "./SubscriptionManagementReducers/offerSearchReducer";
import subscriptionOffersReducers from "./SubscriptionManagementReducers/subscriptionOffersReducers";
import getSubscriptionCategoryNameReducer from "./SubscriptionManagementReducers/getSubscriptionCategoryNameReducer";
import fitcoinBalanceReducer from "./RewardManagementServiceReducers/fitcoinBalanceReducer";
import RewardConfigAllReducer from "./RewardManagementServiceReducers/RewardConfigAllReducer";
import filehandlerReducer from "./FileManagementServiceReducers/filehandlerReducer";

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		// add reducers to persist data
		'utilReducers',
		"searchByLocationReducers",
		"subscriptionReducers",
		"orderCreateReducers",
		"communitySubCategoriesReducer",
		"getSubscriptionCategoryNameReducer"
	],
};

const appReducer = combineReducers({
	utilReducers: utilReducers,
	generateOtpReducer: generateOtpReducer,
	validateOtpReducer: validateOtpReducer,
	refreshTokenReducer: refreshTokenReducer,
	subscriptionReducers: subscriptionReducers,
	searchByLocationReducers: searchByLocationReducers,
	contactUsReducers: contactUsReducers,
	makePaymentReducer: makePaymentReducer,
	checkPaymentStatusReducer: checkPaymentStatusReducer,
	GeocodeReducer: GeocodeReducer,
	emailInvoiceReducer: emailInvoiceReducer,
	orderCreateReducers: orderCreateReducers,
	downloadOrderInvoicePdfReducer: downloadOrderInvoicePdfReducer,
	customerProfileReducer: customerProfileReducer,
	uploadProfilePicReducer: uploadProfilePicReducer,
	updateProfileReducer: updateProfileReducer,
	subscriptionRecordReducer: subscriptionRecordReducer,
	CMSProductsReducer: CMSProductsReducer,
	allOrdersReducer: allOrdersReducer,
	createTicketReducer: createTicketReducer,
	orderTransactionStatusReducer: orderTransactionStatusReducer,
	strapiTokenReducer: strapiTokenReducer,
	strapiArticlesReducer: strapiArticlesReducer,
	strapiClientFeedbackReducer: strapiClientFeedbackReducer,
	strapiPressAndMediaReducer: strapiPressAndMediaReducer,
	strapiOurFitTeamReducer: strapiOurFitTeamReducer,
	strapiFounderReducer: strapiFounderReducer,
	GetFitnessAssessmentReducer: GetFitnessAssessmentReducer,
	getTicketByUserIdReducer: getTicketByUserIdReducer,
	pauseSubscriptionReducer: pauseSubscriptionReducer,
	unPauseSubscriptionReducer: unPauseSubscriptionReducer,
	subscriptionUpgradeSummaryReducer: subscriptionUpgradeSummaryReducer,
	venueStaffReducer: venueStaffReducer,
	strapifaqsReducer: strapifaqsReducer,
	gxSessionReducer: gxSessionReducer,
	getReferralCodeReducer: getReferralCodeReducer,
	referFriendReducer: referFriendReducer,
	fitcoinhistoryReduecer: fitcoinhistoryReduecer,
	subscribeReducer: subscribeReducer,
	getLeaderBoardFileReducer: getLeaderBoardFileReducer,
	trainerRatingReducer: trainerRatingReducer,
	communityReducer: communityReducer,
	communitySubCategoriesReducer: communitySubCategoriesReducer,
	getVenueDetailsReducer: getVenueDetailsReducer,
	offerSearchReducer: offerSearchReducer,
	subscriptionOffersReducers: subscriptionOffersReducers,
	getSubscriptionCategoryNameReducer: getSubscriptionCategoryNameReducer,
	fitcoinBalanceReducer: fitcoinBalanceReducer,
	RewardConfigAllReducer: RewardConfigAllReducer,
	filehandlerReducer: filehandlerReducer,
})

const rootReducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return {
			...state,
			utilReducers: undefined,
			generateOtpReducer: undefined,
			validateOtpReducer: undefined,
			refreshTokenReducer: undefined,
			makePaymentReducer: undefined,
			checkPaymentStatusReducer: undefined,
			emailInvoiceReducer: undefined,
			orderCreateReducers: undefined,
			orderTransactionStatusReducer: undefined,
			downloadOrderInvoicePdfReducer: undefined,
			customerProfileReducer: undefined,
			uploadProfilePicReducer: undefined,
			updateProfileReducer: undefined,
			subscriptionRecordReducer: undefined,
			allOrdersReducer: undefined,
			createTicketReducer: undefined,
			GetFitnessAssessmentReducer: undefined,
			getTicketByUserIdReducer: undefined,
			pauseSubscriptionReducer: undefined,
			unPauseSubscriptionReducer: undefined,
			subscriptionUpgradeSummaryReducer: undefined,
			getReferralCodeReducer: undefined,
			referFriendReducer: undefined,
			fitcoinhistoryReduecer: undefined,
			subscribeReducer: undefined,
			getLeaderBoardFileReducer: undefined,
		}
	}

	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
