import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiOurFitTeam = (payload) => ({
    type: actionTypes.STRAPI_OUR_FIT_TEAM_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiOurFitTeamSuccess = (payload) => ({
    type: actionTypes.STRAPI_OUR_FIT_TEAM_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiOurFitTeamFailure = (payload) => ({
    type: actionTypes.STRAPI_OUR_FIT_TEAM_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiOurFitTeamReset = () => ({
    type: actionTypes.STRAPI_OUR_FIT_TEAM_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiOurFitTeamResetAll = () => ({
    type: actionTypes.STRAPI_OUR_FIT_TEAM_RESET_ALL,
}); 