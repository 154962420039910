import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { containerStyle, defaultCenter, mapStyles } from './mapStyles';
import './MapComponent.css';
import { IoLocationSharp } from "react-icons/io5";
import { AboutUsImg } from '../../../static/constants/imgConstants';

function MapComponent({ geoLocation, venueName, searchByLocationReducersData }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U' // TODO get the key from fitzones developer account
    });

    const handleMarkerClick = (latitude, longitude) => {
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(googleMapsUrl, '_blank');
    };

    return isLoaded ? (
        <section className="map-component w-full flex justify-center">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={11}
            // options={{
            //   styles: mapStyles
            // }}
            >
                {!geoLocation ? (
                    searchByLocationReducersData?.venues
                        ?.filter((item) => item.geolocation)
                        .map((item, index) => (
                            <Marker
                                key={index}
                                position={{ lat: Number(item.geolocation.latitude), lng: Number(item.geolocation.longitude) }}
                                icon={{
                                    url: AboutUsImg.location, // Replace with URL to your preferred icon
                                    scaledSize: new window.google.maps.Size(30, 70), // Adjust size as needed
                                    anchor: new window.google.maps.Point(15, 20)  // Adjust anchor point for custom offset
                                }}
                                label={{
                                    text: item.name,
                                    color: 'blue',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    labelClass: 'marker',
                                    labelInBackground: false,
                                }}
                                onClick={() => handleMarkerClick(item.geolocation.latitude, item.geolocation.longitude)}
                            />
                        ))
                ) : (
                    <Marker
                        position={{ lat: Number(geoLocation?.latitude), lng: Number(geoLocation?.longitude) }}
                        icon={{
                            url: AboutUsImg.location, // Replace with URL to your preferred icon
                            scaledSize: new window.google.maps.Size(30, 70), // Adjust size as needed
                            anchor: new window.google.maps.Point(15, 20)  // Adjust anchor point for custom offset
                        }}
                        label={{
                            text: venueName,
                            color: 'blue',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            labelClass: 'marker',
                            labelInBackground: false,
                        }}
                        onClick={() => handleMarkerClick(geoLocation.latitude, geoLocation.longitude)}
                    />
                )}
            </GoogleMap>
        </section>
    ) : (
        <></>
    );
}

export default MapComponent;