import React, { useEffect } from 'react';
import { FounderImg } from '../../../static/constants/imgConstants';
import './FounderCard.css'
import { useDispatch, useSelector } from 'react-redux';
import { strapiFounderReset } from '../../../store/actions/StrapiServiceActions/strapiFounderAction';
import { base_url_strapi_service } from '../../../store/constants';

const FounderCard = () => {

    const dispatch = useDispatch()

    const strapiFounderReducerData = useSelector((state) => state.strapiFounderReducer?.data);
    const strapiFounderReducerStatus = useSelector((state) => state.strapiFounderReducer?.status);

    useEffect(() => {
        if (strapiFounderReducerStatus == 200) {
            dispatch(strapiFounderReset())
        } else if (strapiFounderReducerStatus) {
            dispatch(strapiFounderReset())
        }
    }, [strapiFounderReducerStatus])

    return (
        <div className='bg-[#1E1E1E] px-[4rem]'>
            <div className='md:container-lg'>
                <div className='flex flex-col md:flex-row justify-evenly md:justify-end items-end py-[5rem]'>
                    <div className='w-full md:w-[27%] h-full flex flex-col justify-end items-start md:mr-[5rem]'>
                        <div className="text-[4.8rem] urbanist-extrabold text-[#FFFFFF1C]/10">
                            From the
                        </div>
                        <h2 className='text-[#fff] text-[9.6rem] urbanist-semibold mb-[4.4rem]'>
                            Founder
                        </h2>
                        <div className='rounded-[2rem]'>
                            {strapiFounderReducerData?.length > 0 ?
                                strapiFounderReducerData?.map((items) => {
                                    return (
                                        <img
                                            className="w-full h-full"
                                            loading="lazy"
                                            alt="Group of people"
                                            src={`${base_url_strapi_service}${items?.image?.url}`}
                                        />
                                    )
                                })
                                :
                                <img
                                    className="w-full h-full"
                                    loading="lazy"
                                    alt="Group of people"
                                    src={FounderImg.FounderImg1}
                                />
                            }
                        </div>
                    </div>
                    <div className='w-full md:w-[60%] h-full bg-[#FAFF00] text-[#000] p-[2.4rem] rounded-[1.2rem] min-h-[46rem] my-[3rem] md:my-0'>
                        <div className='w-[3.2rem] h-[3.2rem] mt-[1.6rem] mb-[2.4rem]'>
                            <img
                                className="w-full] h-full"
                                loading="lazy"
                                alt="treadmill-logo"
                                src={FounderImg.FounderCardIcon}
                            />
                        </div>
                        {strapiFounderReducerData?.length > 0 ?
                            strapiFounderReducerData?.map((items) => {
                                return (
                                    <div className="">
                                        <div className="text-[2.4rem] poppins-semibold mt-[1rem] mb-[.8rem]">
                                            {items?.title}
                                        </div>
                                        <div className="text-[2rem] tracking-wider mt-2 urbanist-medium">
                                            {items?.content}
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="text-[2.4rem] poppins-semibold">
                                No Data Available at this time
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FounderCard;
