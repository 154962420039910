import { TfiHome } from "react-icons/tfi";
import { FaRegUser } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { RxCountdownTimer } from "react-icons/rx";
import { FaRegFolderOpen } from "react-icons/fa";
import { BsTicket } from "react-icons/bs";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RxExit } from "react-icons/rx";

export const SidebarData = [
    {
        title: "My Dashboard",
        icon: <TfiHome size={22} />,
        route: "MyDashboard"
    },
    {
        title: "Profile",
        icon: <FaRegUser size={22} />,
        route: "ProfileInfo"
    },
    {
        title: "Orders and Invoice",
        icon: <GrDocumentPdf size={22} />,
        route: "OrdersInvoice"
    },
    {
        title: "Subscription",
        icon: <RxCountdownTimer size={22} />,
        route: "Subscription"
    },
    {
        title: "Assessment Reports",
        icon: <FaRegFolderOpen size={22} />,
        route: "AssessmentReports"
    },
    {
        title: "ReFerral and Coupons",
        icon: <BsTicket size={22} />,
        route: "ReFerralCoupons"
    },
    {
        title: "Support",
        icon: <IoChatbubblesOutline size={22} />,
        route: "Support"
    },
    {
        title: "Logout",
        icon: <RxExit size={22} />,
        route: "Logout"
    },
]