import React from 'react'
import { IoMdStarOutline } from "react-icons/io";
import { base_url_strapi_service } from '../../../store/constants';

function ClientFeeBackCard({ feedback, rating, userPic, userName }) {

    // Using a for loop to iterate over items and render JSX
    const ratingIcons = [];
    for (let i = 0; i < rating; i++) {
        ratingIcons.push(<IoMdStarOutline color='#FF4300' size={28} className='mr-[1rem]' />);
    }

    return (
        <div className='border-b-[1rem] border-[#FAFF00] p-[4rem] bg-[#05050599]/60 h-[44rem] flex flex-col justify-between items-start'>
            <div className="h-[80%]">
                <pre className="text-white outfit-regular text-[2.4rem] leading-normal tracking-wider overflow-hidden h-[80%]" dangerouslySetInnerHTML={{ __html: feedback }}>
                    
                </pre>
               <div className="flex text-white my-5">
                   {ratingIcons}
               </div>
            </div>
            <div className="text-white flex justify-start items-center h-[20%] overflow-hidden">
                <div className="w-[6rem] h-[6rem] rounded-full">
                    <img src={`${base_url_strapi_service}${userPic}`} alt="" className="w-full h-full" />
                </div>
                <div className="text-white outfit-regular text-[2.4rem] md:text-[2rem] xl:text-[2.4rem] mx-4">
                    {userName}
                </div>
            </div>
        </div>
    )
}

export default ClientFeeBackCard
