import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IoCheckmark } from 'react-icons/io5'
import { useToast } from '../../../hooks/useToast';
import { DownloadAppLandingImg } from '../../../static/constants/imgConstants';
import { OrderCreate, OrderCreateReset } from '../../../store/actions/orderManagementActions/orderCreateActions';
import { makePayment, makePaymentReset } from '../../../store/actions/paymentGatewayActions/makePaymentActions';
import { checkPaymentStatus, checkPaymentStatusReset } from '../../../store/actions/paymentGatewayActions/checkPaymentStatusActions';
import "./PayStepForm.css"
import { OrderTransactionStatus, OrderTransactionStatusReset } from '../../../store/actions/orderManagementActions/orderTransactionStatusActions';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { logout, setUpgradeUnable } from '../../../store/actions/utilsActions/utilsActions';
import { refreshToken, refreshTokenReset } from '../../../store/actions/authActions/refreshTokenActions';
import { offerSearch } from '../../../store/actions/SubscriptionManagementActions/offerSearchAction';
import { fitcoinhistory } from '../../../store/actions/RewardManagementServiceActions/fitcoinhistoryAction';
import { fitcoinBalance, fitcoinBalanceReset } from '../../../store/actions/RewardManagementServiceActions/fitcoinBalanceAction';
import { RewardConfigAll, RewardConfigAllReset } from '../../../store/actions/RewardManagementServiceActions/RewardConfigAllAction';
import { customerProfile, customerProfileReset } from '../../../store/actions/CustomerManagementServiceActions/customerProfileAction';
// import Select from 'react-dropdown-select';

const PayStepForm = () => {

    const Toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { package_type, package_id, pay_step } = useParams();

    const orderCreateReducer = useSelector((state) => state.orderCreateReducers?.data);
    const utilReducers = useSelector((state) => state.utilReducers);
    const orderCreateReducerStatus = useSelector((state) => state.orderCreateReducers?.status);
    const orderCreateReducerLoading = useSelector((state) => state.orderCreateReducers?.loading);
    const makePaymentReducer = useSelector((state) => state.makePaymentReducer?.data);
    const makePaymentReducerStatus = useSelector((state) => state.makePaymentReducer?.status);
    const makePaymentReducerLoading = useSelector((state) => state.makePaymentReducer?.loading);
    const checkPaymentStatusReducer = useSelector((state) => state.checkPaymentStatusReducer?.data);
    const checkPaymentStatusReducerStatus = useSelector((state) => state.checkPaymentStatusReducer?.status);
    const orderTransactionStatusReducerStatus = useSelector((state) => state.orderTransactionStatusReducer?.status);
    const orderTransactionStatusReducerLoading = useSelector((state) => state.orderTransactionStatusReducer?.loading);
    const orderTransactionStatusReducerData = useSelector((state) => state.orderTransactionStatusReducer?.data);
    const refreshTokenReducerStatus = useSelector((state) => state.refreshTokenReducer.status);
    const refreshTokenReducerLoading = useSelector((state) => state.refreshTokenReducer.loading);
    const getSubscriptionCategoryNameReducerData = useSelector((state) => state.getSubscriptionCategoryNameReducer?.data);
    const offerSearchReducerData = useSelector((state) => state.offerSearchReducer?.data);
    const fitcoinBalanceReduecerStatus = useSelector((state) => state.fitcoinBalanceReduecer?.status);
    const fitcoinBalanceReduecerLoading = useSelector((state) => state.fitcoinBalanceReduecer?.loading);
    const fitcoinBalanceReduecerData = useSelector((state) => state.fitcoinBalanceReducer?.data);
    const RewardConfigAllReducerData = useSelector((state) => state.RewardConfigAllReducer?.data);
    const RewardConfigAllReducerStatus = useSelector((state) => state.RewardConfigAllReducer?.status);
    const CustomerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);


    const [redirectUrl, setRedirectUrl] = useState('');
    const [offerApplied, setOfferApplied] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState(false);
    const [coupon, setCoupon] = useState("")
    const [offer, setOffer] = useState("");
    const [fitCoinValue, setFitCoinValue] = useState();
    const [fitcoinCheckbox, setFitcoinCheckbox] = useState(false)

    function getValueByName(properties, name) {
        const property = properties?.find(prop => prop.name === name);
        return property ? property?.value : null;
    }

    const getDateTime = (date) => {
        // const date = new Date();
        const [hours, minutes, seconds] = "00:00:00".split(':').map(Number);
        date.setHours(hours, minutes, seconds); // Sets hours, minutes, and seconds
        const isoString = date.toISOString();
        return isoString;
    }

    const parsePoints = (htmlString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        const paragraphs = Array.from(tempDiv.getElementsByTagName('p')).map(p => p.innerHTML);
        return paragraphs;
    }

    const parsedPoints = parsePoints(utilReducers?.plan?.description);

    const totalAmountWithoutDiscountAndTax = utilReducers?.plan?.totalAmount;

    const totalPriceAfterDiscount = ((utilReducers?.plan?.totalAmount) - (offer[0]?.offerValue ? offer[0]?.offerValue : 0))
        -
        (utilReducers?.plan?.offers?.filter((item) => item?.offerValueType == "INR" && item.isActive)?.reduce((sum, item) => sum + Number(item.offerValue || 0), 0))
        -
        (fitcoinCheckbox && fitcoinBalanceReduecerData?.balancePoints != 0 ?
            (RewardConfigAllReducerData?.data?.filter((item) => item?.eventSubSectionType == "membership_purchase_redeem_limit" && item?.fitCoinValueType == "percentage")
                ? (totalAmountWithoutDiscountAndTax *
                    RewardConfigAllReducerData?.data?.filter((item) =>
                        item?.eventSubSectionType == "membership_purchase_redeem_limit")[0]?.fitCoinValue) / 100
                :
                RewardConfigAllReducerData?.data?.filter((item) => item?.eventSubSectionType == "membership_purchase_redeem_limit")[0]?.fitCoinValue
            )
            :
            0
        );
    const totalTax = (totalPriceAfterDiscount * 18) / 100;
    // const totalTaxWithoutDiscount = (utilReducers?.plan?.totalAmount * 18) / 100;
    const totalAmount = totalPriceAfterDiscount + totalTax;




    const HandleTotalFreeFitcoin = () => {
        if (fitcoinBalanceReduecerData?.balancePoints <
            totalAmount -
            ((totalAmountWithoutDiscountAndTax *
                RewardConfigAllReducerData?.data?.filter((item) =>
                    item?.eventSubSectionType == "membership_purchase_redeem_limit")[0]?.fitCoinValue) / 100
            )) {
            if (RewardConfigAllReducerData?.data?.filter((item) => item?.eventSubSectionType == "membership_purchase_redeem_limit" && item?.fitCoinValueType == "percentage")) {
                setFitCoinValue(
                    totalAmount -
                    fitcoinBalanceReduecerData?.balancePoints
                )
            } else {
                setFitCoinValue(totalAmountWithoutDiscountAndTax - fitcoinBalanceReduecerData?.balancePoints)
            }
        } else {
            if (RewardConfigAllReducerData?.data?.filter((item) => item?.eventSubSectionType == "membership_purchase_redeem_limit" && item?.fitCoinValueType == "percentage")) {
                setFitCoinValue(
                    totalAmount -
                    ((totalAmountWithoutDiscountAndTax *
                        RewardConfigAllReducerData?.data?.filter((item) =>
                            item?.eventSubSectionType == "membership_purchase_redeem_limit")[0]?.fitCoinValue) / 100
                    )
                )
            } else {
                setFitCoinValue(totalAmountWithoutDiscountAndTax - RewardConfigAllReducerData?.data?.filter((item) => item?.eventSubSectionType == "membership_purchase_redeem_limit")[0]?.fitCoinValue)
            }
        }

    }

    const calculateActiveOfferDiscount = () => {
        return utilReducers?.plan?.offers
            ?.filter((item) => item?.offerValueType === "INR" && item.isActive)
            ?.reduce((sum, item) => sum + Number(item.offerValue || 0), 0) || 0;
    };



    const calculateFitcoinDiscount = () => {
        if (!fitcoinCheckbox || fitcoinBalanceReduecerData?.balancePoints === 0) {
            return 0; // No Fitcoin discount if checkbox is unchecked or balance is zero
        }

        const fitcoinConfig = RewardConfigAllReducerData?.data?.filter(
            (item) => item?.eventSubSectionType === "membership_purchase_redeem_limit"
        );

        const fitcoinPercentageValue = fitcoinConfig?.find((item) => item?.fitCoinValueType === "percentage")?.fitCoinValue;
        const fitcoinFixedValue = fitcoinConfig?.find((item) => item?.fitCoinValueType !== "percentage")?.fitCoinValue;

        const percentageDiscount = fitcoinPercentageValue
            ? (totalAmountWithoutDiscountAndTax * fitcoinPercentageValue) / 100
            : 0;

        const applicableDiscount = fitcoinPercentageValue ? percentageDiscount : fitcoinFixedValue || 0;

        // Handle cases where balance is less than the applicable discount
        const fitcoinBalance = fitcoinBalanceReduecerData?.balancePoints;
        return fitcoinBalance < applicableDiscount ? fitcoinBalance : applicableDiscount;
    };

    const totalPriceAfterDiscountWithFitcoins =
        (utilReducers?.plan?.totalAmount || 0) -
        (offer[0]?.offerValue || 0) -
        calculateActiveOfferDiscount() -
        calculateFitcoinDiscount();

    /* const applicableCustomerApplicationTypes = ["Mobileapp"];
    const applicableCustomerStatusTypes = userCategory == "TRIAL_USER" || userCategory == "FULL_PACK_PAID_USER" || userCategory == "HALF_PACK_PAID_USER"
        ?
        ["Member"]
        : userCategory == "UNPAID_USER" ?
            ["Nonmember"]
            : userCategory == "INACTIVE" ?
                ["Priormember"]
                : [""];

    const applicablePackageTypes = data?.subscriptionCategoryName == "Champion" ? ["Champion"] : ["Premium"];

    const filteredOfferData = GetAllGenericOfferReducerData?.filter((item) => {
        return item?.applicableCustomerApplicationTypes?.some(type => applicableCustomerApplicationTypes.includes(type)) &&
            item?.applicableCustomerStatusTypes?.some(status => applicableCustomerStatusTypes.includes(status)) &&
            item?.applicablePackageTypes?.some(packageType => applicablePackageTypes.includes(packageType)) &&
            item?.isActive == true;
    }); */


    /* useEffect(() => {
        if (pay_step === "pay-status") {
            orderCreateReducer['orderStatus'] = 2;
            orderCreateReducer['transactionStatus'] = 1;
            dispatch(OrderTransactionStatus(orderCreateReducer));
        }
    }, [pay_step]); */
    let isCalled = false;
    useMemo(() => {
        if (pay_step === "pay-status" && !isCalled) {
            /* dispatch(checkPaymentStatus({
                PhonePeMerchantId: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_MERCHANTID')[0],
                PhonePeMerchantTransId: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_MERCHANT_TRANSID')[0],
                PhonePeTransCheckXVerify: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_TRANSCHECK_X_VERIFY')[0],
            })); */

            orderCreateReducer['orderStatus'] = 2;
            orderCreateReducer['transactionStatus'] = 1;
            isCalled = true;
            dispatch(OrderTransactionStatus(orderCreateReducer, (status, response, message) => {
                if (status == 200) {
                    Toast("Payment Successful!🎉", "success")
                    dispatch(OrderTransactionStatusReset());
                    // navigate(`/fit-center/${package_type}/${package_id}/pay-status`);

                }
                else if (status) {
                    Toast("Payment Failed", "error");
                    dispatch(OrderTransactionStatusReset());
                    navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
                }
            }));
        }
    }, [pay_step])

    useEffect(() => {
        console.log("orderCreateReducerStatus :", orderCreateReducer);
        if (orderCreateReducerStatus === 200) {
            console.log("orderCreateReducerStatus 2 :", orderCreateReducerStatus);
            dispatch(makePayment({
                PhonePeRequestJSONBase64: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_REQUESTJSONBASE64')[0],
                PhonePeHash: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_HASH')[0],
            }));
            dispatch(OrderCreateReset());
        }
    }, [orderCreateReducerStatus]);

    useEffect(() => {
        if (makePaymentReducerStatus === 200) {
            dispatch(makePaymentReset());
            const redirect = makePaymentReducer.data.instrumentResponse.redirectInfo.url;
            setRedirectUrl(redirect);
            navigate(`/fit-center/${package_type}/${package_id}/payment`);
        } else if (makePaymentReducerStatus == 429) {
            Toast("Payment link is not working! Please try again later");
            dispatch(makePaymentReset());
        } else if (makePaymentReducerStatus) {
            Toast("Payment link is not working! Please try again later");
            dispatch(makePaymentReset());
        }
    }, [makePaymentReducerStatus]);

    /* useEffect(() => {
        if (checkPaymentStatusReducerStatus === 200) {
            Toast("Payment Successful!🎉")
            dispatch(checkPaymentStatusReset());
        } else if (checkPaymentStatusReducerStatus) {
            Toast("Payment Failed");
            dispatch(checkPaymentStatusReset());
        }

    }, [checkPaymentStatusReducerStatus]); */

    /* useEffect(() => {
        if (!redirectUrl) {
            navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
        }
    }, [redirectUrl]) */

    /*  useEffect(() => {
         if (orderTransactionStatusReducerStatus == 200) {
             Toast("Payment Successful!🎉", "success")
             dispatch(OrderTransactionStatusReset());
             navigate(`/fit-center/${package_type}/${package_id}/pay-status`);
         } else if (orderTransactionStatusReducerStatus) {
             Toast("Payment Failed", "error");
             dispatch(OrderTransactionStatusReset());
             navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
         }
     }, [orderTransactionStatusReducerStatus]) */

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            // Handle your custom back button logic here, e.g.:
            navigate("/");
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    useEffect(() => {

        dispatch(offerSearch());
        dispatch(fitcoinBalance({ userId: utilReducers.userId }));
        dispatch(RewardConfigAll());
    }, [])



    useMemo(() => {
        if (refreshTokenReducerStatus == 200) {
            dispatch(OrderCreate({
                "orderType": 1,
                "orderNumber": "",
                "orderDate": new Date(),
                "customerId": utilReducers?.userId,
                "billingAddress": {
                    "line1": "",
                    "line2": "",
                    "city": "",
                    "pincode": "",
                    "state": "",
                    "country": "",
                    "zone": ""
                },
                "orderItems": [
                    {
                        "type": utilReducers.setUpgradeUnable ? 3 : 1,
                        "refId": utilReducers?.plan?.id,
                        "refName": utilReducers?.plan?.title,
                        "itemRefCode": utilReducers?.plan?.packagecode,
                        "refSubscriptionCategoryCode": utilReducers?.plan?.subscriptionCategoryCode,
                        "itemDetails": ""
                    }
                ],
                "offersApplied": [
                    {
                        "offerId": offer[0]?.id != null ? offer[0]?.id : "",
                        "offerCode": offer[0]?.offerCode != null ? offer[0]?.offerCode : "",
                        "offerName": offer[0]?.offerName != null ? offer[0]?.offerName : "",
                        "description": offer[0]?.description != null ? offer[0]?.description : "",
                        "offerType": offer[0]?.offerType != null ? offer[0]?.offerType : 1,
                        "offerValue": offer[0]?.offerValue != null ? offer[0]?.offerValue : "",
                        "offerValueType": offer[0]?.offerValueType != null ? offer[0]?.offerValueType : "",
                    }
                ],
                "mrp": utilReducers?.plan?.totalAmount,
                "upgradeAmount": 0,
                "totalDiscountAmount": (offer[0]?.offerValue != null ? offer[0]?.offerValue : 0) + (utilReducers?.plan?.offers?.filter((item) => item?.offerValueType == "INR" && item.isActive) ? utilReducers?.plan?.offers?.filter((item) => item?.offerValueType == "INR" && item.isActive)?.reduce((sum, item) => sum + Number(item.offerValue || 0), 0) : 0),
                "taxes": (totalPriceAfterDiscountWithFitcoins * 18) / 100,
                "totalpayable": totalPriceAfterDiscountWithFitcoins + ((totalPriceAfterDiscountWithFitcoins * 18) / 100),
                "startdate": getDateTime(utilReducers?.selectedPlanDate),
                "venueId": "",
                "trainerUserId": "",
                "transactionId": "",
                "orderStatus": 1,
                "transactionStatus": 1,
                "paymentMode": "",
                "paymentGateway": "",
                "invoiceNumber": "",
                "invoiceDate": "",
                "clientAppType": "web",
                "extendedProperties": [{
                    "Name": "Redeem Points",
                    "Value": [calculateFitcoinDiscount()]
                }],
                "transactionSummary": {
                    "txnStatus": "",
                    "txnMsg": "",
                    "txnErrMsg": "",
                    "tpslBankCd": "",
                    "tpslTxnId": "",
                    "tpslTxnTime": "",
                    "bankTransactionID": "",
                    "txnMerchantId": ""
                },
            }));
            dispatch(refreshTokenReset());
        } else if (refreshTokenReducerStatus) {
            Toast("Session expired! 😓 Please login again", "error");
            dispatch(logout());
            navigate("/")
        }
    }, [refreshTokenReducerStatus]);

    useEffect(() => {
        if (fitcoinBalanceReduecerStatus == 200) {

            fitcoinBalanceReset();
        } else if (fitcoinBalanceReduecerStatus) {
            fitcoinBalanceReset();
        }
    }, [fitcoinBalanceReduecerStatus])


    useEffect(() => {
        if (RewardConfigAllReducerStatus == 200) {
            HandleTotalFreeFitcoin();
            dispatch(RewardConfigAllReset());

        } else if (RewardConfigAllReducerStatus) {
            dispatch(RewardConfigAllReset());
        }
    }, [RewardConfigAllReducerStatus, fitcoinBalanceReduecerData])

    useEffect(() => {
        dispatch(customerProfile(utilReducers?.userId));
    }, [])

    useEffect(() => {
        if (CustomerProfileReducerStatus == 200) {
            dispatch(customerProfileReset());
        } else if (CustomerProfileReducerStatus == 403) {
            dispatch(logout());
            navigate("/");
        }
        else if (CustomerProfileReducerStatus) {
            dispatch(customerProfileReset());
        }
    }, [CustomerProfileReducerStatus])




    /* const options = offerSearchReducerData?.genericoffers?.
        filter((item) => { return item?.applicableCustomerApplicationTypes[0] == 1 && item?.isActive })
        .map(item => ({
            value: item?.offerCode,
            label: item?.offerCode
        })); */


    // console.log("orderTransactionStatusReducerData :", orderTransactionStatusReducerData);
    // console.log("utilReducers?.plan.offers :", utilReducers?.plan?.offers);
    // console.log("Selected offer data:", offer);
    // console.log("coupon :", coupon);
    console.log("fitcoinBalanceReduecerData :", fitcoinBalanceReduecerData);
    // console.log("totalFreeFitcoin :", totalFreeFitcoin);
    console.log("fitcoinValue :", fitCoinValue);
    console.log("utilReducers?.plan :", utilReducers?.selectedPlanDate);



    return (
        <div className="w-full px-[4rem]">
            <div className="container-lg flex flex-col lg:flex-row justify-between items-center lg:items-start pt-[9rem]">
                <div className="w-full lg:w-[50%] flex flex-col justify-start items-start lg:block">
                    <div className="" />
                    <div className="urbanist-semibold text-white text-[4.8rem] mt-[2rem]">
                        {utilReducers.planType == "fit-prime" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan" : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan"}
                    </div>
                    <div className="urbanist-semibold text-white text-[3.6rem] my-[1.5rem]">
                        {utilReducers?.plan?.totalDuration == "1" ? `${utilReducers?.plan?.totalDuration} Month Plan` : `${utilReducers?.plan?.totalDuration} Months Plan`}
                    </div>
                    <div className="">
                        {parsedPoints.map((point) => {
                            return (
                                <div className="flex justify-start items-center my-5">
                                    <IoCheckmark color='#FFF6F6' size={20} className='mr-4' />
                                    <div className="urbanist-medium text-[#FFF6F6] text-[1.6rem]">
                                        <span dangerouslySetInnerHTML={{ __html: point }} />
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    {pay_step === "init-pay" ?
                        <div className="">
                            <div className="urbanist-semibold text-white text-[3.6rem] my-[3rem]">T&C</div>
                            <div className="flex items-center">
                                <input className='mx-2 mr-5' type="checkbox" value={termsAndCondition} onChange={() => setTermsAndCondition(!termsAndCondition)} />
                                <label
                                    for="vehicle1"
                                    className='urbanist-medium text-[#FFF6F6] text-[1.6rem]'
                                >
                                    Agree to All &nbsp;
                                    <span
                                        className="cursor-pointer underline text-[#0ff]"
                                        onClick={() => window.open("https://fit.zone/#/static/terms", '_blank').focus()}
                                    >
                                        Terms and Conditions
                                    </span>
                                </label>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="w-full xl:w-[50%] mt-[2rem]">
                    <div className="flex justify-center items-center text-white w-[97%] mr-5">
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "init-pay" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                1
                            </div>
                        </div>
                        <div className="w-full border-t border-white"></div>
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "payment" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                2
                            </div>
                        </div>
                        <div className="w-full border-t border-white"></div>
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "pay-status" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                3
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center urbanist-medium text-[1.6rem] text-center text-white my-3">
                        <div className="mx-4">Plan</div>
                        <div className="ml-8">Payment</div>
                        <div className="">Confirmation</div>
                    </div>
                    <div className="bg-[#293C52] px-[4rem] md:mx-[8rem] py-[3rem] rounded-[2rem] mt-[2.3rem] relative">
                        {!orderTransactionStatusReducerLoading ?
                            pay_step === "pay-status" ?
                                <div className="flex flex-col justify-center items-center my-[12rem]">
                                    <div className="text-[#EFF7FF] urbanist-semibold text-[5.8rem]">
                                        Congratulations!
                                    </div>
                                    <div className="text-[#0FAD4E] urbanist-semibold text-[2.4rem] my-[3rem] flex justify-between items-center">
                                        Payment received <IoCheckmark color='#0FAD4E' size={28} className='ml-10' />
                                    </div>
                                    <div className="questrial-regular text-[2.4rem] text-white">
                                        Subscription will be activated from {moment(orderTransactionStatusReducerData?.startdate).format("MMM Do YY")}
                                    </div>
                                </div>
                                : redirectUrl && pay_step === "payment" ?
                                    <div className="flex flex-col justify-center items-center">
                                        {window.open(redirectUrl, "_self")}
                                    </div>
                                    :
                                    <div className="text-[#FCFEFF] text-[2.4rem] urbanist-regular">
                                        <div className="urbanist-semibold">Subtotal</div>
                                        <div className="flex justify-between items-center my-[1rem]">
                                            <div className="">{utilReducers?.plan?.totalDuration == "1" ? `${utilReducers?.plan?.totalDuration} Month ${utilReducers.planType == "fit-prime" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan" : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan"}` : `${utilReducers?.plan?.totalDuration} Months ${utilReducers.planType == "fit-prime" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan" : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) }) + " Plan"}`}</div>
                                            <div className="">₹{utilReducers?.plan?.totalAmount}</div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div className="">Discount</div>
                                            <div className="">{utilReducers?.plan?.offers?.filter((item) => item?.offerValueType == "INR" && item.isActive) ? utilReducers?.plan?.offers?.filter((item) => item?.offerValueType == "INR" && item.isActive)?.reduce((sum, item) => sum + Number(item.offerValue || 0), 0) : 0}</div>
                                        </div>
                                        <div className="my-[2.1rem] flex justify-around md:justify-between items-center border border-white p-4 rounded-[1.2rem]">
                                            {/* <div className=" text-[1.8rem]">
                                                {"Get Additional discount of ₹" + (utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValue : 0)}
                                            </div> */}
                                            {/* <select name="" id="" className="bg-transparent outline-none focus:bg-[#293C52] focus:text-white" value={coupon} placeholder='Apply coupon' onChange={(e) => { setCoupon(e.target.value); setOfferApplied(false); setOffer("") }}>
                                                <option value="" className="" selected>Select Coupon</option>
                                                {offerSearchReducerData?.genericoffers?.length > 0 ?
                                                    offerSearchReducerData?.genericoffers?.filter((item) => { return item?.applicableCustomerApplicationTypes[0] == 1 && item?.isActive }).
                                                        map((genericoffersItems) => {
                                                            return (
                                                                <option value={genericoffersItems?.offerCode} className="">{genericoffersItems?.offerCode}</option>
                                                            )
                                                        })
                                                    :
                                                    null
                                                }
                                            </select> */}
                                            <input
                                                type="text"
                                                className="bg-transparent outline-none focus:bg-[#293C52] focus:text-white"
                                                value={coupon || ""}
                                                placeholder="Apply coupon"
                                                list="coupon-list"
                                                onChange={(e) => { setCoupon(e.target.value); setOffer(""); setOfferApplied(false); }}
                                            />
                                            <datalist id="coupon-list">
                                                {offerSearchReducerData?.length > 0 &&
                                                    offerSearchReducerData?.filter((item) => item?.applicableCustomerApplicationTypes[0] == "Website")
                                                        .map((genericoffersItems) => (
                                                            <option value={genericoffersItems?.offerCode} key={genericoffersItems?.offerCode}>
                                                                {genericoffersItems?.offerCode}
                                                            </option>
                                                        ))}
                                            </datalist>
                                            <button
                                                className="urbanist-bold bg-[#FAFF00] enabled:hover:border-gray-400 disabled:opacity-75 text-black rounded-[2.5rem] px-[4rem] sm:px-[2rem] 2xl:px-[4rem] py-[.8rem]"
                                                // disabled={utilReducers?.plan?.offers == null}
                                                onClick={() => {
                                                    if (coupon == "") {
                                                        Toast("Please enter coupon code", "error")
                                                    } else {
                                                        setOffer(offerSearchReducerData?.filter((item) => { return item?.offerCode == coupon }))
                                                        setOfferApplied(true);
                                                    }
                                                }}
                                            >
                                                {offerApplied ? 'Applied' : 'Apply'}
                                            </button>
                                        </div>
                                        {offer[0]?.offerValue ?
                                            <div className="flex justify-between items-center text-white text-[2rem]">
                                                <div className="">
                                                    Coupon Offer
                                                </div>
                                                <div className="">
                                                    - ₹{offer[0]?.offerValue}
                                                </div>
                                            </div>
                                            :
                                            null

                                        }
                                        <div className="flex justify-between items-center mt-[1.9rem] mb-[1.5rem]">
                                            <div className="flex justify-start items-center">
                                                <input className='mr-5' id='Fitcoins' type="checkbox" value={fitcoinCheckbox} onChange={() => { setFitCoinValue(); HandleTotalFreeFitcoin(); setFitcoinCheckbox(!fitcoinCheckbox) }} />
                                                <label
                                                    for="Fitcoins"
                                                    className='urbanist-medium text-[#FFF6F6] text-[1.6rem] cursor-pointer'
                                                >
                                                    Use your Fitcoins
                                                </label>
                                            </div>
                                            <div className={`urbanist-medium ${fitcoinCheckbox ? "text-[#FFF6F6]" : "text-[#FFF6F6]/40"} text-[1.6rem]`}>
                                                -{calculateFitcoinDiscount()}
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center mt-[1.9rem] mb-[1.5rem]">
                                            <div className="">Total</div>
                                            <div className="">₹{totalPriceAfterDiscountWithFitcoins}</div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div className="">Tax</div>
                                            <div className="">₹{(totalPriceAfterDiscountWithFitcoins * 18) / 100}</div>
                                        </div>
                                        <div className="border-t border-white my-[1.5rem]"></div>
                                        <div className="flex justify-between items-center">
                                            <div className="urbanist-semibold">Net Payable</div>
                                            <div className="flex justify-end items-center">
                                                {/* <div className="text-[#A1A1A1] line-through mr-[1.6rem]">₹{totalAmountWithoutDiscount} </div> */}
                                                <div className="">₹{totalPriceAfterDiscountWithFitcoins + ((totalPriceAfterDiscountWithFitcoins * 18) / 100)} </div>
                                            </div>
                                        </div>
                                        <button
                                            className="w-full flex justify-center items-center bg-[#FAFF00] mt-[4.3rem] urbanist-semibold text-black rounded-[1.2rem] py-[1.4rem]"
                                            onClick={() => {
                                                if (termsAndCondition == false) {
                                                    return
                                                } else {
                                                    dispatch(refreshToken({
                                                        refreshToken: utilReducers?.refreshToken,
                                                        accessToken: utilReducers?.accessToken
                                                    }))

                                                    dispatch(setUpgradeUnable(false));
                                                }
                                            }}
                                        >
                                            {!refreshTokenReducerLoading || !orderCreateReducerLoading || !makePaymentReducerLoading ?
                                                "Pay Now"
                                                :
                                                <div className="flex justify-center items-center">
                                                    <Oval
                                                        visible={true}
                                                        height="25"
                                                        width="25"
                                                        color="#000000"
                                                        ariaLabel="oval-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="w-full"
                                                    />
                                                </div>
                                            }
                                        </button>
                                        {!termsAndCondition ?
                                            <span className="text-[#ff0000] text-[1.4rem] absolute bottom-2 left-20">Please Check & Agree to All Terms and Conditions</span>
                                            :
                                            null
                                        }
                                    </div>
                            :
                            <div className="flex justify-center items-center w-full">
                                <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#000000"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>

                        }
                    </div>
                    <div className="flex justify-between items-center my-[2rem]">
                        <div className="urbanist-semibold text-[#FEFDFF] text-[2.4rem]">
                            Download the Fit.zone app
                        </div>
                        <div className="flex justify-between items-center">
                            <img src={DownloadAppLandingImg.appleIcon} alt="" className="w-[14.2rem] h-full mr-[2.6rem]" />
                            <img src={DownloadAppLandingImg.googleIcon} alt="" className="w-[14.2rem] h-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PayStepForm