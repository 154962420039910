import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { MdStarRate } from "react-icons/md";
import { VideoPlayer3Img } from '../../../static/constants/imgConstants';
import { searchByLocation } from '../../../store/actions/venueManagementServiceActions/searchByLocationActions';
import { useDispatch, useSelector } from 'react-redux';
import { getVenueDetails } from '../../../store/actions/venueManagementServiceActions/getVenueDetailsAction';
import { Oval } from 'react-loader-spinner';
import VideoPlayer3Slider from '../VideoPlayer3Slider/VideoPlayer3Slider';
import "swiper/css";
import 'swiper/element/css/pagination';
import { register } from 'swiper/element/bundle';

const VideoPlayer3 = ({ showFooter, showTagLine, gymId, isHome }) => {
    register();
    const dispatch = useDispatch()

    const [amenitiesCount, setAmenitiesCount] = useState(0);
    const [selectedGymTrainers, setSelectedGymTrainers] = useState("");
    const [selectedGymAmenities, setSelectedGymAmenities] = useState("");
    const [selectedGymSessions, setSelectedGymSessions] = useState("");

    const latLong = {
        lat: "12.887",
        long: "77.639"
    }

    const searchByLocationReducersData = useSelector((state) => state.searchByLocationReducers?.data);
    const searchByLocationReducersStatus = useSelector((state) => state.searchByLocationReducers?.status);
    const searchByLocationReducersLoading = useSelector((state) => state.searchByLocationReducers?.loading);
    const getVenueDetailsReducerData = useSelector((state) => state.getVenueDetailsReducer.data);
    const filehandlerReducerData = useSelector((state) => state.filehandlerReducer?.data);

    useEffect(() => {
        dispatch(searchByLocation({ lat: latLong.lat, long: latLong.long }));
        dispatch(getVenueDetails({ venueId: gymId ? gymId : '' }))
    }, [])


    useEffect(() => {
        if (searchByLocationReducersStatus === 200) {
            if (gymId) {
                setSelectedGymAmenities(searchByLocationReducersData?.venues?.filter((item) => item.amenities)?.length)
            } else {
                const totalAmenitiesCount = searchByLocationReducersData?.venues?.reduce((total, venue) => {
                    return total + (venue.amenities?.length || 0);
                }, 0);
                setAmenitiesCount(totalAmenitiesCount); // Update state with total count
            }
        }
    }, [searchByLocationReducersStatus]);


    useEffect(() => {
        if (searchByLocationReducersStatus === 200) {
            dispatch(getVenueDetails({ venueId: gymId ? gymId : '' }))
        }
    }, [gymId, searchByLocationReducersStatus])

    return (
        <div className={`bg-black sm:bg-[#1E1E1E] sm:pt-[5rem] ${isHome ? "" : "mt-[6rem]"} flex flex-col justify-center items-center`}>
            <div className="urbanist-bold text-[4.8rem] text-white my-[2rem]">
                {isHome ? searchByLocationReducersData?.venues?.length > 0 ? searchByLocationReducersData?.venues[0].name : "" : searchByLocationReducersData?.venues?.filter((item) => item.id == gymId)?.map((data) => { return data?.name })}
            </div>
            {/* {isHome ?
                <ReactPlayer
                    url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
                    light={<img src={'https://s3-alpha-sig.figma.com/img/f1c7/9ee3/8895d8a5c4870b25c426c2aff51ee58f?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=f5ZchouQQ0eeaUlpRothMBSRCQbpRisCmHH~2GoPElrjVsG7IDRkEgO4FOS8c5ggY9gNmAIXzLYkSZ9mXFh9YI9U1n~Aecr22HTfkKRj7JOGgGp5d-b~PPw70lO6V2me3Obti5QMFffG-Sx-1jdsBf2ES25mkosggPZnnz25fyhtDrQwJLnJAPDqE3faY~ShhTuG-Mw-xQMOEzp9Br1SyM~8BzKhajookjIK0t-PWp8Bk8ftWJvGb~bItmL9coljA7X5qlfpzgcS4ikXFzpXst2O5Txz4rfWWNSSR-xA8ZX72-AOdZC1Ngjm9wP75zqEr-AwYxGrXM8z~T--sZG84A__'} alt='Thumbnail' className='w-full sm:w-[144rem] xl:w-[150rem] 2xl:w-[165rem] h-[40rem]' />}
                    controls
                    width={"90%"}
                    playing={true}
                    height={400}
                />
                : */}
            <div className="flex justify-center items-center w-full">
                <swiper-container
                    pagination="true"
                    keyboard="true"
                // autoplay-delay="5000"
                >
                    {filehandlerReducerData?.length > 0 ?
                        filehandlerReducerData?.map((items, index) => {
                            return (
                                <swiper-slide key={index}>
                                    {items?.description == "Video OverView" ?
                                        <div className="flex justify-center items-center">
                                            <ReactPlayer
                                                url={items?.publicURL}
                                                // light={<img src={items?.thumbnailURL} alt='Thumbnail' className='w-full sm:w-[144rem] xl:w-[150rem] 2xl:w-[165rem] h-[40rem]' />}
                                                controls
                                                width={1000}
                                                playing={true}
                                                height={400}
                                            />
                                        </div>
                                        :
                                        <VideoPlayer3Slider image={items?.publicURL} />
                                    }
                                    {/* <div className="flex justify-center items-center">
                                            <img src={items?.publicURL} alt="" className="w-[100rem] h-[50rem]" />
                                        </div> */}
                                </swiper-slide>
                            )
                        })
                        :
                        <div className="text-white text-center text-[1.6rem]">
                            no images available
                        </div>
                    }
                </swiper-container>
            </div>
            {/* } */}

            <div className="px-[4rem] py-[2rem] w-full">
                {searchByLocationReducersLoading ?
                    <div className="flex justify-center items-center w-full">
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#000000"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                    :
                    <div className="container-lg">
                        {showFooter &&
                            <Fragment>
                                {showTagLine &&
                                    <div className="text-white text-[4.8rem] montserrat-semibold text-center my-[2rem]">
                                        Best Centres around you
                                    </div>
                                }
                                <div className={`w-full flex flex-col sm:flex-row items-center ${isHome ? "justify-around" : "justify-center gap-[5rem]"}`}>
                                    {getVenueDetailsReducerData && getVenueDetailsReducerData?.TotalStaff != 0 ?
                                        <div className="flex flex-col justify-center items-start text-[#29CC6A] w-[20rem] lg:w-relative">
                                            <div className="urbanist-semibold text-[6.4rem] leading-[6.4rem] overflow-hidden">{getVenueDetailsReducerData ? getVenueDetailsReducerData?.TotalStaff : "10"}+</div>
                                            <div className="urbanist-medium text-[4rem]">Trainers</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {getVenueDetailsReducerData && getVenueDetailsReducerData?.TotalAmenities != 0 ?
                                        <div className="flex flex-col justify-center items-start text-[#FC5555] w-[20rem] lg:w-relative my-[10rem] sm:my-0">
                                            <div className="urbanist-semibold text-[6.4rem] leading-[6.4rem] overflow-hidden">{getVenueDetailsReducerData ? getVenueDetailsReducerData?.TotalAmenities : "10"}+</div>
                                            <div className="urbanist-medium text-[4rem]">Amenities</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {getVenueDetailsReducerData && getVenueDetailsReducerData?.TotalSessions != 0 ?
                                        <div className="flex flex-col justify-center items-start text-[#0099FF] w-[20rem] lg:w-relative">
                                            <div className="urbanist-semibold text-[6.4rem] leading-[6.4rem] overflow-hidden">{getVenueDetailsReducerData ? getVenueDetailsReducerData?.TotalSessions : "10"}+</div>
                                            <div className="urbanist-medium text-[4rem]">Sessions</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {getVenueDetailsReducerData && getVenueDetailsReducerData?.VenueRating ?
                                        <div className="flex flex-col justify-center items-start text-[#F9D774] w-[20rem] lg:w-relative mt-[10rem] sm:mt-0">
                                            <div className="urbanist-semibold text-[6.4rem] leading-[6.4rem] overflow-hidden flex justify-between items-center">
                                                {getVenueDetailsReducerData ?
                                                    getVenueDetailsReducerData?.VenueRating
                                                    :
                                                    "4.3"
                                                }
                                                <MdStarRate size={40} color='#F9D774' className='ml-5' />
                                            </div>
                                            <div className="urbanist-medium text-[4rem]">Rating</div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                }
            </div>
        </div >
    )
}

export default VideoPlayer3