import { actionTypes } from '../../types';

export const OrderCreate = (payload) => ({
    type: actionTypes.ORDER_CREATE_REQUEST,
    payload,
});

export const OrderCreateSuccess = (payload) => ({
    type: actionTypes.ORDER_CREATE_SUCCESS,
    payload,
});

export const OrderCreateFailure = (payload) => ({
    type: actionTypes.ORDER_CREATE_FAILURE,
    payload,
});

export const OrderCreateReset = () => ({
    type: actionTypes.ORDER_CREATE_RESET,
});

export const OrderCreateResetAll = () => ({
    type: actionTypes.ORDER_CREATE_RESET_ALL,
}); 