import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosCMSService, AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { StrapifaqsFailure, StrapifaqsSuccess } from '../../actions/StrapiServiceActions/StrapifaqsAction';

function* Strapifaqs({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.faqs + `?category.slug=${payload.category}`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_FAQS_SUCCESS response :", response);
        console.log("STRAPI_FAQS_SUCCESS status :", response?.status);
        console.log("STRAPI_FAQS_SUCCESS data :", response?.data);
        console.log("STRAPI_FAQS_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            StrapifaqsSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_FAQS_FAILURE status :", error?.response?.status);
        console.log("STRAPI_FAQS_FAILURE data:", error?.response?.data);
        console.log("STRAPI_FAQS_FAILURE error :", error);
        console.log('====================================');
        yield put(StrapifaqsFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* StrapifaqsSaga() {
    yield takeLatest(actionTypes.STRAPI_FAQS_REQUEST, Strapifaqs);
}

export default StrapifaqsSaga;