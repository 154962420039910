import React from 'react'
import { DownloadAppImg } from '../../../static/constants/imgConstants';

const BuyNowPopup = ({ show, onClose, setBuyNowPopup }) => {

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setBuyNowPopup(false);
        }
    };

    return show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20" onClick={(e) => handleOutsideClick(e)}>
            <div className="bg-[#343434] rounded-[3.5rem] text-white w-[72rem] h-[45.5rem] items-center px-[6rem]">
                <h2 className="text-start sm:text-center urbanist-bold text-[3.6rem] my-[4rem]">To avail this feature, you need to download the App</h2>
                <div className='flex justify-between items-center overflow-hidden'>
                    <div className='w-[40%] cursor-pointer hover:scale-95 bg-[#030606] rounded-[1rem]'>
                        <a href="https://www.apple.com/in/app-store/" target="_blank" className="">
                            <img className='' src={DownloadAppImg.appleIcon} alt='app-store' />
                        </a>
                    </div>
                    <div className='w-[48%] cursor-pointer hover:scale-95'>
                        <a href="https://play.google.com/store/apps" target="_blank" className="">
                            <img className='' src={DownloadAppImg.googleIcon} alt='play-store' />
                        </a>
                    </div>
                </div>
                <div className="flex justify-center items-center mt-[3rem]">
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BuyNowPopup
