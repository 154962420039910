import { actionTypes } from '../../types';

export const getTicketByUserId = (payload) => ({
    type: actionTypes.GET_TICKET_BY_USER_ID_REQUEST,
    payload,
});

export const getTicketByUserIdSuccess = (payload) => ({
    type: actionTypes.GET_TICKET_BY_USER_ID_SUCCESS,
    payload,
});

export const getTicketByUserIdFailure = (payload) => ({
    type: actionTypes.GET_TICKET_BY_USER_ID_FAILURE,
    payload,
});

export const getTicketByUserIdReset = () => ({
    type: actionTypes.GET_TICKET_BY_USER_ID_RESET,
});

export const getTicketByUserIdResetAll = () => ({
    type: actionTypes.GET_TICKET_BY_USER_ID_RESET_ALL,
}); 