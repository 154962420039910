import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_venue_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { getVenueDetailsFailure, getVenueDetailsSuccess } from '../../actions/venueManagementServiceActions/getVenueDetailsAction';
// import store from '../../configStore';

function* getVenueDetails({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_venue_management_service,
            endPoint: endpoints.getVenueDetails + `?venueid=${payload.venueId}`,
            isAuth: false,
        });

        console.log('====================================');
        console.log("GET_VENUE_DETAILS_SUCCESS status :", response.status);
        console.log("GET_VENUE_DETAILS_SUCCESS data :", response.data);
        console.log("GET_VENUE_DETAILS_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            getVenueDetailsSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_VENUE_DETAILS_FAILURE status :", error?.response?.status);
        console.log("GET_VENUE_DETAILS_FAILURE data:", error?.response?.data);
        console.log("GET_VENUE_DETAILS_FAILURE error :", error);
        console.log('====================================');
        yield put(getVenueDetailsFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* getVenueDetailsSaga() {
    yield takeLatest(actionTypes.GET_VENUE_DETAILS_REQUEST, getVenueDetails);
}

export default getVenueDetailsSaga;