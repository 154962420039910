import React, { useEffect } from 'react'
import OurStoryLanding from '../../components/common/OurStoryLanding/OurStoryLanding'
import VideoPlayer3 from '../../components/common/VideoPlayer3/VideoPlayer3'
import FitTeam from '../../components/common/FitTeam/FitTeam'
import FounderCard from '../../components/common/FounderCard/FounderCard'
import LocationMap2 from '../../components/common/LocationMap2/LocationMap2'
import PressAndMediaBanner from '../../components/common/PressAndMediaBanner/PressAndMediaBanner'
import Footer3 from '../../components/common/Footer3/Footer3'
import { useDispatch } from 'react-redux'
import { strapiPressAndMedia } from '../../store/actions/StrapiServiceActions/strapiPressAndMediaAction'
import { strapiOurFitTeam } from '../../store/actions/StrapiServiceActions/strapiOurFitTeamAction'
import { strapiFounder } from '../../store/actions/StrapiServiceActions/strapiFounderAction'

function OurStory() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(strapiOurFitTeam())
        dispatch(strapiFounder())
        dispatch(strapiPressAndMedia())
    }, [])

    return (
        <div className='bg-[#1E1E1E]'>
            <OurStoryLanding />
            <VideoPlayer3 showFooter={false} showTagLine />
            <FitTeam />
            <FounderCard />
            <LocationMap2 />
            <PressAndMediaBanner />
            <Footer3 />
        </div>
    )
}

export default OurStory