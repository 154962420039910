import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const referFriend = (payload) => ({
    type: actionTypes.REFER_FRIEND_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const referFriendSuccess = (payload) => ({
    type: actionTypes.REFER_FRIEND_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const referFriendFailure = (payload) => ({
    type: actionTypes.REFER_FRIEND_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const referFriendReset = () => ({
    type: actionTypes.REFER_FRIEND_RESET,
});

// Action creator for resetting all OTP generation reducer
export const referFriendResetAll = () => ({
    type: actionTypes.REFER_FRIEND_RESET_ALL,
}); 