import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { subscriptionOffersFailure, subscriptionOffersSuccess } from '../../actions/SubscriptionManagementActions/subscriptionOffersAction';

function* subscriptionOffers({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.subscriptionOffers,
            isAuth: true,
        });

        console.log('====================================');
        console.log("SUBSCRIPTION_OFFERS_SUCCESS status :", response.status);
        console.log("SUBSCRIPTION_OFFERS_SUCCESS data :", response.data);
        console.log("SUBSCRIPTION_OFFERS_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            subscriptionOffersSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("SUBSCRIPTION_OFFERS_FAILURE status :", error?.response?.status);
        console.log("SUBSCRIPTION_OFFERS_FAILURE data:", error?.response?.data);
        console.log("SUBSCRIPTION_OFFERS_FAILURE error :", error);
        console.log('====================================');
        yield put(subscriptionOffersFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* subscriptionOffersSaga() {
    yield takeLatest(actionTypes.SUBSCRIPTION_OFFERS_REQUEST, subscriptionOffers);
}

export default subscriptionOffersSaga;