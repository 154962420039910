import React, { Fragment, useEffect, useRef, useState } from 'react'
import { NavbarImg } from '../../../static/constants/imgConstants'
import { motion } from "framer-motion"
import { useLocation, useNavigate } from 'react-router-dom';
import { navData } from './navData';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/actions/utilsActions/utilsActions';
import { customerProfile, customerProfileReset } from '../../../store/actions/CustomerManagementServiceActions/customerProfileAction';
import "./Navbar.css"

function Navbar({ show }) {
    const Navigate = useNavigate();
    const dispatch = useDispatch()
    const scrolled = useRef(false); // Use ref to persist the scrolled value between renders
    const location = useLocation()

    const utilReducers = useSelector((state) => state.utilReducers);
    const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);
    const CustomerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);

    const [activeMiniNavbar, setActiveMiniNavbar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [setProfilePicUrl, setSetProfilePicUrl] = useState("");
    const profileDropdownRef = useRef(null);  // Add a ref for the profile dropdown

    useEffect(() => {
        const navbar = document.getElementById('navbar');
        if (!navbar) {
            console.error('Navbar element not found');
            return;
        }

        const handleScroll = () => {
            if (window.pageYOffset > 10) {
                navbar.classList.add('navbar-bg');
                if (!scrolled.current) {
                    navbar.style.transform = 'translateY(-10rem)';
                }
                setTimeout(() => {
                    navbar.style.transform = 'translateY(0)';
                    scrolled.current = true;
                }, 200);
            } else {
                scrolled.current = false;
                navbar.classList.remove('navbar-bg');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [document.getElementById('navbar')]); // Only run on mount and unmount

    useEffect(() => {
        const navbar = document.getElementById('navbar');
        if (!navbar) {
            console.error('Navbar element not found');
            return;
        }

        if (activeMiniNavbar) {
            navbar.classList.add('navbar-bg');
        } else {
            setTimeout(() => {
                navbar.classList.remove('navbar-bg');
            }, 500);
        }
    }, [activeMiniNavbar]); // This effect depends on activeMiniNavbar

    useEffect(() => {
        dispatch(customerProfile(utilReducers?.userId))
    }, [])

    useEffect(() => {
        if (CustomerProfileReducerStatus == 200) {
            CustomerProfileReducerData.map((items) => {
                setSetProfilePicUrl(items.profilePicURL);
            })
            dispatch(customerProfileReset());
        } else if (CustomerProfileReducerStatus) {
            dispatch(customerProfileReset());
        }
    }, [CustomerProfileReducerStatus])

    // Add the effect for detecting outside clicks
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
                setProfileDropdown(false);
            }
        };

        // Add event listener when dropdown is open
        if (profileDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            // Cleanup the event listener when dropdown is closed
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profileDropdown]);

    const NavItem = ({ id, title, path, isOpenInNewTab }) => {
        return (
            <div
                key={id}
                className={`text-[2.2rem] urbanist-regular ${location.pathname == path || (location.pathname == "/fit-center/fit-champ" && title == "Fit Centers") || (location.pathname == "/community/blogs" && title == "Community") ? "text-[#FAFF00] hover:text-[#FAFF00] focus:text-[#FAFF00]" : "text-white"} cursor-pointer hidden lg:block`}
                onClick={() => {
                    if (!isOpenInNewTab) {
                        Navigate(path)
                    } else {
                        window.open(path, '_blank', 'rel=noopener noreferrer')
                    }
                }
                }
            >
                {title}
            </div>
        )
    }

    const MiniNavItem = ({ id, title, path, isOpenInNewTab }) => {
        return (
            <div
                key={id}
                className="text-[2.2rem] font-[500] text-white hover:text-black focus:text-black p-6 text-center hover:bg-[#FAFF00] focus:bg-[#FAFF00] w-full overflow-hidden"
                onClick={() => {
                    if (!isOpenInNewTab) {
                        Navigate(path);
                        setActiveMiniNavbar(false)
                    } else {
                        window.open(path, '_blank', 'rel=noopener noreferrer')
                        setActiveMiniNavbar(false)
                    }
                }}
            >
                {title}
            </div>
        )
    }

    console.log("location :", location.pathname == "/help");
    console.log("utilReducer :", utilReducers);

    console.log("profileDropdown :", profileDropdown);
    console.log("profileDropdown show :", show);

    return show && (
        <Fragment>
            <div id="navbar" className={`${location.pathname == "/help" ? "bg-[#000000] mr-[1rem]" : "bg-transparent"} px-[4rem] fixed top-0 left-0 right-0 z-[99] overflow-hidden`}>
                <div className='container-lg py-[2rem]'>
                    <div className="flex justify-between items-center">
                        <div className="ml-[.8rem] lg:hidden" onClick={() => setActiveMiniNavbar(!activeMiniNavbar)}>
                            <motion.div
                                animate={{ rotate: activeMiniNavbar ? 45 : 0, y: activeMiniNavbar ? 6.8 : 0 }}
                                transition={{ duration: .5 }}
                                className='w-[30px] h-[3px] bg-[#fff] my-[4px] rounded'
                            ></motion.div>
                            <motion.div
                                animate={{ x: activeMiniNavbar ? -100 : 0 }}
                                transition={{ duration: .5 }}
                                className='w-[30px] h-[3px] bg-[#fff] my-[4px] rounded'
                            ></motion.div>
                            <motion.div
                                animate={{ rotate: activeMiniNavbar ? -45 : 0, y: activeMiniNavbar ? -6.8 : 0 }}
                                transition={{ duration: .5 }}
                                className='w-[30px] h-[3px] bg-[#fff] my-[4px] rounded'
                            ></motion.div>
                        </div>
                        <div className="w-[22rem] h-[2.7rem] lg:w-[30rem] lg:h-[3.7rem] cursor-pointer" onClick={() => Navigate("/")}>
                            <img src={NavbarImg.LogoLight} className='w-full h-full' />
                        </div>
                        {navData?.map((item) => NavItem(item))}
                        {utilReducers?.accessToken?.length > 0 ?
                            <div className="hidden lg:block">
                                <div
                                    className="bg-[#616161D4]/80 rounded-full"
                                    onClick={() => setProfileDropdown(!profileDropdown)}
                                    ref={profileDropdownRef} // Add ref to the profile image div
                                >
                                    <img src={setProfilePicUrl ? setProfilePicUrl : NavbarImg.Exercise} alt="" className="w-[4rem] h-[4rem]" />
                                </div>
                            </div>

                            :
                            <div
                                className="text-[2.2rem] font-[500] text-white focus:text-black text-center hover:text-[#FAFF00] focus:text-[#FAFF00] overflow-hidden hidden lg:block cursor-pointer"
                                onClick={() => Navigate("/login")}
                            >
                                Login
                            </div>
                        }
                        <div className="mr-[.8rem] lg:hidden w-[3rem]"></div>
                    </div>
                    <motion.div
                        className="flex flex-col justify-center items-center lg:hidden"
                        initial={{ height: 0 }}
                        animate={{ height: activeMiniNavbar ? 'max-content' : 0 }}
                        transition={{ type: 'tween',duration: 0.5, ease: "easeIn", }}
                        style={{ overflow: 'hidden' }}
                    >
                        {navData?.map((item) => MiniNavItem(item))}
                        {utilReducers?.accessToken?.length > 0 ?
                            <div className="overflow-hidden">
                                <div className="p-2 bg-[#616161D4]/80 rounded-full my-5" onClick={() => Navigate("/profile")}>
                                    <img src={setProfilePicUrl ? setProfilePicUrl : NavbarImg.Exercise} alt="" className="w-[2.8rem] h-[2.8rem]" />
                                </div>
                            </div>

                            :
                            <div className="overflow-hidden">
                                <div
                                    className="text-[2.2rem] font-[500] text-white focus:text-black text-center hover:text-[#FAFF00] focus:text-[#FAFF00] overflow-hidden my-5"
                                    onClick={() => { setActiveMiniNavbar(false); Navigate("login")}}
                                >
                                    Login
                                </div>
                            </div>
                        }
                        {utilReducers?.accessToken?.length > 0 ?
                            <div
                                className="text-[2.2rem] font-[500] text-white focus:text-black text-center hover:text-[#FAFF00] focus:text-[#FAFF00] overflow-hidden"
                                onClick={() => { setActiveMiniNavbar(false); dispatch(logout()); Navigate("/"); }}
                            >
                                Log Out
                            </div>
                            :
                            null
                        }
                    </motion.div>
                </div>
            </div>
            {profileDropdown ? (
                <div className="fixed z-[100] top-[9rem] right-[10rem]" ref={profileDropdownRef}>
                    <div className="bg-black w-[25rem] h-full p-5 rounded-[2rem]">
                        <div
                            className="text-[2.2rem] font-[500] text-white focus:text-black text-center hover:text-[#FAFF00] focus:text-[#FAFF00] cursor-pointer"
                            onClick={() => { setProfileDropdown(false); Navigate("/profile") }}
                        >
                            {
                                CustomerProfileReducerData[0]?.firstName ?
                                    CustomerProfileReducerData[0]?.firstName + " " + CustomerProfileReducerData[0].lastName
                                    :
                                    CustomerProfileReducerData[0]?.email ?
                                        CustomerProfileReducerData[0]?.email
                                        :
                                        "Profile"
                            }
                        </div>
                        <div
                            className="text-[2.2rem] font-[500] text-white focus:text-black text-center hover:text-[#FAFF00] focus:text-[#FAFF00] cursor-pointer"
                            onClick={() => {
                                setProfileDropdown(false);
                                dispatch(logout());
                                Navigate("/")
                            }}
                        >
                            Logout
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    )
}

export default Navbar;
