import React from 'react';
import { OurStoryPageImg } from '../../../static/constants/imgConstants';
import './LocationMap2.css'

function LocationMap2() {
    return (
        <div className="bg-location-map h-[98rem] md:h-[84rem] lg:h-[96rem] px-[4rem]">
            <div className="container-lg flex flex-col md:flex-row justify-around md:justify-between items-center my-auto h-full">
                <div className="w-full md:w-[57%] flex justify-center md:justify-start">
                    <img src={OurStoryPageImg.MAPLogo} alt="logo" className="w-full h-full" />
                </div>
                <div className="w-full md:w-[35%] lg:w-[25%] flex flex-col items-center md:items-start text-white urbanist-medium">
                    <h2 className="font-bold text-[9.6rem] text-center md:text-left leading-[11.6rem]">
                        50 + gyms
                    </h2>
                    <p className="font-normal text-[4.8rem] text-gray-400 text-center md:text-left leading-[6rem]">
                        Become part of the fitness revolution born in india
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LocationMap2;
