import React from 'react'
import "./PressAndMediaBanner.css"
import { base_url_strapi_service } from '../../../store/constants'
import moment from 'moment'

const PressAndMediaBannerCard = ({ content, image, date }) => {
    return (
        <div className='flex flex-col justify-between items-start w-full my-[3.2rem] md:my-0 md:w-[32%]'>
            <div className='flex flex-col justify-between items-start'>
                <div className="text-[4rem] press-and-media-banner-card-content" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
            <div className="text-[1.8rem] urbanist-regular">
                {moment(date).format("DD-MM-YYYY")}
            </div>
            <div className='h-[2.8rem] my-[3.2rem] md:my-[7.6rem]'>
                <img src={`${base_url_strapi_service}${image}`} alt='logo' className='w-full h-full' />
            </div>
        </div>
    )
}

export default PressAndMediaBannerCard