import { actionTypes } from '../../types';

export const filehandler = (payload) => ({
    type: actionTypes.FILEHNADLER_REQUEST,
    payload,
});

export const filehandlerSuccess = (payload) => ({
    type: actionTypes.FILEHNADLER_SUCCESS,
    payload,
});

export const filehandlerFailure = (payload) => ({
    type: actionTypes.FILEHNADLER_FAILURE,
    payload,
});

export const filehandlerReset = () => ({
    type: actionTypes.FILEHNADLER_RESET,
});

export const filehandlerResetAll = () => ({
    type: actionTypes.FILEHNADLER_RESET_ALL,
}); 