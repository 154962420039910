import { actionTypes } from '../../types';

export const getLeaderBoardFile = (payload) => ({
    type: actionTypes.GET_LEADER_BOARD_FILE_REQUEST,
    payload,
});

export const getLeaderBoardFileSuccess = (payload) => ({
    type: actionTypes.GET_LEADER_BOARD_FILE_SUCCESS,
    payload,
});

export const getLeaderBoardFileFailure = (payload) => ({
    type: actionTypes.GET_LEADER_BOARD_FILE_FAILURE,
    payload,
});

export const getLeaderBoardFileReset = () => ({
    type: actionTypes.GET_LEADER_BOARD_FILE_RESET,
});

export const getLeaderBoardFileResetAll = () => ({
    type: actionTypes.GET_LEADER_BOARD_FILE_RESET_ALL,
}); 