import React, { useEffect, useState } from 'react'
import { CiFacebook } from "react-icons/ci";
import { PiInstagramLogoLight, PiLinkedinLogoLight } from "react-icons/pi";
import { RxTwitterLogo } from "react-icons/rx";
import { DownloadAppImg, NavbarImg } from "../../../static/constants/imgConstants";
import { useDispatch, useSelector } from 'react-redux';
import { subscribe, subscribeReset } from '../../../store/actions/leadgenServiceActions/subscribeAction';
import { useToast } from '../../../hooks/useToast';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

function Footer3() {

    const dispatch = useDispatch();
    const Toast = useToast()
    const navigate = useNavigate()

    const [mail, setMail] = useState("")
    const [emailError, setEmailError] = useState("");

    const subscribeReducerStatus = useSelector((state) => state.subscribeReducer?.status);
    const subscribeReducerLoading = useSelector((state) => state.subscribeReducer?.loading);
    const subscribeReducerData = useSelector((state) => state.subscribeReducer?.data);

    const handleSubscribe = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(mail)) {
            setEmailError(""); // Clear error if valid
            dispatch(subscribe({ "email": mail }));
        } else {
            setEmailError("Please enter a valid email"); // Set error message if invalid
        }
    };

    useEffect(() => {
        if (subscribeReducerStatus == 200) {
            Toast(subscribeReducerData?.statusmessage);
            setMail("")
            dispatch(subscribeReset());
        } else if (subscribeReducerStatus) {
            Toast("email not sent try again later");
            setMail("")
            dispatch(subscribeReset());
        }
    }, [subscribeReducerStatus])


    return (
        <div className='bg-transparent text-white select-none px-[4rem]'>
            <div className='container-lg my-[9rem]'>
                <div className='flex md:hidden w-[33rem] cursor-pointer mb-[5.4rem]'>
                    <img className='w-full h-full' src={NavbarImg.LogoLight} alt='logo' />
                </div>
                <h2 className='text-[6.4rem] open-sans-bold leading-[7.5rem] mb-[1.2rem] overflow-hidden'>
                    Commit to fitness today
                </h2>
                <div className='flex justify-between items-end'>
                    <div className='flex flex-col justify-end items-start w-[100%] sm:w-[88%] md:w-[55%] lg:w-[32%]'>
                        <p className='text-[1.6rem] open-sans-regular pr-[2rem]'>
                            Stay ahead of the Fitness curve, Get latest updates and offers through our Newsletter
                        </p>
                        <div className='flex md:hidden flex-col justify-end items-start my-[4rem]'>
                            <h5 className='text-[2.4rem] open-sans-semibold'>
                                Important Links
                            </h5>
                            <div className='flex flex-col justify-end items-start mt-[1.8rem]'>
                                <div className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' onClick={() => navigate("/our-story")}>About
                                    Us</div>
                                <div className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]'
                                    onClick={() => navigate("/fit-center/fit-prime")}>Centers Near You</div>
                                <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/terms">Terms &
                                    Conditions</a>
                                <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/privacy">Privacy
                                    Policy</a>
                                <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/refund">Refund
                                    Policy</a>
                                <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://dev-partnerportal.fit.zone/#/login">Partner.fit.zone</a>
                                <div className="decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]" onClick={() => navigate("/help")}>
                                    Fitzone Support
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-start my-[.8rem] md:my-[3.2rem] flex-wrap'>
                            <input
                                className='bg-[#000] w-[30rem] h-[5rem] p-[0.9rem] border-[.05rem] border-[#fff] rounded-[.8rem] text-[2rem] text-[#fff] mr-[1rem] mb-[2rem] outline-none'
                                type='email'
                                placeholder='Enter your email'
                                onChange={(e) => setMail(e.target.value)}
                                value={mail}
                            />
                            <button
                                className='bg-[#FAFF00] text-[#486077] text-[1.7rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'
                                onClick={() => {
                                    handleSubscribe()
                                }
                                }
                            >
                                {subscribeReducerLoading ?
                                    <Oval
                                        visible={true}
                                        height="25"
                                        width="25"
                                        color="#000000"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                    :
                                    "Subscribe"
                                }
                            </button>
                            {emailError && (
                                <p className='text-red-500 text-[1.4rem] mt-[-1rem] mb-[1rem]'>
                                    {emailError}
                                </p>
                            )}
                        </div>
                        <div className='flex flex-col md:hidden justify-center items-center overflow-hidden mb-[2rem] mt-[1.5rem] sm:mt-0'>
                            <div className='w-[15.1rem] cursor-pointer hover:scale-95 mr-[1.2rem] bg-[#000]'>
                                <img className='' src={DownloadAppImg.appleIcon} alt='app-store' />
                            </div>
                            <div className='w-[17.5rem] cursor-pointer hover:scale-95'>
                                <img className='' src={DownloadAppImg.googleIcon} alt='play-store' />
                            </div>
                        </div>
                        <div className='flex justify-start items-center'>
                            <a href='https://www.facebook.com/people/Fitzone/61561375006743/' target='_blank' className="">
                                <CiFacebook className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                            <a href="https://www.instagram.com/fitzone.life/" target='_blank' className="">
                                <PiInstagramLogoLight className='mr-[2rem]' size={25} color={'#fff'} />

                            </a>
                            <a href="https://www.linkedin.com/company/fitzone-life/about/?viewAsMember=true" target='_blank' className="">
                                <PiLinkedinLogoLight className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                            <a href="https://x.com/fitzone_life/" target='_blank' className="">
                                <RxTwitterLogo className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                        </div>
                    </div>
                    <div className='hidden md:flex flex-col justify-end items-start'>
                        <h5 className='text-[2.4rem] open-sans-semibold'>
                            Important Links
                        </h5>
                        <div className='flex flex-col justify-end items-start mt-[1.8rem]'>
                            <div className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' style={{ cursor: "pointer" }} onClick={() => navigate("/our-story")}>About
                                Us</div>
                            <div className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]'
                                onClick={() => navigate("/fit-center/fit-prime")} style={{ cursor: "pointer" }}>Centers Near You</div>
                            <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/terms">Terms &
                                Conditions</a>
                            <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/privacy">Privacy
                                Policy</a>
                            <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://fit.zone/#/static/refund">Refund
                                Policy</a>
                            <a className='decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]' target='_blank' href="https://dev-partnerportal.fit.zone/#/login">Partner.fit.zone
                            </a>
                            <div className="decoration-0 text-[1.6rem] open-sans-regular mb-[.4rem]" style={{ cursor: "pointer" }} onClick={() => navigate("/help")}>
                                Fitzone Support
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-end items-start w-[33rem] hidden md:flex'>
                        <div className='cursor-pointer'>
                            <img className='w-[25rem] h-[3.5rem]' src={NavbarImg.LogoLight} alt='logo' />
                        </div>
                        <div className='flex justify-between items-center mt-[4rem] overflow-hidden'>
                            <div className='w-[45%] cursor-pointer hover:scale-95 bg-[#030606] rounded-[1rem]'>
                                <a href="https://www.apple.com/in/app-store/" target="_blank" className="">
                                    <img className='' src={DownloadAppImg.appleIcon} alt='app-store' />
                                </a>
                            </div>
                            <div className='w-[51%] cursor-pointer hover:scale-95'>
                                <a href="https://play.google.com/store/apps" target="_blank" className="">
                                    <img className='' src={DownloadAppImg.googleIcon} alt='play-store' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[120%] h-[1px] bg-[#fff] -ml-[10%]' />
            <p className='container-lg text-[1.5rem] text-center md:text-right my-[4.5rem]'>@ &nbsp; copyright Fit.zone | Created by Infominez</p>
        </div>
    );
}

export default Footer3;
