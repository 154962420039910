import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import CountdownTimer from '../../utils/CountdownTimer/CountdownTimer';
import { validateOtp } from '../../../store/actions/authActions/validateOtpActions';
import { useDispatch, useSelector } from 'react-redux';

const EnterOtpPopup = ({ show, onClose, resendOtp, setHandleOutsideClick }) => {
    const dispatch = useDispatch();
    const utilReducers = useSelector((state) => state.utilReducers);

    const [otp, setOtp] = useState('');

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setHandleOutsideClick(true);
        }
    };

    return show &&(
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10" onClick={(e) => handleOutsideClick(e)}>
            <div className="items-center bg-[#343434] rounded-[3.5rem] text-white w-[72rem] h-[45.5rem] px-[6rem]">
                <h2 className="text-start sm:text-center urbanist-bold text-[3.6rem] my-[4.8rem]">Log in to your account</h2>
                <div className="flex flex-col justify-start items-start mx-auto mt-[5rem] mb-[3rem] w-[35rem]">
                    <label for="otp-input" className='text-white urbanist-semibold text-[2.4rem] mb-[1.3rem]'>Enter OTP</label>
                    <div className="w-full">
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            id='otp-input'
                            numInputs={4}
                            renderSeparator={<span></span>}
                            inputStyle={{
                                borderRadius: "1rem",
                                borderWidth: '1px',
                                borderColor: "white",
                                backgroundColor: "#343434",
                                margin: "0 5% 0 0",
                                padding: "1.5rem",
                                width: "22%",
                                fontFamily: "urbanist",
                                fontWeight: 600,
                                fontSize: "2.6rem",
                                outline: "none",
                                color: "white"
                            }}
                            shouldAutoFocus={true}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                </div>
                <CountdownTimer initialTime={59} resendOtp={() => {resendOtp(); setOtp();}}/>
                <div className="flex justify-center items-center">
                    <button
                        onClick={() => {dispatch(validateOtp({"userId": utilReducers?.userId, "otp": otp}));onClose()}}
                        disabled={otp?.length != 4}
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EnterOtpPopup