import React from "react";
import "./OurStoryLanding.css"

function OurStoryLanding() {
    return (
        <div className="w-full our-story-landing-bg h-[98rem] px-[4rem]">
            <div className="container-lg h-full">
                <div className="flex flex-col justify-center items-start text-white h-full w-full sm:w-[70%] lg:w-[60%] h-[80%]">
                    <h1 className="text-[9.6rem] text-white urbanist-semibold overflow-hidden">
                        <span className="text-[#FFF200]">How </span> We Started
                    </h1>
                    <div className="">
                        <p className="text-[1.8rem] mb-[2rem] urbanist-regular text-justify overflow-hidden my-[3.9rem]">
                            In the vibrant landscape of India, fitness enthusiasts saw a fantastic opportunity: many people were eager to embrace an active lifestyle. In 2024, we joyfully launched Fit.Zone to turn fitness into a delightful experience. Our first location offers a diverse range of exciting workouts, from energizing cardio to traditional strength training, all within a friendly and supportive community. Fit.Zone is more than just a gym; it’s a dynamic hub where fitness is celebrated as a joyful lifestyle. With innovative classes and a strong commitment to affordability, we inspire everyone to enjoy the benefits of fitness and embrace a healthier, happier life.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurStoryLanding;
