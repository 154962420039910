import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, GOOGLE_API_SERVICE_URL } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { GeocodeFailure, GeocodeSuccess } from '../../actions/GoogleApiActions/GeocodeActions';

function* Geocode({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: GOOGLE_API_SERVICE_URL,
            endPoint: endpoints.Geocode + `?address=${payload.zipCode}&sensor=true&key=AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U`,
            isAuth: false,
        });

        console.log('====================================');
        console.log("GEOCODE_SUCCESS status :", response.status);
        console.log("GEOCODE_SUCCESS data :", response.data.results);
        console.log("GEOCODE_SUCCESS message :", response.data.status);
        console.log('====================================');
        yield put(
            GeocodeSuccess({
                status: response?.status,
                data: response?.data.results,
                message: response.data.status
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GEOCODE_FAILURE status :", error?.response?.status);
        console.log("GEOCODE_FAILURE data:", error?.response?.data);
        console.log("GEOCODE_FAILURE error :", error);
        console.log('====================================');
        yield put(GeocodeFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* geocodeSaga() {
    yield takeLatest(actionTypes.GEOCODE_REQUEST, Geocode);
}

export default geocodeSaga;