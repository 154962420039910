import React from 'react'
import Slider from "react-slick";
import CheckoutCategoriesCard from '../CheckoutCategories/CheckoutCategoryCard';
import { CommunityImg } from "../../../static/constants/imgConstants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';

function ExploreCategories({ data, selectedCategory }) {

    const navigate = useNavigate();


    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    };

    console.log("ExploreCategories data :", data);


    return (
        <div className="bg-transparent px-[4rem]">
            <div className="container-lg">
                <div className="text-[#CCC] urbanist-regular text-[4.8rem] overflow-hidden text-center">
                    Explore our other Categories
                </div>
                {data?.length > 0 ?
                    <Slider {...settings} className="relative overflow-hidden my-[3rem]" >
                        {data?.filter((item) => { return item?.sub_category?.slug != selectedCategory }).map((category, index) => {
                            return (
                                <div key={index} className="px-[2rem] flex justify-center cursor-pointer" onClick={() => navigate(`/community/${category?.sub_category_name}`)}>
                                    <CheckoutCategoriesCard
                                        image={category?.Image[0]?.url}
                                        title={category?.sub_category_name}
                                        isTitleShow={true}
                                    />
                                </div>
                            )
                        }
                        )}
                    </Slider>
                    :
                    <div className="text-white text-[2rem] text-center">
                        No Articles Available
                    </div>
                }
            </div>
        </div>
    )
}

export default ExploreCategories
