import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { strapiOurFitTeamFailure, strapiOurFitTeamSuccess } from '../../actions/StrapiServiceActions/strapiOurFitTeamAction';

function* strapiOurFitTeam({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiArticles + `?category.slug=our_fit_team`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_OUR_FIT_TEAM_SUCCESS response :", response);
        console.log("STRAPI_OUR_FIT_TEAM_SUCCESS status :", response?.status);
        console.log("STRAPI_OUR_FIT_TEAM_SUCCESS data :", response?.data);
        console.log("STRAPI_OUR_FIT_TEAM_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            strapiOurFitTeamSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_OUR_FIT_TEAM_FAILURE status :", error?.response?.status);
        console.log("STRAPI_OUR_FIT_TEAM_FAILURE data:", error?.response?.data);
        console.log("STRAPI_OUR_FIT_TEAM_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiOurFitTeamFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* strapiOurFitTeamSaga() {
    yield takeLatest(actionTypes.STRAPI_OUR_FIT_TEAM_REQUEST, strapiOurFitTeam);
}

export default strapiOurFitTeamSaga;