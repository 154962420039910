import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const checkPaymentStatus = (payload) => ({
    type: actionTypes.CHECK_PAYMENT_STATUS_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const checkPaymentStatusSuccess = (payload) => ({
    type: actionTypes.CHECK_PAYMENT_STATUS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const checkPaymentStatusFailure = (payload) => ({
    type: actionTypes.CHECK_PAYMENT_STATUS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const checkPaymentStatusReset = () => ({
    type: actionTypes.CHECK_PAYMENT_STATUS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const checkPaymentStatusResetAll = () => ({
    type: actionTypes.CHECK_PAYMENT_STATUS_RESET_ALL,
}); 