import React from 'react'
import { DownloadAppLandingImg } from '../../../static/constants/imgConstants';
import "./AppBanner2.css"

const AppBanner2 = () => {
  return (
    <div className="w-full h-max app-banner-2-background flex items-center justify-center px-[4rem]">
      <div
        className="container-lg flex flex-col-reverse lg:flex-row items-center lg:justify-between py-12"
      >
        <div className="text-left lg:w-1/2 lg:mb-0 mt-[5rem] lg:mt-0">
          <h1 className="text-[#76CEE4] text-[6.4rem] font-bold mb-4 urbanist-bold">
            Book your Fitness Session now!
          </h1>
          <p className="text-white text-[3rem] mb-6 urbanist-medium">
            Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit, sed do eiusmod tempor
          </p>
          <a href="https://play.google.com/store/apps" target='_blank' className="">
            <button className="bg-white text-[#486077] py-3 px-6 rounded-lg text-[2.63rem] font-semibold hover:text-[#FFF] hover:bg-blue-700 urbanist-semibold">
              Book Now
            </button>
          </a>
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <img
            className="w-full "
            src={DownloadAppLandingImg.AppBanner2}
            alt="App Banner"
          />
        </div>
      </div>
    </div>
  )
}

export default AppBanner2