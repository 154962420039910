import React from 'react'
import Marquee from '../../utils/Marquee/Marquee'

function EStoreCarousal() {
    return (
        <div className='w-full bg-[#1E1E1E]'>
            <h2 className='text-[6.4rem] text-[#fff] text-center Urbanist-bold my-[8rem] lg:my-[3rem]'>The E-store for Your Wellness Needs</h2>
            <Marquee />
        </div>
    )
}

export default EStoreCarousal
