import React from 'react'
import moment from 'moment';
import { base_url_strapi_service } from '../../../store/constants';
import { useNavigate } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';

const SpecialBlogCategory1 = ({ isRevers, data }) => {

    const navigate = useNavigate()

    return (
        <div className={`flex ${isRevers ? "flex-col lg:flex-row-reverse mt-[10rem]" : "flex-col lg:flex-row"} justify-center items-center mx-[1rem] md:mx-[10rem]`}>
            <div className={`w-full flex justify-center items-center lg:block lg:w-[30%] text-white ${isRevers ? "ml-[5rem]" : ""}`}>
                <div className="urbanist-extrabold bordered-font text-transparent text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem] my-[2rem] sm:my-0">
                    {data[0]?.sub_category?.TitlePrimary}
                </div>
                <div className="text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem] urbanist-extrabold text-[#ffffff]/50 mx-5 md:mx-10 lg:mx-0">
                    {data[0]?.sub_category?.TitleSecondary}
                </div>
                <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold hidden lg:block" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                    View More
                </button>
            </div>
            <div className="w-full xl:w-[55%]">
                <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 overflow-hidden">
                    {data?.map((items, index) => {
                        if (index <= 3) {
                            return (
                                <Tilt className="background-stripes parallax-effect p-[1rem]" perspective={900}>
                                    <div className={`w-[22rem] sm:w-[32rem] xl:w-[37rem] cursor-pointer ${index === 2 ? "ml-[5rem]" : index === 3 ? "ml-[5rem]" : ""}`} onClick={() => navigate(`/community/blogs/${items?.sub_category?.slug}`, { state: { data: items } })}>
                                        <img src={base_url_strapi_service + items?.image?.url} alt="" className="w-[22rem] h-[18rem] md:w-[32rem] md:h-[25rem] xl:w-[37rem] xl:h-[30rem] rounded-[2rem] mr-[6rem]" />
                                        <h3 className={`text-white text-[1.4rem] text-left urbanist-bold md:text-[2.4rem] p-[1rem]`}>{items.description}</h3>
                                        <div className="text-[1.8rem] text-white flex justify-between items-center urbanist-semibold px-[1rem]">
                                            <span>{moment(items?.createdAt).format("DD-MM-YYYY")}</span>
                                            <span>3 min read</span>
                                        </div>
                                    </div>
                                </Tilt>
                            )
                        } else {
                            return
                        }
                    })
                    }
                </div>
            </div>
            <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold lg:hidden" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                View More
            </button>
        </div>
    )
}

export default SpecialBlogCategory1