import { actionTypes } from '../../types';

export const subscription = (payload) => ({
    type: actionTypes.SUBSCRIPTION_REQUEST,
    payload,
});

export const subscriptionSuccess = (payload) => ({
    type: actionTypes.SUBSCRIPTION_SUCCESS,
    payload,
});

export const subscriptionFailure = (payload) => ({
    type: actionTypes.SUBSCRIPTION_FAILURE,
    payload,
});

export const subscriptionReset = () => ({
    type: actionTypes.SUBSCRIPTION_RESET,
});

export const subscriptionResetAll = () => ({
    type: actionTypes.SUBSCRIPTION_RESET_ALL,
}); 