import React from 'react';
import { migBoxData } from './migBox';

const DownloadMigBox = () => {
    return (
        <div className='bg-[#1E1E1E] px-[4rem] py-[4rem] md:py-0'>
            <div className="container-lg">
                <div className="flex flex-col items-center justify-start text-white mb-[10rem]">
                    <h1 className="urbanist-bold text-[6.4rem] text-[#80C241] mb-[7rem]">Download a mig box</h1>
                    <div className="w-full justify-start grid grid-cols-1 md:grid-cols-3 gap-20">
                        {
                            migBoxData?.map((item, i) => {
                                return (
                                    <div key={i} className="">
                                        <div className='flex justify-start items-center'>
                                            <div className="w-[2.4rem] h-[4.2rem] border-l-[.5rem] border-[#80C241]"></div>
                                            <h2 className="urbanist-semibold text-[3.2rem]">{item.title}</h2>
                                        </div>
                                        <div className="flex justify-start items-center">
                                            <p className="ml-[2.4rem] urbanist-regular text-[2rem] text-[#A9A9A9]">{item.info}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadMigBox;