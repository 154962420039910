import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_helpdesk_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { getTicketByUserIdFailure, getTicketByUserIdSuccess } from '../../actions/helpdeskServiceActions/getTicketByUserIdAction';

function* getTicketByUserId({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_helpdesk_service,
            endPoint: endpoints.GetTicketByUserId + `?userEmailId=${payload.userEmail}`,
            // data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("GET_TICKET_BY_USER_ID_SUCCESS status :", response.status);
        console.log("GET_TICKET_BY_USER_ID_SUCCESS data :", response.data);
        console.log("GET_TICKET_BY_USER_ID_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        yield put(getTicketByUserIdSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("GET_TICKET_BY_USER_ID_FAILURE error :", error.response?.status);
        console.log("GET_TICKET_BY_USER_ID_FAILURE error :", error.message);
        console.log('====================================');
        yield put(getTicketByUserIdFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* getTicketByUserIdSaga() {
    yield takeLatest(actionTypes.GET_TICKET_BY_USER_ID_REQUEST, getTicketByUserId);
}

export default getTicketByUserIdSaga;