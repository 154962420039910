import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const RewardConfigAll = (payload) => ({
    type: actionTypes.REWARD_CONFIG_ALL_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const RewardConfigAllSuccess = (payload) => ({
    type: actionTypes.REWARD_CONFIG_ALL_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const RewardConfigAllFailure = (payload) => ({
    type: actionTypes.REWARD_CONFIG_ALL_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const RewardConfigAllReset = () => ({
    type: actionTypes.REWARD_CONFIG_ALL_RESET,
});

// Action creator for resetting all OTP generation reducer
export const RewardConfigAllResetAll = () => ({
    type: actionTypes.REWARD_CONFIG_ALL_RESET_ALL,
}); 