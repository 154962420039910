import { actionTypes } from '../../types';

export const allOrders = (payload) => ({
    type: actionTypes.ALL_ORDERS_REQUEST,
    payload,
});

export const allOrdersSuccess = (payload) => ({
    type: actionTypes.ALL_ORDERS_SUCCESS,
    payload,
});

export const allOrdersFailure = (payload) => ({
    type: actionTypes.ALL_ORDERS_FAILURE,
    payload,
});

export const allOrdersReset = () => ({
    type: actionTypes.ALL_ORDERS_RESET,
});

export const allOrdersResetAll = () => ({
    type: actionTypes.ALL_ORDERS_RESET_ALL,
}); 