import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const generateOtp = (payload) => ({
    type: actionTypes.GENERATE_OTP_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const generateOtpSuccess = (payload) => ({
    type: actionTypes.GENERATE_OTP_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const generateOtpFailure = (payload) => ({
    type: actionTypes.GENERATE_OTP_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const generateOtpReset = () => ({
    type: actionTypes.GENERATE_OTP_RESET,
});

// Action creator for resetting all OTP generation reducer
export const generateOtpResetAll = () => ({
    type: actionTypes.GENERATE_OTP_RESET_ALL,
}); 