import { actionTypes } from '../../types';

export const createTicket = (payload) => ({
    type: actionTypes.CREATE_TICKET_REQUEST,
    payload,
});

export const createTicketSuccess = (payload) => ({
    type: actionTypes.CREATE_TICKET_SUCCESS,
    payload,
});

export const createTicketFailure = (payload) => ({
    type: actionTypes.CREATE_TICKET_FAILURE,
    payload,
});

export const createTicketReset = () => ({
    type: actionTypes.CREATE_TICKET_RESET,
});

export const createTicketResetAll = () => ({
    type: actionTypes.CREATE_TICKET_RESET_ALL,
}); 