import React, { useEffect, useState } from "react";
import "./FitTeam.css";
import FitTeamCard from "./FitTeamCard";
import { OurStoryPageImg } from "../../../static/constants/imgConstants";
import { strapiOurFitTeamReset } from "../../../store/actions/StrapiServiceActions/strapiOurFitTeamAction";
import { useDispatch, useSelector } from "react-redux";

function FitTeam() {

    const dispatch = useDispatch()

    const strapiOurFitTeamReducerData = useSelector((state) => state.strapiOurFitTeamReducer?.data);
    const strapiOurFitTeamReducerStatus = useSelector((state) => state.strapiOurFitTeamReducer?.status);

    useEffect(() => {
        if (strapiOurFitTeamReducerStatus == 200) {
            dispatch(strapiOurFitTeamReset())
        } else if (strapiOurFitTeamReducerStatus) {
            dispatch(strapiOurFitTeamReset())
        }
    }, [strapiOurFitTeamReducerStatus])


    return (
        <div className="bg-[#1E1E1E]">
            <div className="container-lg px-[4rem]">
                <div className="fit-team text-white w-full h-full">
                    <div className="flex flex-col justify-center items-center text-center mb-12 h-full">
                        <h2 className="text-[6.5rem] font-bold text-white montserrat-bold">Our Fit.Team</h2>
                        <p className="text-[#858585] text-[3rem] montserrat-medium text-start md:text-center w-[85%]">
                            Our Transformative Leaders Whose Commitment To Excellence And Innovation Is Reshaping The Fitness Experience In India
                        </p>
                    </div>
                    {!(strapiOurFitTeamReducerData.length > 0) ?
                        <div className="urbanist-extrabold text-[3rem] text-center">
                            No Data Available at This Time
                        </div>
                        :
                        null
                    }
                    <div className="grid grid-cols-1 [@media(min-width:576px)]:grid-cols-2 [@media(min-width:768px)]:grid-cols-4 gap-8">
                        {strapiOurFitTeamReducerData.length > 0 ?
                            strapiOurFitTeamReducerData.map((member, index) => (
                                <FitTeamCard
                                    key={index}
                                    member={member}
                                />
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FitTeam;
