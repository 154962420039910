import React, { useState } from "react";

function AboutUsGridSec2() {
    const [gymText, setGymText] = useState("single gym")

    return (
        <div className="about-us-grid-sec2 min-h-max lg:h-[58rem] px-[4rem]">
            <div className="container-lg flex relative h-full">
                <div className="absolute left-0 bottom-0 lg:bottom-[4rem] w-[54%] hidden lg:block">
                    <div className="w-[8rem] h-[8rem] bg-[#21AB64] rounded-full mb-[1.6rem]" />
                    <div className="text-white urbanist-bold text-[4rem]">
                        Opinion: Lorem <br /> Lorem Ispum <br /> Lorem
                    </div>
                </div>
                <div className="flex flex-col w-full lg:w-[46%] ml-0 lg:ml-[54%] lg:pl-[8rem] pt-[4rem] lg:pt-[13rem]">
                    <div className="flex border-b-2 border-[#E3E3E3]">
                        <div
                            className={`overflow-hidden py-3 px-5 text-[2rem] md:text-[2rem] ${gymText === "single gym"
                                    ? "border-t-2 border-r-2 border-l-2 border-solid border-[#E3E3E3]"
                                    : "text-[#B4B4B4]"
                                } urbanist-semibold cursor-pointer`}
                            onClick={() => setGymText("single gym")}
                        >
                            Partner Gym
                        </div>
                        {/* <div
                            className={`py-2.5 px-5 overflow-hidden text-[2rem] md:text-[2rem]  ${gymText === "Gym Chains"
                                    ? "border-t-2 border-r-2 border-l-2 border-solid border-[#E3E3E3]"
                                    : "text-[#B4B4B4]"
                                } urbanist-semibold cursor-pointer`}
                            onClick={() => setGymText("Gym Chains")}
                        >
                            Gym Chains
                        </div> */}
                    </div>
                    <div className="pt-5 border-t-2 border-solid text-[1.7rem] urbanist-medium">
                        <p className="my-[.5rem] leading-[2.6rem]">
                            Offer your members the convenience of accessing our network’s facilities while traveling or
                            living further away, offering flexibility that helps retain and attract more members.
                            Gain exposure through our marketing and promotional efforts - reducing your own costs,
                            connecting with a broader audience and boosting your gym’s profile. Leverage our innovative
                            programs and resources to enrich your gym’s services, keeping your offerings fresh and
                            competitive.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsGridSec2;
