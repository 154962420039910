import { actionTypes } from "../../types";

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: {}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.MAKE_PAYMENT_REQUEST:
			return {
                ...state,
                loading: true,
            }
            case actionTypes.MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.data?.message,
                data: action?.payload?.data
            }
            case actionTypes.MAKE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
        case actionTypes.MAKE_PAYMENT_RESET:
            return {
                ...state,
                status: '',
                message: ''
            }
        case actionTypes.MAKE_PAYMENT_RESET_ALL:
            return initialState
		default:
			return state;
	}
};