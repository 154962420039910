import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const community = (payload) => ({
    type: actionTypes.COMMUNITY_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const communitySuccess = (payload) => ({
    type: actionTypes.COMMUNITY_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const communityFailure = (payload) => ({
    type: actionTypes.COMMUNITY_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const communityReset = () => ({
    type: actionTypes.COMMUNITY_RESET,
});

// Action creator for resetting all OTP generation reducer
export const communityResetAll = () => ({
    type: actionTypes.COMMUNITY_RESET_ALL,
}); 