import React, { useEffect, useState } from 'react'
import SidebarLayout from '../../components/main/SidebarLayout/SidebarLayout'
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa6";
import ProfileInfo from '../../components/common/ProfileInfo/ProfileInfo';
import MyDashboard from '../../components/common/MyDashboard/MyDashboard';
import OrderInvoice from '../../components/common/OrderInvoice/OrderInvoice';
import Subscriptions from '../../components/common/Subscriptions/Subscriptions';
import AssessmentReports from '../../components/common/AssessmentReports/AssessmentReports';
import ReferalsCoupons from '../../components/common/ReferalsCoupons/ReferalsCoupons';
import Support from '../../components/common/Support/Support';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setAccessToken, setUserId, setVerifiedPhoneNo } from '../../store/actions/utilsActions/utilsActions';
import { NavbarImg } from '../../static/constants/imgConstants';

const Profile = () => {
    const Navigate = useNavigate()
    const location = useLocation();

    const dispatch = useDispatch()

    const data = location.state?.data || {}

    const [selectedOption, setSelectedOption] = useState("MyDashboard");
    const [isUserIconClicked, setIsUserIconClicked] = useState(false);

    const utilReducers = useSelector((state) => state.utilReducers);
    const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);

    const RenderOption = () => {
        switch (selectedOption) {
            case "ProfileInfo":
                return <ProfileInfo setIsUserIconClicked={setIsUserIconClicked} />;
            case "OrdersInvoice":
                return <OrderInvoice />;
            case "Subscription":
                return <Subscriptions />;
            case "AssessmentReports":
                return <AssessmentReports />;
            case "ReFerralCoupons":
                return <ReferalsCoupons />;
            case "Support":
                return < Support />;
            case "Logout":
                return handleLogout()
            default:
                return <MyDashboard />;
        }
    }

    useEffect(() => {
        if (data === "UserProfilePopup") {
            setSelectedOption("ProfileInfo");
        }
    }, [data]);

    const handleLogout = () => {
        dispatch(logout()); Navigate("/");
    }

    console.log("Profile Data:", data);


    return (
        <div className='bg-[#1E1E1E] h-screen dm-sans-regular'>
            <div className="flex justify-between w-full h-full">
                <SidebarLayout setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
                <div className="flex flex-col w-full relative">
                    <div className="w-full h-[14%]">
                        <div
                            className={`flex justify-end p-5 my-5 ${isUserIconClicked ? "text-[#FFF200]" : "text-white"} overflow-hidden`}
                            onClick={() => setIsUserIconClicked(!isUserIconClicked)}
                            onBlur={() => setIsUserIconClicked(false)}
                            tabindex={selectedOption === "ProfileInfo" ? null : 0}
                        >
                            <div className="rounded-[2rem]">
                                <img src={CustomerProfileReducerData[0]?.profilePicURL ? CustomerProfileReducerData[0]?.profilePicURL : NavbarImg.Exercise} alt="" className="w-[2.8rem] h-[2.8rem]" />
                            </div>
                            {isUserIconClicked ?
                                <div className="flex justify-end absolute top-[5rem] right-[1rem] z-[50]">
                                    <div className="bg-black w-[25rem] h-[10rem] rounded-[2rem] flex flex-col justify-center items-center">
                                        <div
                                            className={`text-[2rem] text-white hover:text-[#FFF200] focus:text-[#FFF200] cursor-pointer mb-2`}
                                            onClick={() => { setSelectedOption("ProfileInfo"); setIsUserIconClicked(false) }}
                                        >
                                            {
                                                CustomerProfileReducerData[0]?.firstName ?
                                                    CustomerProfileReducerData[0]?.firstName + " " + CustomerProfileReducerData[0].lastName
                                                    :
                                                    CustomerProfileReducerData[0]?.email ?
                                                        CustomerProfileReducerData[0]?.email
                                                        :
                                                        "Profile"
                                            }
                                        </div>
                                        <div
                                            className={`text-[2rem] text-white hover:text-[#FFF200] focus:text-[#FFF200] cursor-pointer mt-2`}
                                            onClick={() => handleLogout()}
                                        >
                                            Logout
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>
                    <div className="w-full h-full rounded-[2rem] my-[2rem]">
                        {RenderOption()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile