import { actionTypes } from '../../types';

export const subscriptionUpgradeSummary = (payload) => ({
    type: actionTypes.SUBSCRIPTION_UPGRADE_SUMMARY_REQUEST,
    payload,
});

export const subscriptionUpgradeSummarySuccess = (payload) => ({
    type: actionTypes.SUBSCRIPTION_UPGRADE_SUMMARY_SUCCESS,
    payload,
});

export const subscriptionUpgradeSummaryFailure = (payload) => ({
    type: actionTypes.SUBSCRIPTION_UPGRADE_SUMMARY_FAILURE,
    payload,
});

export const subscriptionUpgradeSummaryReset = () => ({
    type: actionTypes.SUBSCRIPTION_UPGRADE_SUMMARY_RESET,
});

export const subscriptionUpgradeSummaryResetAll = () => ({
    type: actionTypes.SUBSCRIPTION_UPGRADE_SUMMARY_RESET_ALL,
}); 