import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_dot_cms_service, endpoints } from '../../constants';
import { AxiosCMSService } from '../../services';
import { actionTypes } from '../../types';
import { setAccessToken, setRefreshToken, setVerifiedPhoneNo } from '../../actions/utilsActions/utilsActions';
import store from '../../configStore';
import { CMSProductsFailure, CMSProductsSuccess } from '../../actions/DotCMSServiceActions/CMSProductsAction';


function* CMSProducts({ payload }) {
    try {
        const response = yield call(AxiosCMSService, {
            method: 'get',
            base_url: base_url_dot_cms_service,
            endPoint: endpoints.CMSproducts + `?language_id=1`,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CMS_PRODUCTS_SUCCESS status :", response.status);
        console.log("CMS_PRODUCTS_SUCCESS data :", response.data);
        console.log("CMS_PRODUCTS_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            CMSProductsSuccess({
                status: response?.status,
                data: response?.data
            })
        );
        yield put(setAccessToken(response?.data?.token?.accessToken));
        yield put(setRefreshToken(response?.data?.token?.refreshToken));
        const state = store.getState();
        let phoneNumber = state.utilReducers?.phoneNumber
        if (phoneNumber) {
            yield put(setVerifiedPhoneNo({
                number: phoneNumber.number,
                isVerified: true
            }))
        }
    } catch (error) {
        console.log('====================================');
        console.log("CMS_PRODUCTS_FAILURE status :", error?.response?.status);
        console.log("CMS_PRODUCTS_FAILURE data:", error?.response?.data);
        console.log("CMS_PRODUCTS_FAILURE error :", error);
        console.log('====================================');
        yield put(CMSProductsFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* CMSProductsSaga() {
    yield takeLatest(actionTypes.CMS_PRODUCTS_REQUEST, CMSProducts);
}

export default CMSProductsSaga;