import { put, takeLatest } from 'redux-saga/effects';
import { base_url_order_management_service, endpoints } from '../../constants';
import { actionTypes } from '../../types';
import axios from 'axios';
import store from '../../configStore';
import { downloadOrderInvoicePdfSuccess } from '../../actions/orderManagementActions/downloadOrderInvoicePdfAction';

function* downloadOrderInvoicePdf({ payload }) {

    const state = store.getState();
    let accessToken = state.utilReducers?.accessToken
    /* try { */
    axios({
        method: 'POST',
        url: base_url_order_management_service + endpoints.downloadOrderInvoicePdf + `?orderid=${payload?.orderId}`,
        responseType: 'blob',
        headers:
        {
            'Content-Type': 'application/json',
            Authorization: `bearer ${accessToken}`,
        }
    }).then((response) => {
        console.log('====================================');
        console.log("DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS status :", response?.status);
        console.log("DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS data :", response?.data);
        console.log("DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        const href = window.URL?.createObjectURL(response?.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${payload.orderId}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
        alert("Invoice Downloaded")
    }).catch((error) => {
        console.log("Download error :", error);
        alert("Invoice not downloaded at this time! Please try again later..")
    })
}

function* downloadOrderInvoicePdfSaga() {
    yield takeLatest(actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_REQUEST, downloadOrderInvoicePdf);
}

export default downloadOrderInvoicePdfSaga;