import React from 'react'
import { ExploreOtherGymImg, NearbyGymCardImg } from '../../../static/constants/imgConstants'
import ExploreOtherGymCard from './ExploreOtherGymCard'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ExploreOtherGym = () => {
    const { gym_id } = useParams();

    const searchByLocationReducersData = useSelector((state) => state.searchByLocationReducers?.data);

    console.log("searchByLocationReducersData :",searchByLocationReducersData?.venues?.filter((item) => item.id !== gym_id));
    

    return (
        <div className='bg-[#1E1E1E] px-[4rem]'>
            <div className="container-lg pb-[8rem]">
                <div className="urbanist-medium text-white text-[6.4rem] text-center my-[8.5rem]">
                    Explore our other Gyms
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
                    {searchByLocationReducersData?.venues?.filter((item) => item.id !== gym_id)?.map((items, index) => {
                        if (index > 2) {
                            return null
                        } else {
                            return (
                                <ExploreOtherGymCard
                                    img={items?.venuePosterImage ? items?.venuePosterImage : ExploreOtherGymImg.exploreOtherGymImg2}
                                    id={items.id}
                                    icon={items.category == "premium" ? NearbyGymCardImg.premium : NearbyGymCardImg.champion}
                                    type={items.category}
                                    title={items.name}
                                    address={items.address.line1}
                                    ratingStar={4}
                                    totalRating={"4.3/5 (780)"}
                                    marginTop={index === 1 ? 9.5 : 0}
                                />
                            )
                        }
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default ExploreOtherGym