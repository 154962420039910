import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { strapiFounderFailure, strapiFounderSuccess } from '../../actions/StrapiServiceActions/strapiFounderAction';

function* strapiFounder({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiArticles + `?category.slug=founder`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_FOUNDER_SUCCESS response :", response);
        console.log("STRAPI_FOUNDER_SUCCESS status :", response?.status);
        console.log("STRAPI_FOUNDER_SUCCESS data :", response?.data);
        console.log("STRAPI_FOUNDER_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            strapiFounderSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_FOUNDER_FAILURE status :", error?.response?.status);
        console.log("STRAPI_FOUNDER_FAILURE data:", error?.response?.data);
        console.log("STRAPI_FOUNDER_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiFounderFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* strapiFounderSaga() {
    yield takeLatest(actionTypes.STRAPI_FOUNDER_REQUEST, strapiFounder);
}

export default strapiFounderSaga;