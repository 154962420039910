import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiPressAndMedia = (payload) => ({
    type: actionTypes.STRAPI_PRESS_AND_MEDIA_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiPressAndMediaSuccess = (payload) => ({
    type: actionTypes.STRAPI_PRESS_AND_MEDIA_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiPressAndMediaFailure = (payload) => ({
    type: actionTypes.STRAPI_PRESS_AND_MEDIA_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiPressAndMediaReset = () => ({
    type: actionTypes.STRAPI_PRESS_AND_MEDIA_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiPressAndMediaResetAll = () => ({
    type: actionTypes.STRAPI_PRESS_AND_MEDIA_RESET_ALL,
}); 