import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_venue_staff_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { venueStaffFailure, venueStaffSuccess } from '../../actions/VenueStaffManagementServiceActions/venueStaffAction';
// import store from '../../configStore';

function* venueStaff({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_venue_staff_management_service,
            endPoint: endpoints.VenueStaff + `?searchkey=venueId&searchvalue=${payload.venueId}&sortfield=Name&pagesize=10000&pagenumber=1&order=1&OnlyPublicfields=false`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("VENUE_STAFF_SUCCESS status :", response.status);
        console.log("VENUE_STAFF_SUCCESS data :", response.data);
        console.log("VENUE_STAFF_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            venueStaffSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("VENUE_STAFF_FAILURE status :", error?.response?.status);
        console.log("VENUE_STAFF_FAILURE data:", error?.response?.data);
        console.log("VENUE_STAFF_FAILURE error :", error);
        console.log('====================================');
        yield put(venueStaffFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* venueStaffSaga() {
    yield takeLatest(actionTypes.VENUE_STAFF_REQUEST, venueStaff);
}

export default venueStaffSaga;