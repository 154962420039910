import { all } from "redux-saga/effects";
import generateOtpSaga from "./authSagas/generateOtpSaga";
import validateOtpSaga from "./authSagas/validateOtpSaga";
import subscriptionSaga from "./SubscriptionManagementSagas/subscriptionSagas";
import searchByLocationSaga from "./venueManagementServiceSagas/searchByLocationSagas";
import emailInvoiceSaga from "./orderManagementSagas/emailInvoiceSaga";
import orderCreateSaga from "./orderManagementSagas/orderCreateSaga";
import orderTransactionStatusSaga from "./orderManagementSagas/orderTransactionStatusSaga";
import downloadOrderInvoicePdfSaga from "./orderManagementSagas/downloadOrderInvoicePdfSaga";
import contactUsSaga from "./leadgenServiceSagas/contactUsSaga";
import refreshTokenSaga from "./authSagas/refreshTokenSaga";
import makePaymentSaga from "./paymentGatewaySagas/makePaymentSaga";
import checkPaymentStatusSaga from "./paymentGatewaySagas/checkPaymentStatusSaga";
import geocodeSaga from "./GoogleApiSagas/GeocodeSaga";
import watchCustomerProfileSaga from "./CustomerManagementServiceSagas/customerProfileSaga";
import updateProfileSaga from "./CustomerManagementServiceSagas/updateProfileSaga";
import uploadProfilePicSaga from "./FileManagementServiceSagas/uploadProfilePicSaga";
import customerProfileSaga from "./CustomerManagementServiceSagas/customerProfileSaga";
import subscriptionRecordSaga from "./SubscriptionManagementSagas/SubscriptionRecordSaga";
import CMSProductsSaga from "./DotCMSServiceSagas/CMSProductsSaga";
import allOrdersSaga from "./orderManagementSagas/allOrdersSaga";
import createTicketSaga from "./helpdeskServiceSagas/createTicketSaga";
import strapiTokenSaga from "./StrapiServiceSagas/strapiTokenSaga";
import strapiArticlesSaga from "./StrapiServiceSagas/strapiArticlesSaga";
import strapiClientFeedbackSaga from "./StrapiServiceSagas/strapiClientFeedbackSaga";
import strapiPressAndMediaSaga from "./StrapiServiceSagas/strapiPressAndMediaSaga";
import strapiOurFitTeamSaga from "./StrapiServiceSagas/strapiOurFitTeamSaga";
import strapiFounderSaga from "./StrapiServiceSagas/strapiFounderSaga";
import GetFitnessAssessmentSaga from "./FitnessAssessementServiceSagas/GetFitnessAssessmentSaga";
import getTicketByUserIdSaga from "./helpdeskServiceSagas/getTicketByUserIdSaga";
import pauseSubscriptionSaga from "./SubscriptionManagementSagas/pauseSubscriptionSaga";
import unPauseSubscriptionSaga from "./SubscriptionManagementSagas/unPauseSubscriptionSaga";
import subscriptionUpgradeSummarySaga from "./SubscriptionManagementSagas/subscriptionUpgradeSummarySaga";
import venueStaffSaga from "./VenueStaffManagementServiceSagas/venueStaffSaga";
import StrapifaqsSaga from "./StrapiServiceSagas/StrapifaqsSaga";
import gxSessionSaga from "./GxSessionManagementServiceSagas/gxSessionSaga";
import getReferralCodeSaga from "./authSagas/getReferralCodeSaga";
import referFriendSaga from "./authSagas/referFriendSaga";
import fitcoinhistorySaga from "./RewardManagementServiceSagas/fitcoinhistorySaga";
import subscribeSaga from "./leadgenServiceSagas/subscribeSaga";
import getLeaderBoardFileSaga from "./FileManagementServiceSagas/getLeaderBoardFileSaga";
import trainerRatingSaga from "./userFeedbackManagementServiceSagas/trainerRatingSaga";
import communitySaga from "./StrapiServiceSagas/communitySaga";
import communitySubCategoriesSaga from "./StrapiServiceSagas/communitySubCategoriesSaga";
import getVenueDetailsSaga from "./venueManagementServiceSagas/getVenueDetailsSaga";
import offerSearchSaga from "./SubscriptionManagementSagas/offerSearchSaga";
import subscriptionOffersSaga from "./SubscriptionManagementSagas/subscriptionOffersSaga";
import getSubscriptionCategoryNameSaga from "./StrapiServiceSagas/getSubscriptionCategoryNameSaga";
import fitcoinBalanceSaga from "./RewardManagementServiceSagas/fitcoinBalanceSaga";
import RewardConfigAllSaga from "./RewardManagementServiceSagas/RewardConfigAllSaga";
import filehandlerSaga from "./FileManagementServiceSagas/filehandlerSaga.js";

export default function* rootSaga() {
    yield all([
        generateOtpSaga(),
        validateOtpSaga(),
        refreshTokenSaga(),
        subscriptionSaga(),
        searchByLocationSaga(),
        contactUsSaga(),
        makePaymentSaga(),
        checkPaymentStatusSaga(),
        emailInvoiceSaga(),
        orderCreateSaga(),
        orderTransactionStatusSaga(),
        downloadOrderInvoicePdfSaga(),
        geocodeSaga(),
        customerProfileSaga(),
        updateProfileSaga(),
        uploadProfilePicSaga(),
        subscriptionRecordSaga(),
        CMSProductsSaga(),
        allOrdersSaga(),
        createTicketSaga(),
        strapiTokenSaga(),
        strapiArticlesSaga(),
        strapiClientFeedbackSaga(),
        strapiPressAndMediaSaga(),
        strapiOurFitTeamSaga(),
        strapiFounderSaga(),
        GetFitnessAssessmentSaga(),
        getTicketByUserIdSaga(),
        pauseSubscriptionSaga(),
        unPauseSubscriptionSaga(),
        subscriptionUpgradeSummarySaga(),
        venueStaffSaga(),
        StrapifaqsSaga(),
        gxSessionSaga(),
        getReferralCodeSaga(),
        referFriendSaga(),
        fitcoinhistorySaga(),
        subscribeSaga(),
        getLeaderBoardFileSaga(),
        trainerRatingSaga(),
        communitySaga(),
        communitySubCategoriesSaga(),
        getVenueDetailsSaga(),
        offerSearchSaga(),
        subscriptionOffersSaga(),
        getSubscriptionCategoryNameSaga(),
        fitcoinBalanceSaga(),
        RewardConfigAllSaga(),
        filehandlerSaga(),
    ])
}