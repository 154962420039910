import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VideoPlayerCardMini from './VideoPlayerCardMini'
import ReactPlayer from 'react-player'
import { Swiper, SwiperSlide } from "swiper/react"
import { VideoPlayer1Img } from '../../../static/constants/imgConstants'
import "swiper/css";
import "./VideoPlayer1.css"
import "swiper/css/effect-coverflow";
import { FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { FaPause, FaStop } from 'react-icons/fa6'
import { BsArrowCounterclockwise, BsArrowClockwise } from "react-icons/bs";
import 'rc-slider/assets/index.css'; // Import the rc-slider CSS
import Slider from 'rc-slider';
import BuyNowPopup from '../BuyNowPopup/BuyNowPopup'
import Slider1 from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function VideoPlayer1() {
    const Navigate = useNavigate()
    const playerRef = useRef('react-player');

    const [screenSize] = useState(window.innerWidth);
    const [showVideoTitle, setShowVideoTitle] = useState(true)
    const [showSlider, setShowSlider] = useState(true);
    const [playing, setPlaying] = useState(true);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false)

    const [handleOutsideClick, setHandleOutsideClick] = useState(false);

    const [buyNowPopup, setBuyNowPopup] = useState(false);

    const SwiperImages = [
        { title: "Treadmill", image: VideoPlayer1Img?.cardVideoImg1 },
        { title: "Yoga", image: VideoPlayer1Img?.cardVideoImg2 },
        { title: "Stretching", image: VideoPlayer1Img?.cardVideoImg3 },
        { title: "Running", image: VideoPlayer1Img?.cardVideoImg4 },
        { title: "Running", image: VideoPlayer1Img?.cardVideoImg4 },
    ]

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[25%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        centerPadding: '0',
    }

    const handleVolumeChange = (value) => {
        if (typeof value === 'number') {
            const newVolume = value / 100;  // Convert from percentage to 0-1 range
            setVolume(newVolume); // Update volume state
        }
    };

    const handleStop = () => {
        setPlaying(false);
        playerRef.current.seekTo(parseFloat(0))
    };

    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    }

    const handleForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }

    useEffect(() => {
        setBuyNowPopup(false);
    }, [handleOutsideClick])


    return (
        <div className='bg-black relative container-lg px-[2rem] md:h-[80rem] 2xl:h-[85rem] mb-[5rem] md:mb-0'>
            <div className="relative">
                {showVideoTitle ?
                    <div className="absolute top-[45rem] md:top-[5rem] w-full bg-blue z-[20]">
                        <div className="bebas-neue-regular text-white text-[4.8rem] sm:text-[9.6rem] text-center">
                            Ready to take the next step?
                        </div>
                        <div className="flex justify-center items-center">
                            <div className="bebas-neue-regular text-[#ffffff]/50 text-[2rem] sm:text-[3rem] text-start w-[45%]">
                                Push your limits. Flex your potential. Breathe deeply. Our world-class instructors are here to empower and motivate you every step of the way.
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="h-[78rem] relative">
                    <ReactPlayer
                        url={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4"}
                        controls={screenSize > 600 ? false : true}
                        width={"100%"}
                        playing={playing}
                        ref={playerRef}
                        muted={muted}
                        volume={volume}
                        light={
                            <img
                                src={screenSize > 600 ? VideoPlayer1Img.VideoPlayer1CoverImg : VideoPlayer1Img.VideoPlayer1CoverSmallImg} alt='Thumbnail'
                                className='w-full sm:w-[158.3rem] h-[75.5rem]'
                            />
                        }
                        height={"100%"}
                        onStart={() => { setShowVideoTitle(false); setPlaying(true); setShowSlider(false) }}
                        onPause={() => setShowSlider(true)}
                    />
                    {showVideoTitle ?
                        null
                        :
                        screenSize > 600 ?
                            <div className="absolute right-0 top-[39rem] transform -translate-y-1/2 flex flex-col space-y-10 bg-[#333333]/40 opacity-50 hover:bg-[#333333] hover:opacity-80 p-4 rounded-lg h-[65vh]">
                                <button className="flex justify-center overflow-hidden" onClick={() => {
                                    setPlaying(!playing);
                                    setShowSlider(!showSlider)
                                }}>
                                    {playing ? <FaPause color="white" className="" size={26} /> : <FaPlay color="white" className="" size={26} />}
                                </button>
                                <button className="flex justify-center overflow-hidden" onClick={() => handleRewind()}>
                                    <BsArrowCounterclockwise color="white" className='' size={26} />
                                </button>
                                <button className="flex justify-center overflow-hidden" onClick={() => handleForward()}>
                                    <BsArrowClockwise color="white" className='' size={26} />
                                </button>
                                <button className="flex justify-center overflow-hidden" onClick={() => handleStop()}>
                                    <FaStop color="white" className="" size={26} />
                                </button>
                                <button className="flex justify-center overflow-hidden" onClick={() => setMuted(!muted)}>
                                    {muted ? <FaVolumeMute color="white" className='' size={26} /> : <FaVolumeUp color="white" className='' size={26} />}
                                </button>
                                <div className="flex justify-center overflow-hidden items-center">
                                    <Slider
                                        vertical
                                        min={0}
                                        max={100}  // Set range to 0-100 for user interaction
                                        step={1}   // Step size for the slider
                                        value={volume * 100 || 80}  // Convert volume (0-1) to percentage, default 80% to prevent undefined errors
                                        onChange={handleVolumeChange}
                                        style={{ height: 150 }}
                                    />
                                </div>
                                {/* <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={(event) => setVolume(parseFloat(event.target.value))}
                                className="volume-slider mt-20 ml-4"
                                style={{ writingMode: "bt-lr" }}
                            /> */}
                                {/* <div className="flex flex-col items-center">
                                <label className="text-white mb-2">Seek</label>
                                <Slider
                                    vertical
                                    min={0}
                                    max={100}
                                    value={(playedSeconds / duration) * 100}  // Convert played seconds to percentage
                                    onChange={handleSeek}
                                    style={{ height: 300 }}  // Adjust the height for vertical seek
                                />
                                <span className="text-white mt-2">
                                    {Math.floor(playedSeconds)}s / {Math.floor(duration)}s
                                </span>
                            </div> */}
                            </div>
                            :
                            null
                    }
                </div>
                <div className={`px-[2rem] md:absolute bottom-0 left-0 right-0 z-[11] ${showSlider ? "" : "hidden"}`}>
                    <div className='flex justify-between items-center'>
                        <div className="block bebas-neue-regular text-white text-[4.8rem]">
                            Popular Exercises
                        </div>
                        <button className="open-sans-semibold text-[1.6rem] bg-[#FAFF00] p-2 rounded-[2.2rem] px-14 mr-[5rem]" onClick={() => setBuyNowPopup(true)}>
                            BUY NOW
                        </button>
                    </div>
                    <div className="md:h-[30rem] video-swiper">
                        {screenSize > 600 ?
                            <Swiper
                                effect={"coverflow"}
                                loop={true}
                                slidesPerView={screenSize < 1000 ? 3 : 4}
                                keyboard="true"
                                pagination={{ clickable: true }}

                            >
                                {SwiperImages?.map((items) => {
                                    return (
                                        <SwiperSlide>
                                            <VideoPlayerCardMini images={items.image} title={items.title} />
                                        </SwiperSlide>
                                    )
                                })}
                                <div className="my-5"></div>
                                <div className="swiper-pagination"></div>
                            </Swiper>
                            :
                            <Slider1 {...settings} className="relative overflow-hidden my-[3rem]">
                                {SwiperImages?.map((items) => {
                                    return (
                                        <div className="relative">
                                            <VideoPlayerCardMini images={items.image} title={items.title} />
                                        </div>
                                    )
                                })}
                            </Slider1>
                        }
                    </div>
                </div>
            </div>
            <BuyNowPopup show={buyNowPopup} onClose={() => setBuyNowPopup(false)} setBuyNowPopup={setBuyNowPopup} />
        </div>
    )
}

export default VideoPlayer1
