import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const fitcoinhistory = (payload) => ({
    type: actionTypes.FITCOIN_HISTORY_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const fitcoinhistorySuccess = (payload) => ({
    type: actionTypes.FITCOIN_HISTORY_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const fitcoinhistoryFailure = (payload) => ({
    type: actionTypes.FITCOIN_HISTORY_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const fitcoinhistoryReset = () => ({
    type: actionTypes.FITCOIN_HISTORY_RESET,
});

// Action creator for resetting all OTP generation reducer
export const fitcoinhistoryResetAll = () => ({
    type: actionTypes.FITCOIN_HISTORY_RESET_ALL,
}); 