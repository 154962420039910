import { actionTypes } from '../../types';

export const subscribe = (payload) => ({
    type: actionTypes.SUBSCRIBE_REQUEST,
    payload,
});

export const subscribeSuccess = (payload) => ({
    type: actionTypes.SUBSCRIBE_SUCCESS,
    payload,
});

export const subscribeFailure = (payload) => ({
    type: actionTypes.SUBSCRIBE_FAILURE,
    payload,
});

export const subscribeReset = () => ({
    type: actionTypes.SUBSCRIBE_RESET,
});

export const subscribeResetAll = () => ({
    type: actionTypes.SUBSCRIBE_RESET_ALL,
}); 