import { put, call, takeLatest } from 'redux-saga/effects';
import { UAT_PAY_STATUS_API_URL } from '../../constants';
import { AxiosPGService } from '../../services';
import { actionTypes } from '../../types';
import { checkPaymentStatusFailure, checkPaymentStatusSuccess } from '../../actions/paymentGatewayActions/checkPaymentStatusActions';

function* checkPaymentStatus({ payload }) {
    try {
        const response = yield call(AxiosPGService, {
            method: 'get',
            url: UAT_PAY_STATUS_API_URL + '/' + payload.PhonePeMerchantId + "/" + payload.PhonePeMerchantTransId,
           header: {
                headers: {
                    'Content-Type': 'application/json',
                    'X-VERIFY': payload.PhonePeTransCheckXVerify,
                    'X-MERCHANT-ID': payload.PhonePeMerchantId
                }
            },
        });
        console.log('====================================');
        console.log("GENERATE_OTP_SUCCESS status :", response.status);
        console.log("GENERATE_OTP_SUCCESS data :", response.data);
        console.log("GENERATE_OTP_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(checkPaymentStatusSuccess({
            status: response?.status,
            data: response?.data
        }))
    } catch (error) {
        console.log('====================================');
        console.log("GENERATE_OTP_FAILURE error :", error.response?.status);
        console.log("GENERATE_OTP_FAILURE message :", error.message);
        console.log("GENERATE_OTP_FAILURE error :", error);
        console.log('====================================');
        yield put(checkPaymentStatusFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* checkPaymentStatusSaga() {
    yield takeLatest(actionTypes.CHECK_PAYMENT_STATUS_REQUEST, checkPaymentStatus);
}

export default checkPaymentStatusSaga;