import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const refreshToken = (payload) => ({
    type: actionTypes.REFRESH_TOKEN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const refreshTokenSuccess = (payload) => ({
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const refreshTokenFailure = (payload) => ({
    type: actionTypes.REFRESH_TOKEN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const refreshTokenReset = () => ({
    type: actionTypes.REFRESH_TOKEN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const refreshTokenResetAll = () => ({
    type: actionTypes.REFRESH_TOKEN_RESET_ALL,
}); 