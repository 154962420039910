import React, { useEffect } from 'react'
import "./Membership2.css"
import { Membership2Img } from '../../../static/constants/imgConstants'
import Membership2Card from './Membership2Card'
import { useDispatch, useSelector } from 'react-redux'
import { subscription, subscriptionReset } from '../../../store/actions/SubscriptionManagementActions/subscriptionActions'

const Membership2 = () => {

    const dispatch = useDispatch();

    const subscriptionReducersData = useSelector((state) => state.subscriptionReducers?.data);
    const subscriptionReducersStatus = useSelector((state) => state.subscriptionReducers?.status);

    useEffect(() => {
        dispatch(subscription());
    }, [])

    useEffect(() => {
        if (subscriptionReducersStatus == 200) {
            dispatch(subscriptionReset());
        } else if (subscriptionReducersStatus) {
            dispatch(subscriptionReset());
        }
    }, [subscriptionReducersStatus])

    return (
        <div className='bg-[#1E1E1E] membership-2-section relative px-[4rem]'>
            <div className="container-lg flex flex-col xl:flex-row justify-between items-center xl:items-start">
                <div className="w-[60%] xl:w-[30%] flex flex-col justify-center items-center mt-[8.6rem]">
                    <div className="">
                        <img src={Membership2Img.membership2UserImg} alt="" className="h-[33rem] w-[33rem]" />
                    </div>
                    <div className="urbanist-semibold text-[4.8rem] text-white my-[3.8rem]">
                        Unlock More, Pay Less. Where Value Meets Fitness
                    </div>
                </div>
                <div className="w-[53%] [@media(min-width:1280px)]:w-[60%] lg:w-full xl:w-[53%] flex flex-col lg:flex-row justify-evenly lg:justify-between items-center overflow-visible my-0 xl:my-[10rem] h-full">
                    {subscriptionReducersData?.subscriptions?.filter((item) => (item.totalDuration).toFixed() == 6)?.map((items) => {
                        return (
                            <Membership2Card
                                type={items.subscriptionCategoryCode}
                                planDuration={"6 Month Plan"}
                                points={items.description}
                                planValue={items.totalAmount / items.totalDuration}
                                id={items.id}
                                items={items}
                            />
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default Membership2
