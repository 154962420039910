import React, { useEffect } from 'react'
import CommunityLanding from '../../components/common/CommunityLanding/CommunityLanding'
import FeaturedArticles from '../../components/common/FeaturedArticles/FeaturedArticles'
import CheckoutCategories from '../../components/common/CheckoutCategories/CheckoutCategories'
import Footer3 from '../../components/common/Footer3/Footer3'
import SpecialBlogCategory1 from '../../components/common/SpecialBlogCategory1/SpecialBlogCategory1'
import VideoSpecialCategory from '../../components/common/VideoSpecialCetegory/VideoSpecialCategory'
import { useDispatch, useSelector } from 'react-redux'
import { community } from '../../store/actions/StrapiServiceActions/communityAction'
import { communitySubCategories } from '../../store/actions/StrapiServiceActions/communitySubCategoriesAction'

function Community() {

    const dispatch = useDispatch()

    const communityReducerData = useSelector((state) => state.communityReducer?.data);
    const communitySubCategoriesReducerData = useSelector((state) => state.communitySubCategoriesReducer?.data);

    useEffect(() => {
        dispatch(community());
        dispatch(communitySubCategories())
    }, [])

    console.log("communitySubCategoriesReducerData :", communitySubCategoriesReducerData);

    return (
        <div className='bg-[#000000] md:bg-[#1E1E1E]'>
            <CommunityLanding
                CommunityLandingData={communityReducerData[0]}
            />
            <FeaturedArticles cards={communityReducerData} />
            <CheckoutCategories categories={communitySubCategoriesReducerData} />
            <SpecialBlogCategory1 data={communityReducerData?.length > 0 ? communityReducerData?.filter((item) => { return item?.sub_category?.slug === "workout_diaries" }) : []} />
            <SpecialBlogCategory1 data={communityReducerData?.length > 0 ? communityReducerData?.filter((item) => { return item?.sub_category?.slug === "body_and_mind_articles" }) : []} isRevers={true} />
            <VideoSpecialCategory data={communityReducerData?.length > 0 ? communityReducerData?.filter((item) => { return item?.sub_category?.slug === "motion_and_muscle" }) : []} />
            <Footer3 />
        </div>
    )
}

export default Community
