import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_customer_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { customerProfileFailure, customerProfileSuccess } from '../../actions/CustomerManagementServiceActions/customerProfileAction';
// import store from '../../configStore';

function* customerProfile({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_customer_management_service,
            endPoint: endpoints.CustomerProfile + `/${payload}`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("CUSTOMER_PROFILE_SUCCESS status :", response.status);
        console.log("CUSTOMER_PROFILE_SUCCESS data :", response.data);
        console.log("CUSTOMER_PROFILE_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            customerProfileSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("CUSTOMER_PROFILE_FAILURE status :", error?.response?.status);
        console.log("CUSTOMER_PROFILE_FAILURE data:", error?.response?.data);
        console.log("CUSTOMER_PROFILE_FAILURE error :", error);
        console.log('====================================');
        yield put(customerProfileFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* customerProfileSaga() {
    yield takeLatest(actionTypes.CUSTOMER_PROFILE_REQUEST, customerProfile);
}

export default customerProfileSaga;