import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiClientFeedback = (payload) => ({
    type: actionTypes.STRAPI_CLIENT_FEEDBACK_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiClientFeedbackSuccess = (payload) => ({
    type: actionTypes.STRAPI_CLIENT_FEEDBACK_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiClientFeedbackFailure = (payload) => ({
    type: actionTypes.STRAPI_CLIENT_FEEDBACK_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiClientFeedbackReset = () => ({
    type: actionTypes.STRAPI_CLIENT_FEEDBACK_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiClientFeedbackResetAll = () => ({
    type: actionTypes.STRAPI_CLIENT_FEEDBACK_RESET_ALL,
}); 