import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const Strapifaqs = (payload) => ({
    type: actionTypes.STRAPI_FAQS_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const StrapifaqsSuccess = (payload) => ({
    type: actionTypes.STRAPI_FAQS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const StrapifaqsFailure = (payload) => ({
    type: actionTypes.STRAPI_FAQS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const StrapifaqsReset = () => ({
    type: actionTypes.STRAPI_FAQS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const StrapifaqsResetAll = () => ({
    type: actionTypes.STRAPI_FAQS_RESET_ALL,
}); 