import React from 'react'
import MapComponent from '../../utils/MapComponent/MapComponent'
import "./LocationMap.css"

const LocationMap = ({geoLocation, venueName}) => {
    return (
        <div className='container-lg px-[4rem]'>
            <MapComponent geoLocation={geoLocation} venueName={venueName} />
        </div>
    )
}

export default LocationMap