import { actionTypes } from '../../types';

export const customerProfile = (payload) => ({
    type: actionTypes.CUSTOMER_PROFILE_REQUEST,
    payload,
});

export const customerProfileSuccess = (payload) => ({
    type: actionTypes.CUSTOMER_PROFILE_SUCCESS,
    payload,
});

export const customerProfileFailure = (payload) => ({
    type: actionTypes.CUSTOMER_PROFILE_FAILURE,
    payload,
});

export const customerProfileReset = () => ({
    type: actionTypes.CUSTOMER_PROFILE_RESET,
});

export const customerProfileResetAll = () => ({
    type: actionTypes.CUSTOMER_PROFILE_RESET_ALL,
}); 