import React from 'react'
import { TiSocialFacebook } from "react-icons/ti";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { TiSocialTwitter } from "react-icons/ti";
import { GrPinterest } from "react-icons/gr";

function Footer1() {
    return (
        <div className='flex justify-center items-center w-full sm:w-[85%] border-b border-white py-10'>
            <a target='_blank' href="https://www.facebook.com/people/Fitzone/61561375006743/" className="">
                <TiSocialFacebook color="rgba(255, 255, 255, 0.7)" size={36} />
            </a>
            <a target='_blank' href="https://www.instagram.com/fitzone.life/" className="">
                <BiLogoInstagramAlt color="rgba(255, 255, 255, 0.7)" size={32} className='mx-5' />
            </a>
            <a target='_blank' href="https://x.com/fitzone_life/" className="">
                <TiSocialTwitter color="rgba(255, 255, 255, 0.7)" size={36} />
            </a>
            <a target='_blank' href="https://in.pinterest.com/fitzonetechnologies/" className="">
                <GrPinterest  color="rgba(255, 255, 255, 0.7)" size={26} className='mx-5' />
            </a>
        </div>
    )
}

export default Footer1