import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_user_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { setUserId } from '../../actions/utilsActions/utilsActions';
import { generateOtpSuccess, generateOtpFailure } from '../../actions/authActions/generateOTPActions';

function* generateOtp({ payload }) {
    try {
        // Make an API call to generate OTP
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_user_management_service,
            endPoint: endpoints.GenerateOTP,
            data: payload,
            isAuth: false,
        });

        console.log('====================================');
        console.log("GENERATE_OTP_SUCCESS status :", response.status);
        console.log("GENERATE_OTP_SUCCESS data :", response.data);
        console.log("GENERATE_OTP_SUCCESS message :", response.data.message);
        console.log('====================================');
        // Dispatch success action with the response data
        yield put(generateOtpSuccess({
            status: response?.status,
            data: response?.data
        })
        )
        yield put(setUserId(response?.data?.userID));
    } catch (error) {
        console.log('====================================');
        console.log("GENERATE_OTP_FAILURE error :", error.response?.status);
        console.log("GENERATE_OTP_FAILURE message :", error.response?.data.statusmessage);
        console.log("GENERATE_OTP_FAILURE error :", error);
        console.log('====================================');
        yield put(generateOtpFailure({
            status: error.response?.status,
            message: error.response?.data.statusmessage
        }));
    }
}

function* generateOtpSaga() {
    yield takeLatest(actionTypes.GENERATE_OTP_REQUEST, generateOtp);
}

export default generateOtpSaga;