import { actionTypes } from '../../types';

export const getVenueDetails = (payload) => ({
    type: actionTypes.GET_VENUE_DETAILS_REQUEST,
    payload,
});

export const getVenueDetailsSuccess = (payload) => ({
    type: actionTypes.GET_VENUE_DETAILS_SUCCESS,
    payload,
});

export const getVenueDetailsFailure = (payload) => ({
    type: actionTypes.GET_VENUE_DETAILS_FAILURE,
    payload,
});

export const getVenueDetailsReset = () => ({
    type: actionTypes.GET_VENUE_DETAILS_RESET,
});

export const getVenueDetailsResetAll = () => ({
    type: actionTypes.GET_VENUE_DETAILS_RESET_ALL,
}); 