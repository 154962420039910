import { actionTypes } from '../../types';

export const venueStaff = (payload) => ({
    type: actionTypes.VENUE_STAFF_REQUEST,
    payload,
});

export const venueStaffSuccess = (payload) => ({
    type: actionTypes.VENUE_STAFF_SUCCESS,
    payload,
});

export const venueStaffFailure = (payload) => ({
    type: actionTypes.VENUE_STAFF_FAILURE,
    payload,
});

export const venueStaffReset = () => ({
    type: actionTypes.VENUE_STAFF_RESET,
});

export const venueStaffResetAll = () => ({
    type: actionTypes.VENUE_STAFF_RESET_ALL,
}); 