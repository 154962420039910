import React from 'react'
import { NearbyGymCardImg } from '../../../static/constants/imgConstants'
import { base_url_strapi_service } from '../../../store/constants';

const BlogAndNewsCard = ({ image, title, type, details }) => {

    console.log("image url", image);

    return (
        <div className='w-[45rem] h-full'>
            <div className="">
                <div className="relative">
                    <img src={`${base_url_strapi_service}${image}`} alt="" className="w-full h-full" />
                </div>
                <div className="h-[21rem]">
                    <div className="bg-[#FAFF00] rounded-[2.5rem] w-full text-start p-3 orbitron-bold text-[1.6rem] mt-10 uppercase">
                        {title}
                    </div>
                    <p className="outfit-medium text-[#FFFFFFCC]/80 text-[2rem] mt-10 overflow-hidden">
                        {details}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogAndNewsCard