import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const communitySubCategories = (payload) => ({
    type: actionTypes.COMMUNITY_SUB_CATEGORIES_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const communitySubCategoriesSuccess = (payload) => ({
    type: actionTypes.COMMUNITY_SUB_CATEGORIES_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const communitySubCategoriesFailure = (payload) => ({
    type: actionTypes.COMMUNITY_SUB_CATEGORIES_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const communitySubCategoriesReset = () => ({
    type: actionTypes.COMMUNITY_SUB_CATEGORIES_RESET,
});

// Action creator for resetting all OTP generation reducer
export const communitySubCategoriesResetAll = () => ({
    type: actionTypes.COMMUNITY_SUB_CATEGORIES_RESET_ALL,
}); 