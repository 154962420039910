import React from 'react'
import { setUpdateProfile } from '../../../store/actions/utilsActions/utilsActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UserProfilePopup3 = ({ show, onClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            // setUserProfilePopup2(false);
        }
    };

    return show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20" onClick={(e) => handleOutsideClick(e)}>
            <div className="bg-[#343434] rounded-[3.5rem] text-white w-[50rem] h-[30rem] items-center px-[6rem]">
                <h2 className="text-start sm:text-center urbanist-bold text-[3.6rem] my-[4rem]">Complete your profile</h2>
                <div className="text-start sm:text-center urbanist-bold text-[1.8rem] my-2">A complete profile helps us serve you better.</div>
                <div className="text-start sm:text-center urbanist-bold text-[1.8rem] my-2">Please update your details now</div>
                <div className="flex justify-between items-center mt-[3rem] gap-8">
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => { navigate("/profile", { state: { data: "UserProfilePopup" } }) }}
                    >
                        Update
                    </button>
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => { dispatch(setUpdateProfile(false)); /* navigate(`/fit-center/${package_type}/${id}`); */ onClose() }}
                    >
                        Skip
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserProfilePopup3