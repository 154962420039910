import React, { useState } from 'react';
import { IoChevronDownCircle } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa6";

const FAQSectionItem = ({ faqSectionData, index, isPackageDetailsPageActive }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    // console.log("faqSectionData :", faqSectionData);


    return (
        <div className='w-full mt-[2rem]'>
            <div className="m-2 space-y-2">
                <div
                    className={`group flex flex-col bg-[#4F4F4F] ${isOpen ? 'border focus:border-[#FAFF00]' : ''} rounded-[1.4rem] px-[2rem] sm:px-[3.8rem] py-[1rem] ${isPackageDetailsPageActive ? "" : "py-[2rem] sm:py-[3.5rem]"} text-white rounded-[1.4rem]`}
                    tabIndex={`${index + 1}`}
                    onClick={handleToggle}
                    onBlur={() => setIsOpen(false)}
                >
                    <div className="flex cursor-pointer items-center justify-between">
                        <span className={`dm-sans-semibold ${isPackageDetailsPageActive ? "text-[1.6rem]" : "text-[2.2rem]"} `}>{faqSectionData?.Question}</span>
                        <IoChevronDownCircle color={"#FAFF00"} className={`text-[4rem] transition-all duration-500 ${isOpen ? 'block' : 'hidden'}`} />
                        <FaChevronRight color={"white"} className={`text-[2.2rem] transition-all duration-500 ${isOpen ? 'hidden' : 'block'}`} />
                    </div>
                    <div
                        className={`h-auto md:w-[80%] dm-sans-regular ${isPackageDetailsPageActive ? "text-[1.2rem]" : "text-[1.8rem]"} items-center overflow-hidden transition-all ${isOpen ? 'max-h-screen opacity-100 my-[0.6rem] duration-1000' : 'max-h-0 opacity-0 invisible'}`}
                    >
                        {/* {faqSectionData?.answer?.map((item, idx) => (
                            <div key={idx}>{item}</div>
                        ))} */}
                        {faqSectionData?.Answer}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQSectionItem;
