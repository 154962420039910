import { actionTypes } from '../../types';

export const SubscriptionRecord = (payload) => ({
    type: actionTypes.SUBSCRIPTION_RECORD_REQUEST,
    payload,
});

export const SubscriptionRecordSuccess = (payload) => ({
    type: actionTypes.SUBSCRIPTION_RECORD_SUCCESS,
    payload,
});

export const SubscriptionRecordFailure = (payload) => ({
    type: actionTypes.SUBSCRIPTION_RECORD_FAILURE,
    payload,
});

export const SubscriptionRecordReset = () => ({
    type: actionTypes.SUBSCRIPTION_RECORD_RESET,
});

export const SubscriptionRecordResetAll = () => ({
    type: actionTypes.SUBSCRIPTION_RECORD_RESET_ALL,
}); 