import React from 'react'

const SupportNav = ({ setActiveTab }) => {
    return (
        <div className='flex justify-between items-center w-full sm:w-[80%] h-full'>
            <div
                className="bg-[#103E63] hover:bg-[#103E63]/50 cursor-pointer h-[18.5rem] rounded-[0.8rem] mr-[3rem] md:flex-1 flex flex-col justify-end items-start px-[2.8rem] py-[2.4rem] roboto-medium text-white text-[2rem]"
                onClick={() => setActiveTab("Fitness")}
            >
                <div className="-rotate-90 md:rotate-0">
                    Fitness
                </div>
            </div>
            <div
                className="bg-[#2A4037] hover:bg-[#2A4037]/50 cursor-pointer h-[18.5rem] rounded-[0.8rem] mr-[3rem] md:flex-1 flex flex-col justify-end items-start px-[2.8rem] py-[2.4rem] roboto-medium text-white text-[2rem]"
                onClick={() => setActiveTab("Center")}
            >
                <div className="-rotate-90 md:rotate-0">
                    Fit Centres
                </div>
            </div>
            <div
                className="bg-[#490517] hover:bg-[#490517]/50 cursor-pointer h-[18.5rem] rounded-[0.8rem] mr-[3rem] md:flex-1 flex flex-col justify-end items-start px-[2.8rem] py-[2.4rem] roboto-medium text-white text-[2rem]"
                onClick={() => setActiveTab("MYACCOUNT")}
            >
                <div className="-rotate-90 md:rotate-0">
                    My Account
                </div>
            </div>
            <div
                className="bg-[#695D44] hover:bg-[#695D44]/50 cursor-pointer h-[18.5rem] rounded-[0.8rem] mr-[3rem] md:flex-1 flex flex-col justify-end items-start px-[2.8rem] py-[2.4rem] roboto-medium text-white text-[2rem]"
                onClick={() => setActiveTab("HomeFitness")}
            >
                <div className="-rotate-90 md:rotate-0">
                    Home Fitness
                </div>
            </div>
            {/* <div
                className="bg-[#7BFFF780]/50 hover:bg-[#7BFFF780]/30 cursor-pointer h-[18.5rem] rounded-[0.8rem] mr-[3rem] md:flex-1 flex flex-col justify-end items-start px-[2.8rem] py-[2.4rem] roboto-medium text-white text-[2rem]"
                onClick={() => setActiveTab("EStore")}
            >
                <div className="-rotate-90 md:rotate-0">
                    Estore
                </div>
            </div> */}
        </div>
    )
}

export default SupportNav