import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeFitnessImg } from '../../../static/constants/imgConstants';
import Slider from 'react-slick';
import PortraitVideoCard from './PortraitVideoCard';
import BuyNowPopup from '../BuyNowPopup/BuyNowPopup';


function HomeFitness() {
    let sliderRef = useRef(null);

    const [slideIndex, setSlideIndex] = useState(0);
    const [buyNowPopup, setBuyNowPopup] = useState(false);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        speed: 500,
        adaptiveHeight: true,
        variableWidth: true,
        autoplay: true,
        focusOnSelect: true,
        draggable: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                }
            },
        ],
        afterChange: () => { },
        beforeChange: (current, next) => setSlideIndex(next),
        onchange: (current) => { setSlideIndex(current) }
    };

    const fitnessCardData = [
        {
            title: "",
            video: "https://youtube.com/shorts/1H6ybczMrZk?si=rxU5Kkk0chkm-wsB",
            thumbnail: HomeFitnessImg.fitnessImg1
        },
        {
            title: "",
            video: "https://youtube.com/shorts/3hSsLZBRUAY?si=4ABzUmwoZ6BN8Aqb",
            thumbnail: HomeFitnessImg.fitnessImg2
        },
        {
            title: "",
            video: "https://youtube.com/shorts/AIixCjC8T8w?si=JTQJffzHEXPIkICj",
            thumbnail: HomeFitnessImg.fitnessImg3
        },
        {
            title: "",
            video: "https://youtube.com/shorts/ySO7xFllU00?si=55R7356-WJ9wgh4w",
            thumbnail: HomeFitnessImg.fitnessImg4
        },
        {
            title: "",
            video: "https://youtube.com/shorts/9E06Nxeq-84?si=fqP5V1iZIYMT-JGs",
            thumbnail: HomeFitnessImg.fitnessImg5
        },
        {
            title: "",
            video: "https://youtube.com/shorts/PQF4QO0hXxk?si=fNIPBR68EthdesQZ",
            thumbnail: HomeFitnessImg.fitnessImg1
        },
        {
            title: "",
            video: "https://youtube.com/shorts/nQQ041VkhgY?si=KN5l6hoXsOZbGC4W",
            thumbnail: HomeFitnessImg.fitnessImg2
        },
        {
            title: "",
            video: "https://youtube.com/shorts/PQF4QO0hXxk?si=fNIPBR68EthdesQZ",
            thumbnail: HomeFitnessImg.fitnessImg3
        },
        {
            title: "",
            video: "https://youtube.com/shorts/nQQ041VkhgY?si=KN5l6hoXsOZbGC4W",
            thumbnail: HomeFitnessImg.fitnessImg4
        },
        {
            title: "",
            video: "https://youtube.com/shorts/PQF4QO0hXxk?si=fNIPBR68EthdesQZ",
            thumbnail: HomeFitnessImg.fitnessImg5
        },
    ]

    return (
        <div className='h-[85rem] flex flex-col justify-center items-center mt-[10rem] md:mt-0'>
            <div className="text-white text-center montserrat-bold text-[6.5rem] my-[1rem] overflow-hidden">
                Home Fitness
            </div>
            <div className="slider-container min-h-max pt-[1rem] pb-[5rem] overflow-hidden">
                <Slider {...settings} ref={slider => { sliderRef = slider; }}>
                    {fitnessCardData.map((item, i) => {
                        return (
                            <PortraitVideoCard item={item} i={i} activeSlideIndex={slideIndex} length={fitnessCardData?.length} setBuyNowPopup={setBuyNowPopup} />
                        )
                    })
                    }
                </Slider>
            </div>
            <BuyNowPopup show={buyNowPopup} onClose={() => setBuyNowPopup(false)} setBuyNowPopup={setBuyNowPopup} />
        </div>
    )
}

export default HomeFitness
