import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateOtp, generateOtpReset } from '../../../store/actions/authActions/generateOTPActions';
import { useToast } from '../../../hooks/useToast';
import { setEmail, setResendOtpTime, setVerifiedPhoneNo } from '../../../store/actions/utilsActions/utilsActions';
import { Oval } from 'react-loader-spinner';

const EnterEmailSec = ({ setEmailPhone, emailPhone, setIsOTPSecOpen }) => {

    const dispatch = useDispatch();

    const Toast = useToast()

    const GenerateOtpReducerStatus = useSelector((state) => state.generateOtpReducer?.status);
    const GenerateOtpReducerMessage = useSelector((state) => state.generateOtpReducer?.message);
    const GenerateOtpReducerLoading = useSelector((state) => state.generateOtpReducer?.loading);

    useEffect(() => {
        if (GenerateOtpReducerStatus == 200) {
            dispatch(setResendOtpTime(null))
            dispatch(generateOtpReset());
            setIsOTPSecOpen(true);
            Toast(GenerateOtpReducerMessage)
        } else if (GenerateOtpReducerStatus) {
            Toast(GenerateOtpReducerMessage)
            dispatch(generateOtpReset());
        }
    }, [GenerateOtpReducerStatus])


    return (
        <div>
            <label className="text-start text-white lg:text-black" htmlFor="emailOrMobile">
                Email/Mobile no.
            </label>
            <input
                type="text"
                id="emailOrMobile"
                className="text-[2.4rem] text-white lg:text-black outline-none rounded-[1rem] bg-black lg:bg-white w-full h-[7rem] border-[.1rem] border-[#D9D9D9] px-[2rem] mt-[.8rem]"
                onChange={(e) => {

                    setEmailPhone(e.target.value)
                }}
            />
            <button
                className="w-full h-[5.5rem] bg-[#20DC49] lg:bg-white rounded-[1rem] text-center border-[.1rem] border-[#5A5A5A] lg:hover:bg-[#FFFFFF] mt-[3.8rem]"
                onClick={() => {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const phonePattern = /^(?!0000000000)\d{10}$/;
                    if (emailPattern.test(emailPhone)) {
                        dispatch(setEmail(emailPhone));
                        dispatch(
                            generateOtp({
                                email: emailPhone,
                                otpGenAttempt: 1
                            })
                        )
                        dispatch(setVerifiedPhoneNo({
                            number: "",
                            isVerified: false
                        }));
                    } else if (phonePattern.test(emailPhone)) {
                        dispatch(setVerifiedPhoneNo({
                            number: emailPhone,
                            isVerified: false
                        }));
                        dispatch(
                            generateOtp({
                                mobileNumber: {
                                    countryCode: "+91",
                                    number: emailPhone
                                },
                                otpGenAttempt: 1
                            })
                        )
                        dispatch(setEmail(""));
                    } else {
                        Toast("Please enter a valid email or mobile number");
                        return;
                    };
                    // dispatch(generateOtp({ email: emailPhone, "otpGenAttempt": 1 }))
                    // setIsOTPSecOpen(true)
                }}
                disabled={!emailPhone}
            >
                {
                    GenerateOtpReducerLoading ?
                        <div className="flex justify-center items-center w-full">
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#000000"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                        :
                        "Send OTP"}
            </button>
        </div>
    )
}

export default EnterEmailSec