import { actionTypes } from '../../types';

export const downloadOrderInvoicePdf = (payload) => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_REQUEST,
    payload,
});

export const downloadOrderInvoicePdfSuccess = (payload) => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS,
    payload,
});

export const downloadOrderInvoicePdfFailure = (payload) => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_FAILURE,
    payload,
});

export const downloadOrderInvoicePdfReset = () => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_RESET,
});

export const downloadOrderInvoicePdfResetAll = () => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE_PDF_RESET_ALL,
}); 