import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getReferralCode = (payload) => ({
    type: actionTypes.GET_REFERRAL_CODE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getReferralCodeSuccess = (payload) => ({
    type: actionTypes.GET_REFERRAL_CODE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getReferralCodeFailure = (payload) => ({
    type: actionTypes.GET_REFERRAL_CODE_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getReferralCodeReset = () => ({
    type: actionTypes.GET_REFERRAL_CODE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getReferralCodeResetAll = () => ({
    type: actionTypes.GET_REFERRAL_CODE_RESET_ALL,
}); 