import { actionTypes } from '../../types';

export const searchByLocation = (payload) => ({
    type: actionTypes.SEARCH_BY_LOCATION_REQUEST,
    payload,
});

export const searchByLocationSuccess = (payload) => ({
    type: actionTypes.SEARCH_BY_LOCATION_SUCCESS,
    payload,
});

export const searchByLocationFailure = (payload) => ({
    type: actionTypes.SEARCH_BY_LOCATION_FAILURE,
    payload,
});

export const searchByLocationReset = () => ({
    type: actionTypes.SEARCH_BY_LOCATION_RESET,
});

export const searchByLocationResetAll = () => ({
    type: actionTypes.SEARCH_BY_LOCATION_RESET_ALL,
}); 