const envType = "DEV"; // Use "PRODUCTION" for production url and use "DEV" for development url.

export const base_url_user_management_service = `https://user-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // User Management Service Base URL
export const base_url_customer_management_service = `https://customer-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Customer Management Service Base URL
export const base_url_subscription_management_service = `https://subscription-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Subscription Management Service Base URL
export const base_url_gx_class_management_service = `https://gx-class-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Gx_class Management Service Base URL
export const base_url_file_management_service = `https://file-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // File Management Service Base URL
export const base_url_fitness_assessement_service = `https://fitness-assessement-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Fitness Assessement Service Base URL
export const base_url_venue_management_service = `https://venue-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Venue Management Service Base URL
export const base_url_order_management_service = `https://order-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Order Management Service Base URL
export const base_url_reward_management_service = `https://reward-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Reward Management Service Base URL
export const base_url_podcast_management_service = `https://podcast-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Podcast Management Service Base URL
export const base_url_attendance_management_service = `https://attendance-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Attendance Management Service Base URL
export const base_url_leadgen_service = `https://leadgen-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Lead gen Service Base URL
export const base_url_helpdesk_service = `https://helpdesk-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // helpdesk Service Base URL
export const base_url_venue_staff_management_service = `https://venue-staff-management-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}-app.azurewebsites.net/` // Venue Staff Management Service Base URL
export const base_url_user_feedback_management_service = `https://user-feedback-service-fitzone-${envType == "PRODUCTION" ? 'prod' : 'dev'}.azurewebsites.net/` // User Feedback Management Service Base URL

export const base_url_dot_cms_service = "https://192.168.29.242:8443/" // Dot CMS Service Base URL
export const UAT_PAY_API_URL = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
export const UAT_PAY_STATUS_API_URL = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status";
export const GOOGLE_API_SERVICE_URL = "https://maps.googleapis.com/";
export const base_url_strapi_service = "https://fitzonecms.southindia.cloudapp.azure.com/"; // Strapi Service base URL

export const endpoints = {
    // API endpoints here
    // User Management Service
    GenerateOTP: "OTP/GenerateOTP", // Generate OTP
    ValidateOTP: "OTP/ValidateOTP", // Validate OTP
    RefreshToken: "Token/refresh", // Refresh access Token
    Subscription: "Subscription/search", // Subscription
    SearchByLocation: "VenueBaseProfile/SearchByLocation", // SearchByLocation
    ContactUs: "ContactUs/add",
    Geocode: "maps/api/geocode/json",
    //Order Management Service
    emailInvoice: "order/invoice/email/sendtocustomer", // Email Invoice
    OrderCreate: "order/create", // Create Order
    downloadOrderInvoicePdf: "order/invoice/downloadpdf",
    CustomerProfile: "CustomerProfile/allbyuser",
    UploadProfilePic: "fileHandler/Upload",
    UpdateProfile: "CustomerProfile/update",
    SubscriptionRecord: "/SubscriptionRecord/Customer/all",
    CMSproducts: "api/v1/page/render/store/products/the-power-of-habit",
    downloadPdf: "order/invoice/downloadpdf",
    AllOrders: "order/search",
    CreateTicket: "helpdesk/CreateTicket",
    OrderTransactionStatus: "order/transactionstatus",
    StrapiToken: "auth/local",
    StrapiArticles: "articles",
    GetFitnessAssessment: "FitnessAssessment/GetFitnessAssessment",
    GetTicketByUserId: "helpdesk/GetTicketByUserId",
    PauseSubscription: "SubscriptionRecord/PauseSubscription",
    UnPauseSubscription: "SubscriptionRecord/UnPauseSubscription",
    SubscriptionUpgradeSummary: "SubscriptionRecord/SubscriptionUpgradeSummary",
    VenueStaff: "VenueStaff/search",
    faqs: "faqs",
    gxsession: "gxsession/search",
    getReferralCode: "User/getReferalCode",
    referFriend: "OTP/Refer",
    fitcoinhistory: "reward/fitcoinhistory",
    subscribe: "ContactUs/Subscribe",
    getLeaderBoardFile: "filehandler/GetLeaderBoardFile",
    trainerRating: "feedback/GetPersonalTrainerFeedback",
    community: "articles",
    communitySubCategories: "sub-categories",
    getVenueDetails: "VenueBaseProfile/GetVenueDetails",
    offerSearch: "Offer/GetCouponBannerOffer",
    subscriptionOffers: "Subscription/GetPackageBannerOffer",
    getSubscriptionCategoryName: "Subscription/GetSubscriptionCategoryName",
    fitcoinBalance: "reward/fitcoinbalance",
    RewardConfigAll: "rewardconfig/all",
    filehandler: "filehandler/fetch/type",
}
