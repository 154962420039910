import React, { useState } from 'react'
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { DayPicker } from 'react-day-picker';
import "react-day-picker/dist/style.css";
import { format, addDays } from 'date-fns';
import { useToast } from '../../../hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { pauseSubscription } from '../../../store/actions/SubscriptionManagementActions/pauseSubscriptionAction';
import moment from 'moment';

const SubscriptionsPauseModel = ({ show, onClose, setPauseModel, pauseDays, selectedSubscriptionId }) => {

    const Toast = useToast();
    const dispatch = useDispatch()

    const utilReducers = useSelector((state) => state.utilReducers);

    const [showCalendar, setShowCalendar] = useState(false);
    const [showCalendarEndDate, setShowCalendarEndDate] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();

    const today = new Date();
    const isPastDate = date => date < new Date(today.setHours(0, 0, 0, 0) - 1);

    const maxEndDate = selectedStartDate ? addDays(selectedStartDate, pauseDays) : null;

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setPauseModel(false);
        }
    };

    console.log("pauseDays :", pauseDays);

    return show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20" onClick={(e) => handleOutsideClick(e)}>
            <div className={`bg-[#343434] rounded-[3.5rem] text-white w-[90rem] h-[100rem] ${showCalendar || showCalendarEndDate ? "h-[100rem] md:h-[66rem]" : "md:h-[40rem] h-[60rem]"}  items-center px-[6rem]`}>
                <h2 className="text-center urbanist-bold text-[3.6rem] mt-[4rem]">Select your number of days to pause</h2>
                <div className="text-center urbanist-bold text-[1.8rem] my-2">Maximum number of days to pause your membership: {pauseDays} days</div>
                <div className={`flex flex-col md:flex-row justify-between items-center md:items-start gap-8 md:gap-0 ${showCalendar || showCalendarEndDate ? "md:h-[44rem]" : "md:h-[10rem] mt-5"}`}>
                    <div className="w-[50rem] sm:w-[45rem] md:w-[40rem] lg:w-[36rem] mr-5 z-[5]">
                        <div
                            onClick={() => { setShowCalendar(!showCalendar); setShowCalendarEndDate(false) }}
                            className="bg-[#000000]/40 text-white w-full p-4 rounded-[2.5rem] urbanist-semibold text-[1.4rem] outline-none border border-white flex justify-between items-center"
                        >
                            <div className="mx-2">
                                {selectedStartDate ? format(selectedStartDate, 'PP') : "Pick Start Date"}
                            </div>
                            {showCalendar ?
                                <GoChevronUp size={26} className='mx-3' />
                                :
                                <GoChevronDown size={26} className='mx-3' />
                            }
                        </div>
                        {showCalendar ?
                            <DayPicker
                                mode="single"
                                selected={selectedStartDate}
                                onSelect={(value) => { setShowCalendar(false); setSelectedStartDate(value); setSelectedEndDate() }}
                                disabled={isPastDate}
                                className='bg-black text-white urbanist-medium text-[1.6rem] rounded-[1.3rem] date-picker px-2'
                                modifiersStyles={{
                                    selected: {
                                        backgroundColor: "white",
                                        color: "black",
                                    }
                                }}
                            />
                            :
                            null
                        }
                    </div>
                    <div className="w-[50rem] sm:w-[45rem] md:w-[40rem] lg:w-[36rem] mr-5 z-[5]">
                        <div
                            onClick={() => {
                                if (selectedStartDate) {
                                    setShowCalendarEndDate(!showCalendarEndDate);
                                    setShowCalendar(false);
                                } else {
                                    Toast("Pick Start Date first", "error")
                                }
                            }
                            }
                            className="bg-[#000000]/40 text-white w-full p-4 rounded-[2.5rem] urbanist-semibold text-[1.4rem] outline-none border border-white flex justify-between items-center"
                        >
                            <div className="mx-2">
                                {selectedEndDate ? format(selectedEndDate, 'PP') : "Pick End Date"}
                            </div>
                            {showCalendarEndDate ?
                                <GoChevronUp size={26} className='mx-3' />
                                :
                                <GoChevronDown size={26} className='mx-3' />
                            }
                        </div>
                        {showCalendarEndDate ?
                            <DayPicker
                                mode="single"
                                selected={selectedEndDate}
                                onSelect={(value) => { setShowCalendarEndDate(false); setSelectedEndDate(value) }}
                                disabled={date => isPastDate(date) || (maxEndDate && date > maxEndDate) || (selectedStartDate && date < selectedStartDate)}
                                className='bg-black text-white urbanist-medium text-[1.6rem] rounded-[1.3rem] date-picker px-2'
                                modifiersStyles={{
                                    selected: {
                                        backgroundColor: "white",
                                        color: "black",
                                    }
                                }}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="flex justify-around items-center mt-[3rem]">
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => {
                            dispatch(pauseSubscription({ subscriptionId: selectedSubscriptionId, userId: utilReducers?.userId, startDate: moment(selectedStartDate).format("YYYY-MM-DD"), endDate: moment(selectedEndDate).format("YYYY-MM-DD") }));
                            setSelectedStartDate();
                            setSelectedEndDate();
                            onClose()
                        }}
                    >
                        Submit
                    </button>
                    <button
                        className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[7.5rem] py-[1.4rem]"
                        onClick={() => {
                            setSelectedEndDate();
                            setSelectedStartDate();
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionsPauseModel;
