import React from "react";
import { AboutUsImg } from '../../../static/constants/imgConstants';

function AboutUsGridSec1() {
    return (
        <div className="about-us-grid-sec1 h-[74rem] lg:bg-custom-gradient px-[4rem]">
            <div className="container-lg flex flex-col lg:flex-row h-full">
                <div className="flex flex-col justify-center items-start h-full w-full lg:w-[64%]">
                    <b className="text-[4rem] md:text-[4rem] urbanist-bold">
                        Our Partner Gyms
                    </b>
                    <div className="text-[1.7rem] md:text-[1.7rem] text-[#DEDEDE] urbanist-medium lg:pr-[35%]">
                        <p className="my-[.5rem] leading-[2.6rem]">
                            Expanding your fitness journey has never been easier with our network of partner gyms.
                            For our members who travel or live farther from our locations, our partner gyms offer seamless
                            access to top-quality facilities and services, ensuring you stay on track no matter where you are.
                        </p>
                        <p className="my-[.5rem] leading-[2.6rem]">
                            For our partner gyms, joining our network means tapping into a wider reach and gaining exposure
                            to new potential members. Benefit from our established brand, innovative programs, and marketing
                            support, all while contributing to a growing community dedicated to health and wellness. Together,
                            we create a robust network that supports fitness enthusiasts everywhere and elevates the fitness
                            experience for all.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-center w-full lg:w-[36%] px-[3rem] lg:px-[6rem] bg-[#76CEE4] h-full lg:min-h-max">
                    <div className="relative pt-[6rem]">
                        <div className="absolute w-[10rem] opacity-40 top-[1.4rem]">
                            <img
                                className="w-full h-full"
                                alt=""
                                src={AboutUsImg.AboutUsImg1}
                            />
                        </div>
                        <div className="text-[3.2rem] lg:text-[3.2rem] urbanist-bold text-left">
                            Partnering with Fit.Zone has
                            been a huge boost for us.
                            Their support and innovative ideas have transformed our gym, leading to happier members and better results. Their expertise and resources have helped us elevate our services and inspire our team. It's been a game-changer for our success!
                        </div>
                        <div className="text-[2rem] urbanist-semibold">
                            Rajesh Srivastava - XYZ Gym
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsGridSec1;
