import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../hooks/useToast';
import OrderInvoiceCard from '../OrderInvoiceCard/OrderInvoiceCard';
import { Oval } from 'react-loader-spinner';
import { allOrders, allOrdersReset, allOrdersResetAll } from '../../../store/actions/orderManagementActions/allOrdersAction';

const OrderInvoice = () => {
  const dispatch = useDispatch();
  const Toast = useToast();
  const hasShownProfileToast = useRef(false);

  const utilReducers = useSelector((state) => state.utilReducers);
  const allOrdersReducerData = useSelector((state) => state.allOrdersReducer.data);
  const allOrdersReducerStatus = useSelector((state) => state.allOrdersReducer.status);
  const allOrdersReducerLoading = useSelector((state) => state.allOrdersReducer.loading);
  const allOrdersReducerMessage = useSelector((state) => state.allOrdersReducer.message);
  const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);

  useEffect(() => {
    const customerProfile = CustomerProfileReducerData?.[0];
    dispatch(allOrdersResetAll());
    dispatch(allOrders({ userId: utilReducers?.userId }));

    if ((!customerProfile?.mobileNumber?.number || !customerProfile?.email) && !hasShownProfileToast.current) {
      alert("Update your profile first!");
      console.log("function is running continue");
      hasShownProfileToast.current = true;
      return;
    }


  }, [CustomerProfileReducerData, utilReducers?.userId, dispatch]);

  useEffect(() => {
    if (allOrdersReducerStatus === 200) {
      dispatch(allOrdersReset());
    } else if (allOrdersReducerStatus) {
      Toast(allOrdersReducerMessage ? allOrdersReducerMessage : "No Orders available this time! Please try again later");
      dispatch(allOrdersReset());
    }
  }, [allOrdersReducerStatus, allOrdersReducerMessage, Toast, dispatch]);

  const sortedOrders = allOrdersReducerData?.orders?.slice().sort((a, b) => {
    return new Date(b?.orderDate) - new Date(a?.orderDate);
  });

  console.log("CustomerProfileReducerData:", CustomerProfileReducerData);

  return (
    <div className='bg-[#1E1E1E] no-scrollbar'>
      <div className="container-lg px-[4rem] flex flex-col justify-start items-start">
        <div className="w-full bg-black text-[#ffffff] mt-[10rem] rounded-[2rem]">
          <h1 className="text-[2rem] md:text-[2.5rem] text-start px-[4rem] p-4">All Orders</h1>
        </div>
        <div className="w-full">
          {!allOrdersReducerLoading ? (
            sortedOrders?.length > 0 ? (
              sortedOrders?.map((items, index) => (
                <OrderInvoiceCard data={items} index={index} key={index} />
              ))
            ) : (
              <div className="text-[2rem] text-white flex justify-center items-center w-full my-[5rem]">No Orders Found</div>
            )
          ) : (
            <div className="flex justify-center items-center w-full my-[5rem]">
              <Oval
                visible={true}
                height="50"
                width="50"
                color="#FAFF00"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderInvoice;
