import React from 'react'
import { DownloadAppImg, DownloadAppLandingImg } from '../../../static/constants/imgConstants'
import './DownloadApp2.css'

const DownloadApp2 = () => {
    return (
        <div className='download-app-bg px-[4rem]'>
            <div className="container-lg flex flex-col-reverse md:flex-row justify-between items-center md:h-[85rem] text-white font-urbanist overflow-hidden my-[4rem] md:my-0">
                <div className="md:w-[45%] flex flex-col justify-center items-center h-full pt-[10%]">
                    <h1 className="text-[5.2rem] lg:text-[6.4rem] urbanist-semibold leading-[8rem] text-center">
                        Transform with Our Fitness App Today!
                    </h1>
                    <h2 className="text-[3.6rem] lg:my-[5rem] urbanist-medium text-center lg:text-left">
                        Download the Fit.zone app now!
                    </h2>
                    <div className='flex justify-between items-center mt-[4rem] overflow-hidden w-[72%]'>
                        <a href="https://www.apple.com/in/app-store/" target="_blank" className="w-[45%]">
                            <div className=' cursor-pointer hover:scale-95 bg-[#030606] rounded-[1.2rem]'>
                                <img className='' src={DownloadAppImg.appleIcon} alt='app-store' />
                            </div>
                        </a>
                        <a href="https://play.google.com/store/apps" target="_blank" className="w-[51%]">
                            <div className=' cursor-pointer hover:scale-95'>
                                <img className='' src={DownloadAppImg.googleIcon} alt='play-store' />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="w-[50%] my-[4.5rem] md:my-0">
                    <img
                        src={DownloadAppLandingImg.DownloadApp2}
                        alt="Fitness App Preview"
                        className="w-full"
                    />
                </div>
            </div>
        </div>
    )
}

export default DownloadApp2