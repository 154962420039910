import { actionTypes } from '../../types';

export const GetFitnessAssessment = (payload) => ({
    type: actionTypes.GET_FITNESS_ASSESSMENT_REQUEST,
    payload,
});

export const GetFitnessAssessmentSuccess = (payload) => ({
    type: actionTypes.GET_FITNESS_ASSESSMENT_SUCCESS,
    payload,
});

export const GetFitnessAssessmentFailure = (payload) => ({
    type: actionTypes.GET_FITNESS_ASSESSMENT_FAILURE,
    payload,
});

export const GetFitnessAssessmentReset = () => ({
    type: actionTypes.GET_FITNESS_ASSESSMENT_RESET,
});

export const GetFitnessAssessmentResetAll = () => ({
    type: actionTypes.GET_FITNESS_ASSESSMENT_RESET_ALL,
}); 