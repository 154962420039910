export const navData = [
    {
        id: 1,
        title: 'Community',
        path: '/community',
    },
    {
        id: 2,
        title: 'Fit Centers',
        path: '/fit-center/fit-prime',
    },
    {
        id: 3,
        title: 'Fit Partners',
        path: '/fit-partner',
    },
    {
        id: 4,
        title: 'Fit Store',
        path: 'https://shop.fit.zone/',
        isOpenInNewTab: true
    },
    {
        id: 5,
        title: 'App',
        path: '/app-store',
    },
    /* {
        id: 6,
        title: 'Login',
        path: '/common/auth',
    }, */
    {
        id: 7,
        title: 'Help',
        path: '/help',
    },
]
