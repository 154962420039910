import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { SubscriptionRecordFailure, SubscriptionRecordSuccess } from '../../actions/SubscriptionManagementActions/SubscriptionRecordAction';

function* subscriptionRecord({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.SubscriptionRecord + `?CustomerId=${payload.userId}`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("SUBSCRIPTION_RECORD_SUCCESS status :", response.status);
        console.log("SUBSCRIPTION_RECORD_SUCCESS data :", response.data);
        console.log("SUBSCRIPTION_RECORD_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            SubscriptionRecordSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("SUBSCRIPTION_RECORD_FAILURE status :", error?.response?.status);
        console.log("SUBSCRIPTION_RECORD_FAILURE data:", error?.response?.data);
        console.log("SUBSCRIPTION_RECORD_FAILURE error :", error);
        console.log('====================================');
        yield put(SubscriptionRecordFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* subscriptionRecordSaga() {
    yield takeLatest(actionTypes.SUBSCRIPTION_RECORD_REQUEST, subscriptionRecord);
}

export default subscriptionRecordSaga;