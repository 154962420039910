import React from 'react'
import { NavbarImg } from '../../../static/constants/imgConstants'
import { SidebarData } from './SidebarData'
import { useNavigate } from 'react-router-dom'

const SidebarLayout = ({ setSelectedOption, selectedOption }) => {
    const Navigate = useNavigate()
    return (
        <div className={`bg-black w-[10rem] lg:w-[35rem] h-full flex flex-col justify-start items-center px-3`}>
            <div className="w-[20rem] h-[2.7rem] lg:w-[25rem] lg:h-[3.7rem] my-[3rem] mx-auto cursor-pointer hidden lg:block" onClick={() => Navigate("/")}>
                <img src={NavbarImg.LogoLight} alt="" className="w-full h-full" />
            </div>
            <div className="w-[6rem] my-[3rem] lg:hidden cursor-pointer" onClick={() => Navigate("/")}>
                <img src={NavbarImg.logoSmall} alt="" className="w-full h-full" />
            </div>
            <div className="">
                {SidebarData.map((items) => {
                    return (
                        <div
                            className={`flex justify-start items-center my-[3rem] cursor-pointer ${selectedOption === items.route ? "text-[#FFF200]" : "text-white"} hover:text-[#FFF200] focus:text-[#FFF200]`}
                            onClick={() => setSelectedOption(items.route)}
                        >
                            <div className="lg:mr-[2rem]">
                                {items.icon}
                            </div>
                            <div className="text-[2rem] text-start urbanist-medium hidden lg:block">
                                {items.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SidebarLayout