import React from 'react'
import { GymPageImg, NearbyGymCardImg } from '../../../static/constants/imgConstants'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

const AltMembershipAd = ({ image, package_type, title, address1, openingHours, closingHours, address2 }) => {

    const Navigate = useNavigate();
    const getSubscriptionCategoryNameReducerData = useSelector((state) => state.getSubscriptionCategoryNameReducer?.data);


    console.log("package_type :", package_type);


    return (
        <div className='bg-[#1E1E1E] px-[5.9rem] xl:px-[15.5rem] flex justify-center items-center'>
            <div className="container flex flex-col-reverse lg:flex-row justify-between items-center my-[3rem]">
                <div className="my-[4.8rem] lg:my-0">
                    <div className="flex flex-col justify-center items-center lg:items-start xl:block">
                        <div className="flex flex-col lg:flex-row justify-start items-center">
                            <div className="flex justify-start items-start">
                                <img src={package_type === "fit-champ" || package_type == "champion" ? NearbyGymCardImg.champion : NearbyGymCardImg.premium} alt="" className="mr-[1.8rem] w-[4.7rem] h-[6.3rem]" />
                                <div className={`${package_type === "fit-champ" ? "text-[#FFBB33]" : "text-white"} urbanist-bold text-[4.8rem]`}>{package_type === "fit-champ" || package_type == "champion" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) }) : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) })}&nbsp;</div>
                            </div>
                            <div className="urbanist-bold text-[4.8rem] text-white flex">
                                <span className="hidden xl:block">-</span>&nbsp;{title}
                            </div>
                        </div>
                        <div className="urbanist-regular text-[2.4rem] text-white mt-[3rem] w-[50%] xl:w-[90%]">
                            {address1}
                        </div>
                        <div className="urbanist-regular text-[2.4rem] text-white mb-[3rem] w-[50%] xl:w-[90%]">
                            {address2}
                        </div>
                        <div className="urbanist-medium text-[3.6rem] text-white w-[45%] xl:w-full">
                            Monday - Sunday | {openingHours?.split(":")[0] + ":" + openingHours?.split(":")[1]} - {closingHours?.split(":")[0] + ":" + closingHours?.split(":")[1]}
                        </div>
                        <button
                            className="bg-[#FAFF00] my-[5.1rem] open-sans-semibold text-[2.5rem] rounded-[2.2rem] py-[0.9rem] px-[6rem]"
                            onClick={() => Navigate(`/fit-center/${package_type === "fit-champ" ? "fit-champ" : "fit-prime"}`)}
                        >
                            Buy Now
                        </button>
                    </div>
                </div>
                <div className="">
                    <img src={image ? image : GymPageImg.gymViewImg} alt="" className="rounded-[4.1rem] w-[44.5rem] h-[44.3rem]" />
                </div>
            </div>
        </div>
    )
}

export default AltMembershipAd