import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { strapiPressAndMediaFailure, strapiPressAndMediaSuccess } from '../../actions/StrapiServiceActions/strapiPressAndMediaAction';

function* strapiPressAndMedia({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiArticles + `?category.slug=press_and_media`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_PRESS_AND_MEDIA_SUCCESS response :", response);
        console.log("STRAPI_PRESS_AND_MEDIA_SUCCESS status :", response?.status);
        console.log("STRAPI_PRESS_AND_MEDIA_SUCCESS data :", response?.data);
        console.log("STRAPI_PRESS_AND_MEDIA_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            strapiPressAndMediaSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_PRESS_AND_MEDIA_FAILURE status :", error?.response?.status);
        console.log("STRAPI_PRESS_AND_MEDIA_FAILURE data:", error?.response?.data);
        console.log("STRAPI_PRESS_AND_MEDIA_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiPressAndMediaFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* strapiPressAndMediaSaga() {
    yield takeLatest(actionTypes.STRAPI_PRESS_AND_MEDIA_REQUEST, strapiPressAndMedia);
}

export default strapiPressAndMediaSaga;