import React, { useEffect } from 'react'
import VideoPlayer3 from '../../components/common/VideoPlayer3/VideoPlayer3'
import AltMembershipAd from '../../components/common/AltMembershipAd/AltMembershipAd'
import GymTraitsIconSection from '../../components/common/GymTraitsIconSection/GymTraitsIconSection'
import LocationMap from '../../components/common/LocationMap/LocationMap'
import ExploreOtherGym from '../../components/common/ExporeOtherGym/ExploreOtherGym'
import Membership2 from '../../components/common/Membership2/Membership2'
import Footer3 from '../../components/common/Footer3/Footer3'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DownloadApp from '../../components/common/DownloadApp/DownloadApp'
import { filehandler } from '../../store/actions/FileManagementServiceActions/filehandlerAction'

const GymPage = () => {
    const { package_type } = useParams();
    const { gym_id } = useParams();
    const dispatch = useDispatch();

    const searchByLocationReducersData = useSelector((state) => state.searchByLocationReducers?.data);

    useEffect(() => {
        dispatch(filehandler({ venueId: gym_id }))
    }, [])


    console.log("searchByLocationReducersData :", searchByLocationReducersData);
    return (
        <div className='bg-[#1e1e1e]'>
            <VideoPlayer3 showFooter gymId={gym_id} />
            {searchByLocationReducersData?.venues?.filter((item) => item.id == gym_id)?.map((items) => {
                return (
                    <div className="">
                        <AltMembershipAd
                            image={items?.venuePosterImage}
                            package_type={package_type}
                            title={items.name}
                            address1={items.address.line1}
                            address2={items.address.line2}
                            openingHours={items.openingHours}
                            closingHours={items.closingHours}
                        />
                        <GymTraitsIconSection facilities={items.amenities} />
                        <LocationMap geoLocation={items.geolocation} venueName={items.name} />
                    </div>
                )
            })
            }
            <ExploreOtherGym />
            <Membership2 />
            <div className="hidden lg:block">
                <DownloadApp />
            </div>
            <Footer3 />
        </div>
    )
}

export default GymPage