import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { communityFailure, communitySuccess } from '../../actions/StrapiServiceActions/communityAction';

function* community({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.community + `?category.slug=community`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("COMMUNITY_SUCCESS response :", response);
        console.log("COMMUNITY_SUCCESS status :", response?.status);
        console.log("COMMUNITY_SUCCESS data :", response?.data);
        console.log("COMMUNITY_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            communitySuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("COMMUNITY_FAILURE status :", error?.response?.status);
        console.log("COMMUNITY_FAILURE data:", error?.response?.data);
        console.log("COMMUNITY_FAILURE error :", error);
        console.log('====================================');
        yield put(communityFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* communitySaga() {
    yield takeLatest(actionTypes.COMMUNITY_REQUEST, community);
}

export default communitySaga;