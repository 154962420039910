import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_user_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { validateOtpFailure, validateOtpSuccess } from '../../actions/authActions/validateOtpActions';
import { setAccessToken, setRefreshToken, setVerifiedPhoneNo } from '../../actions/utilsActions/utilsActions';
import store from '../../configStore';


function* validateOtp({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_user_management_service,
            endPoint: endpoints.ValidateOTP + `?userid=${payload?.userId}&otp=${payload?.otp}`,
            isAuth: false,
        });
        console.log('====================================');
        console.log("VALIDATE_OTP_SUCCESS status :", response.status);
        console.log("VALIDATE_OTP_SUCCESS data :", response.data);
        console.log("VALIDATE_OTP_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            validateOtpSuccess({
                status: response?.status,
                data: response?.data
            })
        );
        yield put(setAccessToken(response?.data?.token?.accessToken));
        yield put(setRefreshToken(response?.data?.token?.refreshToken));
        const state = store.getState();
        let phoneNumber = state.utilReducers?.phoneNumber
        if(phoneNumber){
            yield put(setVerifiedPhoneNo({
                number: phoneNumber.number,
                isVerified: true
            }))
        }
    } catch (error) {
        console.log('====================================');
        console.log("VALIDATE_OTP_FAILURE status :", error?.response?.status);
        console.log("VALIDATE_OTP_FAILURE data:", error?.response?.data);
        console.log("VALIDATE_OTP_FAILURE error :", error);
        console.log('====================================');
        yield put(validateOtpFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* validateOtpSaga() {
    yield takeLatest(actionTypes.VALIDATE_OTP_REQUEST, validateOtp);
}

export default validateOtpSaga;