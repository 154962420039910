import { actionTypes } from '../../types';

export const subscriptionOffers = (payload) => ({
    type: actionTypes.SUBSCRIPTION_OFFERS_REQUEST,
    payload,
});

export const subscriptionOffersSuccess = (payload) => ({
    type: actionTypes.SUBSCRIPTION_OFFERS_SUCCESS,
    payload,
});

export const subscriptionOffersFailure = (payload) => ({
    type: actionTypes.SUBSCRIPTION_OFFERS_FAILURE,
    payload,
});

export const subscriptionOffersReset = () => ({
    type: actionTypes.SUBSCRIPTION_OFFERS_RESET,
});

export const subscriptionOffersResetAll = () => ({
    type: actionTypes.SUBSCRIPTION_OFFERS_RESET_ALL,
}); 