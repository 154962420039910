import { actionTypes } from "../../types";

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMMUNITY_SUB_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.COMMUNITY_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
                data: action?.payload?.data
            }
        case actionTypes.COMMUNITY_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
        case actionTypes.COMMUNITY_SUB_CATEGORIES_RESET:
            return {
                ...state,
                status: '',
                message: ''
            }
        case actionTypes.COMMUNITY_SUB_CATEGORIES_RESET_ALL:
            return initialState
        default:
            return state;
    }
};