import { actionTypes } from "../../types"

const initialState = {
    accessToken: '',
    refreshToken: '',
    userId: '',
    userLatitute: '',
    userLongitude: '',
    userRoleId: '',
    userCategory: '',
    phoneNumber: {
        number: '',
        isVerified: false
    },
    email: "",
    plan: {},
    cMSAccessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhcGkxYTI5NGZjMS03ZTNlLTRlYWMtYjRlMS1lNGM2MjU0NzRhZTYiLCJ4bW9kIjoxNzIzNTI2NjM3MDAwLCJuYmYiOjE3MjM1MjY2MzcsImlzcyI6ImRvdGNtcy1wcm9kdWN0aW9uIiwibGFiZWwiOiJcdGRvdGNtcy5vcmcuMSIsImV4cCI6MTgxODA5NTQwMSwiaWF0IjoxNzIzNTI2NjM3LCJqdGkiOiI0ZTUwMzdiZS0yYzRkLTQwYTgtOGM3NS03YWZjNDZiM2JmYTEifQ.UPxxIDRCMlNfBDCOtMKxtBpUg-GNBucM1BMy_1Z0LI8",
    setJwtToken: "",
    planType: "",
    setName: "",
    setUpgradeUnable: false,
    resendOtpTime: null,
    setUpdateProfile: false,
    selectedPlanDate: "",
    selectedRenewPlan: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case actionTypes.SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload
            }
        case actionTypes.SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            }
        case actionTypes.USER_LATITUTE:
            return {
                ...state,
                userLatitute: action.payload
            }
        case actionTypes.USER_LONGITUDE:
            return {
                ...state,
                userLongitude: action.payload
            }
        case actionTypes.USER_ROLE_ID:
            return {
                ...state,
                userRoleId: action.payload
            }
        case actionTypes.USER_CATEGORY:
            return {
                ...state,
                userCategory: action.payload
            }
        case actionTypes.SET_VERIFIED_PHONE_NO:
            return {
                ...state,
                phoneNumber: {
                    number: action.payload.number,
                    isVerified: action.payload.isVerified,
                }
            }
        case actionTypes.SET_SELECTED_PLAN:
            return {
                ...state,
                plan: action.payload
            }
        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case actionTypes.SET_JWT_TOKEN:
            return {
                ...state,
                setJwtToken: action.payload
            }
        case actionTypes.SET_PLAN_TYPE:
            return {
                ...state,
                planType: action.payload
            }
        case actionTypes.SET_NAME:
            return {
                ...state,
                setName: action.payload
            }
        case actionTypes.SET_UPGRADE_UNABLE:
            return {
                ...state,
                setUpgradeUnable: action.payload
            }
        case actionTypes.SET_RESEND_OTP_TIME:
            return {
                ...state,
                resendOtpTime: action.payload
            }
        case actionTypes.SET_UPDATE_PROFILE:
            return {
                ...state,
                setUpdateProfile: action.payload
            }
        case actionTypes.SET_SELECTED_PLAN_DATE:
            return {
                ...state,
                selectedPlanDate: action.payload
            }
        case actionTypes.SET_SELECTED_RENEW_PLAN:
            return {
                ...state,
                selectedRenewPlan: action.payload
            }
        default:
            return state;
    }
}