import React from 'react'
import { DownloadAppLandingImg } from '../../../static/constants/imgConstants';
import "./AppBanner.css"

const AppBanner = () => {
  return (
    <div className='bg-[#1E1E1E] app-banner-bg px-[4rem]'>
      <div className="container-lg">
        <div className="flex flex-col md:flex-row items-center justify-between text-white min-h-max p-6">
          <div className="flex-1 flex justify-center">
            <img src={DownloadAppLandingImg.bannerImage} alt="Banner" className="w-full h-full" />
          </div>
          <div className="flex-1 text-center md:text-left mt-6 md:mt-0 flex flex-col justify-start items-start md:justify-center">
            <h1 className="text-[6.4rem] font-bold text-orange-500 urbanist-bold text-start mt-[4rem] md:mt-0">
              Set Challenges and
              <br />
              track your progress</h1>
            <p className="mt-4 text-[2.4rem] text-white urbanist-medium text-start">Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br /> sed do eiusmod tempor</p>
            <a href="https://play.google.com/store/apps" target='_blank' className="">
              <button className="mt-6 p-3 bg-[#FAFF00] rounded-[2.2rem] font-semibold text-[1.73rem] text-[#486077] px-20 urbanist-semibold">Join Us</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppBanner



