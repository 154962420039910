import React from 'react'
import "./HomeLandingCarousel.css"
import { HomeLandingCarouselImg } from '../../../static/constants/imgConstants'

function HomeLandingCarouselItem3() {
    return (
        <div className='home-landing-crousal-item3 px-[4rem]'>
            <div className='container-lg h-full'>
                <div 
                    className='home-landing-crousal-item3-bg poppins-semibold 
                    w-[85%] sm:w-[75%] md:w-[65%] lg:w-[55%] xl:w-[45%] ml-[10%] md:ml-0 h-full
                    flex justify-center md:justify-start items-center 
                    text-[8rem] md:text-[10rem] lg:text-[11rem] xl:text-[12vh] text-white 
                    leading-[9rem] sm:leading-[10rem] md:leading-[12rem] lg:leading-[12.5rem] xl:leading-[14rem]'
                >
                    Workout Program Made For You
                </div>
            </div>
        </div>
    )
}

export default HomeLandingCarouselItem3
