import { actionTypes } from '../../types';

export const getSubscriptionCategoryName = (payload) => ({
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_REQUEST,
    payload,
});

export const getSubscriptionCategoryNameSuccess = (payload) => ({
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_SUCCESS,
    payload,
});

export const getSubscriptionCategoryNameFailure = (payload) => ({
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_FAILURE,
    payload,
});

export const getSubscriptionCategoryNameReset = () => ({
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_RESET,
});

export const getSubscriptionCategoryNameResetAll = () => ({
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_RESET_ALL,
}); 