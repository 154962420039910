import React, { useState } from 'react'
import "./VideoPlayer2.css"
import ReactPlayer from 'react-player'
import { VideoPlayer2Img } from '../../../static/constants/imgConstants'
import { useNavigate } from 'react-router-dom'

function VideoPlayer2() {

    const Navigate = useNavigate()

    const [selectedMedia, setSelectedMedia] = useState({
        url1: "https://fit.zone/assets/images/elevate-work/elevate-work-vid.mp4"
    })

    return (
        <div className='h-[100rem] sm:h-[124rem] lg:h-[85rem] px-[4rem] flex flex-col justify-start items-center'>
            <div className="container-lg overflow-hidden">
                <div className="text-white text-[5rem] sm:text-[6.5rem] montserrat-bold text-start xl:text-center my-[4rem]">
                    Elevate your workout game with
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center overflow-hidden">
                    <div className="w-full xl:w-[50%] flex flex-col justify-between items-start urbanist-medium text-[2.6rem] smtext-[3.5rem] leading-[3rem] sm:leading-[3.6rem] text-white">
                        <div
                            onClick={() => setSelectedMedia({ url1: "https://fit.zone/assets/images/elevate-work/elevate-work-vid.mp4" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url1 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Scientific Workout Online Trainer ADI
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url2: "https://s3-alpha-sig.figma.com/img/5c12/3c1f/e0e83a82eecd4eed79a0243280dff55a?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZLxRfvbiEIiTKF7hjUSt~Znli64Hhl4D5wWFO66ebWK0Ln0XrPODeX1N7DMmRw8Xp-VMB2dOnaYAcbtSaRMpQwSO1ItiZlYhmv-6PtJge5T~cfnvmT03T3~JiKvS7lS7RqF9jmGk9DSsJyOuTDm2kyaxjl12wPkpaZX1X4YVZleOzlCsDbtsrf8wue7UrAGt1P6v3X3T6F9BcUHdUpDOyAH0mCil5eChm8EimmRvBJdTFE2bjhxmWnpsF2YT4op85hnXjCGYfldSTSymuowQoCc6XPXi2gcru2cbLBs4UHEmsttpn0ZFJ5kjPKDy~1z6iuU~OAc~wsWbyTbPlJpOLA__" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url2 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Workout Anywhere
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url3: "https://s3-alpha-sig.figma.com/img/bfa6/08b3/21bbdbf19f284dc5a04e55ba047f94e4?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qAa6j1d93vyOAnWwgMsULHIILnw1r8hXaGYyr4O8ccJbpN8t2aJuYXwWeeon-qrQ60E4~40CxYJi8GEZHfaS9lkn~ebWDTcWpATpUX0TV1Clfb-oOzUoEh1VjduM7B~Ccq-Gn2H2elDWqG~qvWiwBZI7paV2NQ-KHFsGyZft4wJShPb48Xd9WsPtIUnii~AInovKImg7K38EU5gRVxyr9GESYmjlPzlH-vTVggjr1As39W790ZuXUF6NFtvikkpoCR~YZoE0Z2e36OySceg9sGt0jRiXWQoiBl6PThvNISOq3UqvdwONXtkMbPyjDq06yxvkqyzvcJLy86cNxHSxyw__" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url3 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Amazing group sessions
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url4: "https://s3-alpha-sig.figma.com/img/b0a1/c04f/217c268dc69a43ca74a4490561230569?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tyvk9SgIPDV3f73eo9Jpn2qfyOJyedRqb1fhjGI7FUfISq2350CZ1N4XjqBseHWlrtXwNPFQDrnhsCeyeZc4HsYtwRBinVId4SYvJQjKseT74Jfw-dQkjRo8jTVk8a4FcmLk8Bh1UPHhroF04AkDrDWG8uS8PLhXkeefYJQM1oFTdwr2bJWxYVre1Aipt7y1Q~DHV8wKio8T3AZWi37R53nVMWyliG8xMQ6ijGsbhuKQAIA68L8kYHKaZH4k1tR2ZbUzRFqswFvpsgjqOx5oLCXjP~mwO5pNbExMm9rgPYJLkb8nlmYrmAmJgL2OOjjCVIFlOVpq928n9gzqJJ1bMw__" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url4 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            State of Art facilities in both category
                        </div>
                        <button className="open-sans-semibold text-[1.6rem] text-black bg-[#FAFF00] p-2 rounded-[2.2rem] px-14 mx-10" onClick={() => Navigate("/fit-center/fit-prime")}>
                            Buy Now
                        </button>
                    </div>
                    <div className="w-full xl:w-[50%] my-10 xl:my-0 rounded-[2.2rem]">
                        {selectedMedia.url1 ?
                            <div className="w-[55rem] sm:w-full h-full rounded-[2.2rem] flex justify-center items-center">
                                <ReactPlayer
                                    url={selectedMedia.url1}
                                    controls={true}
                                    width={"75.1rem"}
                                    light={<img src={VideoPlayer2Img.videoPlayer2CoverImg} alt='Thumbnail' className='w-full h-full rounded-[2.2rem]' />}
                                    playing={true}
                                    height={"38.6rem"}
                                    playbackRate={1}
                                    progressInterval={500}
                                />
                            </div>
                            :
                            <div className="w-[55rem] sm:w-[75.1rem] h-[38.6rem] rounded-[2.2rem]">
                                <img src={selectedMedia.url2 || selectedMedia.url3 || selectedMedia.url4} alt="image" className="w-full h-full rounded-[2.2rem]" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer2
