import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_venue_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { searchByLocationFailure, searchByLocationSuccess } from '../../actions/venueManagementServiceActions/searchByLocationActions';
// import store from '../../configStore';

function* searchByLocation({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_venue_management_service,
            endPoint: endpoints.SearchByLocation + `?lat=${payload.lat}&lon=${payload.long}&Distance=50km&sortfield=Geolocation&pagesize=100&pagenumber=1&order=1`,
            isAuth: false,
        });

        console.log('====================================');
        console.log("SEARCH_BY_LOCATION_SUCCESS status :", response.status);
        console.log("SEARCH_BY_LOCATION_SUCCESS data :", response.data);
        console.log("SEARCH_BY_LOCATION_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            searchByLocationSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("SEARCH_BY_LOCATION_FAILURE status :", error?.response?.status);
        console.log("SEARCH_BY_LOCATION_FAILURE data:", error?.response?.data);
        console.log("SEARCH_BY_LOCATION_FAILURE error :", error);
        console.log('====================================');
        yield put(searchByLocationFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* searchByLocationSaga() {
    yield takeLatest(actionTypes.SEARCH_BY_LOCATION_REQUEST, searchByLocation);
}

export default searchByLocationSaga;