import axios from "axios";
import store from "./configStore";

export const AxiosService = ({ method, base_url, endPoint, data, ContentType, isAuth }) => {
    const state = store.getState();
    let accessToken = state.utilReducers?.accessToken

    console.log('====================================');
    console.log("calling AxiosService: ",
        {
            "method": method,
            "url": base_url + endPoint,
            "body": data,
            "ContentType": ContentType ? ContentType : 'application/json',
            "isAuth": isAuth,
            "token": isAuth ? accessToken : "",
        }
    );
    console.log('====================================');
    if (method === 'get') {
        return axios({
            method: method,
            url: base_url + endPoint,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else if (method === 'post' || method === 'put') {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: data,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: data,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `bearer ${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    }
};

export const AxiosPGService = ({ method, url, data, header }) => {

    console.log('====================================');
    console.log("calling Axios_PG_Service: ",
        {
            "method": method,
            "url": url,
            "body": data,
            "header": header,
        }
    );
    console.log('====================================');
    if (method === 'get') {
        return axios.get(
            url,
            header,
        );
    } else if (method === 'post') {
        return axios.post(
            url,
            data,
            header
        );
    }
};

export const AxiosCMSService = ({ method, base_url, endPoint, isAuth, ContentType }) => {
    const state = store.getState();
    let accessToken = state.utilReducers?.cMSAccessToken
    console.log('====================================');
    console.log("calling AxiosCMSService: ",
        {
            "method": method,
            "url": base_url + endPoint,
            "ContentType": ContentType ? ContentType : 'application/json',
            "isAuth": isAuth,
            "token": isAuth ? accessToken : "",
        }
    );
    console.log('====================================');
    axios({
        method: method,
        url: base_url + endPoint,
        headers: isAuth ?
            {
                'Content-Type': ContentType ? ContentType : 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
            :
            {
                'Content-Type': ContentType ? ContentType : 'application/json',
            },
    });
}
export const AxiosStrapiService = ({ method, base_url, endPoint, isAuth, ContentType }) => {
    const state = store.getState();
    let JwtToken = state.utilReducers?.setJwtToken
    console.log('====================================');
    console.log("calling AxiosStrapiService: ",
        {
            "method": method,
            "url": base_url + endPoint,
            "ContentType": ContentType ? ContentType : 'application/json',
            "isAuth": isAuth,
            "token": isAuth ? JwtToken : "",
        }
    );
    console.log('====================================');
    return axios({
        method: method,
        url: base_url + endPoint,
        headers: isAuth ?
            {
                'Content-Type': ContentType ? ContentType : 'application/json',
                Authorization: `Bearer ${JwtToken}`,
                "ngrok-skip-browser-warning": "69420",
            }
            :
            {
                'Content-Type': ContentType ? ContentType : 'application/json',
            },
    });
}
