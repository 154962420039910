import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const fitcoinBalance = (payload) => ({
    type: actionTypes.FITCOIN_BALANCE_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const fitcoinBalanceSuccess = (payload) => ({
    type: actionTypes.FITCOIN_BALANCE_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const fitcoinBalanceFailure = (payload) => ({
    type: actionTypes.FITCOIN_BALANCE_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const fitcoinBalanceReset = () => ({
    type: actionTypes.FITCOIN_BALANCE_RESET,
});

// Action creator for resetting all OTP generation reducer
export const fitcoinBalanceResetAll = () => ({
    type: actionTypes.FITCOIN_BALANCE_RESET_ALL,
}); 