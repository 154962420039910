import { actionTypes } from '../../types';

export const uploadProfilePic = (payload) => ({
    type: actionTypes.UPLOAD_PROFILE_PIC_REQUEST,
    payload,
});

export const uploadProfilePicSuccess = (payload) => ({
    type: actionTypes.UPLOAD_PROFILE_PIC_SUCCESS,
    payload,
});

export const uploadProfilePicFailure = (payload) => ({
    type: actionTypes.UPLOAD_PROFILE_PIC_FAILURE,
    payload,
});

export const uploadProfilePicReset = () => ({
    type: actionTypes.UPLOAD_PROFILE_PIC_RESET,
});

export const uploadProfilePicResetAll = () => ({
    type: actionTypes.UPLOAD_PROFILE_PIC_RESET_ALL,
}); 