import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { getSubscriptionCategoryNameFailure, getSubscriptionCategoryNameSuccess } from '../../actions/SubscriptionManagementActions/getSubscriptionCategoryNameAction';

function* getSubscriptionCategoryName({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.getSubscriptionCategoryName,
            isAuth: false,
        });

        console.log('====================================');
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_SUCCESS response :", response);
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_SUCCESS status :", response?.status);
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_SUCCESS data :", response?.data);
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            getSubscriptionCategoryNameSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_FAILURE status :", error?.response?.status);
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_FAILURE data:", error?.response?.data);
        console.log("GET_SUBSCRIPTION_CATEGORY_NAME_FAILURE error :", error);
        console.log('====================================');
        yield put(getSubscriptionCategoryNameFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* getSubscriptionCategoryNameSaga() {
    yield takeLatest(actionTypes.GET_SUBSCRIPTION_CATEGORY_NAME_REQUEST, getSubscriptionCategoryName);
}

export default getSubscriptionCategoryNameSaga;