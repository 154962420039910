import React from 'react'
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function PortraitVideoCard({ i, item, activeSlideIndex, length, setBuyNowPopup }) {
    const Navigate = useNavigate()
    return (
        <div
            key={i}
            className={`
                ${activeSlideIndex == i ?
                    "sm:!w-[40rem] w-[30rem] h-[66rem]"
                    : activeSlideIndex - 1 == i ?
                        "sm:!w-[36rem] w-[27rem] h-[58.5rem] my-[3.2rem] opacity-50"
                        : activeSlideIndex + 1 == i ?
                            "sm:!w-[36rem] w-[27rem] h-[58.5rem] my-[3.2rem] opacity-50"
                            : activeSlideIndex - 2 == i ?
                                "sm:!w-[36rem] w-[27rem] h-[54rem] my-[4.8rem] opacity-75"
                                : activeSlideIndex + 2 == i ?
                                    "sm:!w-[36rem] w-[27rem] h-[54rem] my-[4.8rem] opacity-75"
                                    :
                                    "sm:!w-[36rem] w-[27rem] h-[54rem] my-[5rem] opacity-10"
                }
                rounded-[2rem] bg-transparent relative
                transition-all ease-in-out duration-[1500ms] delay-300
            `}
        >
            <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer'>
                <FaPlay size={'5rem'} color='#fff' />
            </div>
            <img className='w-full h-full' src={item.thumbnail} alt='slide' />
            {activeSlideIndex == i ?
                <button
                    className="button text-black text-[1.6rem] open-sans-semibold bg-[#FAFF00] absolute bottom-5 px-[3.4rem] py-[0.6rem] -translate-x-[50%] -translate-y-[50%] left-[50%] rounded-[2.2rem]"
                    onClick={() => setBuyNowPopup(true)}
                >
                    Book Now
                </button>
                :
                null
            }
        </div>
    )
}

export default PortraitVideoCard
