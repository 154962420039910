import React from 'react'
import AboutUsLanding from '../../components/common/AboutUsLanding/AboutUsLanding'
import AboutUsGrid from '../../components/common/AboutUsGrid/AboutUsGrid'
import Footer3 from '../../components/common/Footer3/Footer3'

function AboutUs() {
    return (
        <div className='bg-[#000]'>
            <AboutUsLanding />
            <AboutUsGrid />
            <Footer3 />
        </div>
    )
}

export default AboutUs