import React from 'react'
import { DownloadAppImg, DownloadAppLandingImg } from '../../../static/constants/imgConstants'
import './DownloadAppLanding.css'
import "swiper/css";
import "swiper/css/effect-coverflow";

function DownloadAppLanding() {
  return (
    <div className='bg-[#1E1E1E]'>
      <swiper-container pagination="true" keyboard="true" Autoplay={"2000"}>
        <swiper-slide>
          <div className="w-full download-app-landing-bg bg-[#1E1E1E] px-[4rem] mt-[15.5rem] md:mt-0">
            <div className="container-lg text-white font-urbanist md:translate-y-[50%]">
              <div className="md:w-[45%] flex flex-col justify-center items-center h-full">
                <h1 className="text-[6.4rem] urbanist-semibold leading-[8rem]">
                  Experience Fitness
                  <br />
                  Anywhere, Anytime
                </h1>
                <img src={DownloadAppLandingImg.downloadAppLandingMobileImg} alt="" className="w-full h-full md:hidden my-[5.9rem]" />
                <h2 className="my-[5rem] text-[3rem] urbanist-medium">
                  Download Our App Now for a Healthier You
                </h2>
                <div className='flex justify-between items-center mt-[4rem] overflow-hidden w-[72%]'>
                  <div className='w-[45%] cursor-pointer hover:scale-95 bg-[#030606] rounded-[1rem]'>
                    <a href="https://www.apple.com/in/app-store/" target="_blank" className="">
                      <img className='' src={DownloadAppImg.appleIcon} alt='app-store' />
                    </a>
                  </div>
                  <div className='w-[51%] cursor-pointer hover:scale-95'>
                    <a href="https://play.google.com/store/apps" target="_blank" className="">
                      <img className='' src={DownloadAppImg.googleIcon} alt='play-store' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div className='bg-[#1E1E1E] download-app-landing-bg-2 px-[4rem] py-[3rem]'>
            <div className="container-lg">
              <div className="flex flex-col md:flex-row items-center justify-between text-white min-h-max p-6">
                <div className="flex-1 flex justify-center">
                  <img src={DownloadAppLandingImg.bannerImage} alt="Banner" className="w-full h-full" />
                </div>
                <div className="flex-1 text-center md:text-left mt-6 md:mt-0 flex flex-col justify-start items-start md:justify-center">
                  <h1 className="text-[6.4rem] font-bold text-orange-500 urbanist-bold text-start mt-[4rem] md:mt-0">
                    Set Challenges and
                    <br />
                    track your progress</h1>
                  <p className="mt-4 text-[2.4rem] text-white urbanist-medium text-start">Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br /> sed do eiusmod tempor</p>
                  <button className="mt-6 p-3 bg-[#FAFF00] rounded-[2.2rem] font-semibold text-[1.73rem] text-[#486077] px-20 urbanist-semibold">Join Us</button>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div className='download-app-landing-bg-3 px-[4rem]'>
            <div className="container-lg">
              <div className="h-max text-white flex flex-col md:flex-row items-center justify-center p-4 my-[5rem]">
                <div className="md:w-1/2 flex justify-center md:justify-end md:px-16">
                  <img
                    src={DownloadAppLandingImg.AppBanner3}
                    alt="Dance"
                    className="w-full h-full"
                  />
                </div>
                <div className="mt-8 md:mt-0 md:w-1/2 text-start">
                  <h1 className="text-[6.4rem] font-bold text-[#80C241]">
                    Group Sessions for fun <br /> & fitness
                  </h1>
                  <p className="text-[2.4rem] text-[#FFFEFE]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br /> sed do eiusmod tempor
                  </p>
                  <button className="mt-8 p-3 bg-[#FAFF00] rounded-full urbanist-semibold text-black text-[1.7rem] px-[3.9rem] py-[1.4rem] md:px-12 lg:px-16">
                    Join Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  )
}

export default DownloadAppLanding