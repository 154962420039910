// Marquee.js
import React from 'react';
import EstoreImg1 from '../../../static/img/estore-img-1.jpg';
import EstoreImg2 from '../../../static/img/estore-img-2.jpg';
import EstoreImg3 from '../../../static/img/estore-img-3.jpg';

const Marquee = () => {
    const images = [
        EstoreImg1,
        EstoreImg2,
        EstoreImg3,
        EstoreImg1,
        EstoreImg2,
        EstoreImg3,
        EstoreImg1,
        EstoreImg2,
        EstoreImg3,
        EstoreImg1,
        EstoreImg2,
        EstoreImg3,
    ];

    return (
        <div className='w-full bg-[#1E1E1E]'>
            <div className="overflow-hidden whitespace-nowrap">
                <div className="inline-block animate-marquee">
                    {images.map((src, index) => (
                        <div className='inline-block w-[41rem] h-[50rem] mx-[2rem] rounded-[.5rem]'>
                            <img key={index} src={src} alt={`image-${index}`} className="w-full h-full" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Marquee;
