import React, { useEffect, useState } from 'react'
import TrainerOverviewCard from './TrainerOverviewCard'
import "./TrainerOverview.css"
import { NavbarImg } from '../../../static/constants/imgConstants'
import "swiper/css";
import 'swiper/element/css/pagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BuyNowPopup from '../BuyNowPopup/BuyNowPopup';
import { venueStaffReset } from '../../../store/actions/VenueStaffManagementServiceActions/venueStaffAction';

function TrainerOverview() {

    const dispatch = useDispatch();
    const [screenSize] = useState(window.innerWidth);
    const [trainerData, setTrainerData] = useState([])
    const Navigate = useNavigate()

    const [handleOutsideClick, setHandleOutsideClick] = useState(false);

    const [buyNowPopup, setBuyNowPopup] = useState(false);

    const venueStaffReducerData = useSelector((state) => state.venueStaffReducer.data);
    const venueStaffReducerStatus = useSelector((state) => state.venueStaffReducer.status);

    const slidesPerView = () => {
        if (screenSize < 1024 && screenSize > 768) {
            console.log("screenSize < 1024");
            return 3
        } else if (screenSize < 768 && screenSize > 600) {
            console.log("screenSize < 768");
            return 2
        } else if (screenSize < 600) {
            console.log("screenSize < 600");
            return 1
        } else {
            console.log("screenSize > 1024");
            return 4
        }
    }

    useEffect(() => {
        if (venueStaffReducerStatus == 200) {
            if (venueStaffReducerData?.VenueStaff?.length > 0) {
                venueStaffReducerData.VenueStaff?.filter((item) => item.staffType == "PersonalTrainer")?.map((items, key) => {
                    setTrainerData(...trainerData, { feedback: items })
                })
            }
            dispatch(venueStaffReset());
        } else if (venueStaffReducerStatus) {
            dispatch(venueStaffReset());
        }
    }, [venueStaffReducerStatus])

    useEffect(() => {
        // Fetch the trainers
        if (venueStaffReducerStatus === 200) {
            const personalTrainers = venueStaffReducerData?.VenueStaff?.filter(item => item.staffType === "PersonalTrainer");
            setTrainerData(personalTrainers);

            // Dispatch the rating action for each trainer
            dispatch(venueStaffReset());
        } else if (venueStaffReducerStatus) {
            dispatch(venueStaffReset());
        }
    }, [venueStaffReducerStatus]);

    /* useEffect(() => {
        // Check if trainerRating API returned data and update the trainerData state
        if (trainerRatingReducerStatus === 200 || trainerRatingReducerStatus === 204) {
            setTrainerData(prevTrainerData =>
                prevTrainerData.map(trainer => ({
                    ...trainer,
                    // If the rating API returns data for a trainer, use it; otherwise, default to 0
                    rating: trainerRatingReducerData?.[trainer.userId] !== undefined
                        ? trainerRatingReducerData[trainer.userId]?.averageRating
                        : 0
                }))
            );
        }
    }, [trainerRatingReducerStatus, trainerRatingReducerData]); */

    useEffect(() => {
        setBuyNowPopup(false);
    }, [handleOutsideClick])


    console.log("trainerData :", trainerData);


    return (
        <div className='overflow-hidden'>
            <div className="trainer-overview-section overflow-hidden px-[4rem]">
                <div className="container-lg">
                    <div className="w-[33.1rem] mt-[4rem]">
                        <img src={NavbarImg.LogoLight} alt="" className="w-full h-full" />
                    </div>
                    <div className="my-[2rem]">
                        <swiper-container
                            pagination={true}
                            slides-per-view={slidesPerView()}
                            keyboard="true"
                        >
                            {trainerData.length > 0 ?
                                trainerData?.map((items, key) => {
                                    console.log("PT items :", items)
                                    return (
                                        <swiper-slide>
                                            <div className={`flex justify-center xl:justify-between items-center`}>
                                                <div
                                                    style={{ '--image-url': `url(${items.profilePicURL})` }}
                                                    className={`w-full sm:w-[85%] h-[100rem] sm:h-[50rem] relative ${items.profilePicURL ? "bg-[image:var(--image-url)]" : "trainer-overview-card1"} bg-cover bg-no-repeat`}
                                                >
                                                    <TrainerOverviewCard
                                                        name={items.firstName + " " + items.lastName}
                                                        rating={items.rating?.toFixed(1)}
                                                        feedback={items?.additionalDetails}
                                                    />
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    )
                                })
                                :
                                <div className="text-center text-white montserrat-bold text-[4.1rem]">
                                    No Data Available at this time
                                </div>
                            }
                        </swiper-container>
                    </div>
                    <div className="mb-[2rem]">
                        <div className="big-shoulders-display-regular text-white text-[4.8rem] text-center">
                            Trainer Overview
                        </div>
                        <div className=" text-white text-[1.6rem] text-center questrial-regular">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus interdum nunc, et eleifend
                            neque sagittis et.
                        </div>
                    </div>
                </div>
            </div>
            <div className="free-trial-section hidden sm:block h-[51vh]">
                <div className="flex flex-col sm:flex-row justify-evenly items-center h-full m-auto">
                    <div className="text-center sm:text-start">
                        <div className="text-white big-shoulders-display-semibold text-[6.4rem]">
                            Are you ready to change?
                        </div>
                        <pre className="text-white big-shoulders-display-regular text-[2rem] w-full">
                            {`Challenge your strength. Stretch your body. Breathe easy. Our team of world-class\ninstructors will empower`}
                        </pre>
                    </div>
                    <div className="free-trial-button text-white outfit-medium text-[2rem] p-5 px-[5.7rem] border-2 border-white cursor-pointer">
                        <div className="free-trial-button-text" onClick={() => setBuyNowPopup(true)}>
                            Start your Free Trial
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-[2.7rem] sm:hidden h-[27rem]">
                <div className="text-white big-shoulders-display-semibold text-[3.2rem]">
                    Are you ready to change?
                </div>
                <div className="flex justify-between items-center">
                    <pre className="text-white big-shoulders-display-regular text-[2rem]">
                        {`Challenge your\nstrength. Stretch your body. Breathe\neasy. Our team of\nworld-class instructors will\nempower`}
                    </pre>
                    <div className="free-trial-button text-white outfit-medium text-[1.6rem] py-[2rem] px-[2rem] border-2 border-white cursor-pointer mr-[5rem]">
                        <div className="free-trial-button-text" onClick={() => setBuyNowPopup(true)}>
                            Start your Free Trial
                        </div>
                    </div>
                </div>
            </div>
            <BuyNowPopup show={buyNowPopup} onClose={() => setBuyNowPopup(false)} setBuyNowPopup={setBuyNowPopup} />
        </div >
    )
}

export default TrainerOverview
