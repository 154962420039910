import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const CMSProducts = (payload) => ({
    type: actionTypes.CMS_PRODUCTS_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const CMSProductsSuccess = (payload) => ({
    type: actionTypes.CMS_PRODUCTS_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const CMSProductsFailure = (payload) => ({
    type: actionTypes.CMS_PRODUCTS_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const CMSProductsReset = () => ({
    type: actionTypes.CMS_PRODUCTS_RESET,
});

// Action creator for resetting all OTP generation reducer
export const CMSProductsResetAll = () => ({
    type: actionTypes.CMS_PRODUCTS_RESET_ALL,
}); 