import React from 'react'
import { GymTraitsIconSectionImg } from '../../../static/constants/imgConstants'

const GymTraitsIconSection = ({ facilities }) => {
    return (
        <div className='bg-[#1E1E1E] px-[4rem]'>
            <div className="container-lg">
                <div className="flex flex-wrap justify-center items-center gap-[10.4rem]">
                    {facilities?.map((items) => {
                        if (items === "locker") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon4} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Lockers
                                    </div>
                                </div>
                            )
                        } else if (items === "steam") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon3} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Steam
                                    </div>
                                </div>
                            )
                        } else if (items === "car-parking") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon1} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Parking
                                    </div>
                                </div>
                            )
                        } else if (items === "restroom") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon2} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Restroom
                                    </div>
                                </div>
                            )
                        } else if (items === "shower") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon5} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Shower
                                    </div>
                                </div>
                            )
                        } else if (items === "air-condition") {
                            return (
                                <div className="flex flex-col justify-between items-center">
                                    <img src={GymTraitsIconSectionImg.gymtraitsIcon6} alt="" className="w-[9.7rem] h-[9.6rem]" />
                                    <div className="text-white text-[3rem] urbanist-medium my-[6rem]">
                                        Air Condition
                                    </div>
                                </div>
                            )
                        }
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default GymTraitsIconSection