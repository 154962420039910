import React from 'react'
import HomeLandingCrousalItem1 from './HomeLandingCarouselItem1';
import HomeLandingCrousalItem2 from './HomeLandingCarouselItem2';
import HomeLandingCrousalItem3 from './HomeLandingCarouselItem3';
import { register } from 'swiper/element/bundle';
import "swiper/css";
import 'swiper/element/css/pagination';
import "./HomeLandingCarousel.css"
import { useSelector } from 'react-redux';

function HomeLandingCarousel() {
    register();

    const offerSearchReducerData = useSelector((state) => state.offerSearchReducer?.data);
    const subscriptionOffersReducersData = useSelector((state) => state.subscriptionOffersReducers?.data);

    // const combinedArray = [...offerSearchReducerData?.genericoffers?.filter((item) => { return item?.applicableCustomerApplicationTypes[0] == 1 }), ...array2];

    const offers1 = offerSearchReducerData?.filter((item) => { return item?.applicableCustomerApplicationTypes[0] == "Website" });

    const offers2 = subscriptionOffersReducersData?.length > 0 ? subscriptionOffersReducersData?.map((item) => { return item }).filter((item) => { return item?.offers[0]?.applicableCustomerApplicationTypes[0] == "Website" && item?.offers[0]?.isBannerOffer }) : [];

    const filteredData = subscriptionOffersReducersData?.length > 0 ? subscriptionOffersReducersData?.filter(item => {
        const offer = item.offers.find(offer =>
            offer.applicableCustomerApplicationTypes.includes('Website') &&
            offer.isBannerOffer
        );
        return offer !== undefined;
    }) : [] ;

    const mergedOffers = [...offers1, ...filteredData];

    // console.log("subscriptionOffersReducersData :", subscriptionOffersReducersData);
    // console.log("offerSearchReducerData :", offerSearchReducerData);
    console.log("mergedOffers :", mergedOffers);
    console.log("filteredData :", filteredData);



    return (
        <div className='home-landing-swiper bg-[#1E1E1E]'>
            <swiper-container pagination="true" keyboard="true" autoplay-delay="5000">
                {mergedOffers?.length > 0 ?
                    mergedOffers.
                        map((genericoffersItems, index) => {
                            return (
                                <swiper-slide key={index}>
                                    <HomeLandingCrousalItem1 genericoffersItems={genericoffersItems} />
                                </swiper-slide>
                            )
                        })
                    :
                    <div className=""></div>
                }
                {/* <swiper-slide>
                    <HomeLandingCrousalItem2 />
                </swiper-slide>
                <swiper-slide>
                    <HomeLandingCrousalItem3 />
                </swiper-slide> */}
            </swiper-container>
        </div>
    )
}

export default HomeLandingCarousel
