import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { strapiPressAndMediaReset } from '../../../store/actions/StrapiServiceActions/strapiPressAndMediaAction'
import PressAndMediaBannerCard from './PressAndMediaBannerCard'

function PressAndMediaBanner() {

    const dispatch = useDispatch()

    const strapiPressAndMediaReducerData = useSelector((state) => state.strapiPressAndMediaReducer?.data);
    const strapiPressAndMediaReducerStatus = useSelector((state) => state.strapiPressAndMediaReducer?.status);

    useEffect(() => {
        if (strapiPressAndMediaReducerStatus == 200) {
            dispatch(strapiPressAndMediaReset())
        } else if (strapiPressAndMediaReducerStatus) {
            dispatch(strapiPressAndMediaReset())
        }
    }, [strapiPressAndMediaReducerStatus])

    return (
        <div className='bg-[#293C52] px-[4rem]'>
            <div className='container-lg text-white urbanist-bold my-[12rem]'>
                <h1 className='text-[8.6rem] sm:text-[9.4rem]'>
                    Press & Media
                </h1>
                <div className='flex justify-between items-start flex-wrap ml-[6rem] sm:ml-[14rem] mt-[5rem]'>
                    {strapiPressAndMediaReducerData.length > 0 ?
                        strapiPressAndMediaReducerData?.map((items) => {
                            return (
                                <PressAndMediaBannerCard
                                    content={items?.content}
                                    image={items?.image?.url}
                                    date={items?.published_at}
                                />
                            )
                        })
                        :
                        <div className="text-[4rem] leading-[4.4rem] overflow-hidden text-center w-full">
                            No Banners Available at this time
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PressAndMediaBanner
