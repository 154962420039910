import React from 'react'
import ReactPlayer from 'react-player'
import { base_url_strapi_service } from '../../../store/constants';
import { useNavigate } from 'react-router-dom';

const VideoSpecialCategory = ({ data }) => {

    const navigate = useNavigate();

    return (
        <div className='sm:mx-[20rem] mx-[2rem] flex flex-col lg:flex-row justify-center items-center mt-[10rem]'>
            <div className="w-full flex justify-center items-center lg:block lg:w-[30%] text-white">
                <div className="urbanist-extrabold bordered-font text-transparent text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem]">
                    {data[0]?.TitlePrimary}
                </div>
                <div className="urbanist-extrabold text-[#ffffff]/50 text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem]">
                    {data[0]?.TitleSecondary}
                </div>
                <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold hidden lg:block" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                    View More
                </button>
            </div>
            <div className="">
                <div className="w-[52rem] sm:w-full h-full rounded-[2.2rem] flex justify-center items-center">
                    <ReactPlayer
                        url={base_url_strapi_service + data[0]?.video?.url}
                        controls={true}
                        width={"75.1rem"}
                        light={<img src={"https://s3-alpha-sig.figma.com/img/43ca/42cb/6027395c33f6d0f28a43d464a62c3314?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HJhDsKAEyypokf4FayqNka-zR9DfTKeny18MulDL0lxTHNmUeeGIkGwUvHUxPp44oKheE8edPQiQhdHeH-94s6-am0K4cdjpwO55f~enpriVO9S5wk9Wa5lJQXi9VLY0tS~ubn7UCwOO44yJ0fSD4J71y~aFuwaXitavgLgaRTvnCVgW0gK0-C-iMvZJcM3hjlTzJZSgQPvuL6sBm~h5LW2M90bolDJh4otjHQyrly4NWxjkjM8WCXeujQj~t3fQB10O-HceooSKWurKktsdcGTsVlCOsjrATm32pCpPeCi33hL5i1gVbUlW1dzd9cYWVGhtltkdaGGFtFbRY2K-zA__"} alt='Thumbnail' className='w-full h-full rounded-[2.2rem]' />}
                        playing={true}
                        height={"38.6rem"}
                        playbackRate={1}
                        progressInterval={500}
                    />
                </div>
            </div>
            <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold lg:hidden" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                View More
            </button>
        </div>
    )
}

export default VideoSpecialCategory