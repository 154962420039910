import React, { useState } from "react";

const FloatingInput = ({ label, id, type = "text", isReq, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (e) => {
        setIsFocused(false);
        setHasValue(e.target.value !== "");
    };

    return (
        <div className="relative w-full mt-4 text-[1.5rem] urbanist-medium my-[2rem] overflow-hidden">
            <input
                id={id}
                type={type}
                className={`
                    block px-3 pb-2 pt-5 w-full bg-transparent border-b border-gray-300 appearance-none 
                    focus:outline-none focus:ring-0 focus:border-blue-500 peer 
                    ${isFocused || hasValue ? "border-blue-500" : "border-gray-300"}
                `}
                placeholder=" "
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...props}
            />
            <label
                htmlFor={id}
                className={`
                    absolute duration-300 transform -translate-y-4 scale-75 top-3 z-10 origin-[0]
                    ${ isFocused || hasValue ? "text-blue-500 scale-75 -translate-y-4" : "scale-100 translate-y-0" } 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4
                `}
            >
                {label}{isReq && <span className="text-red-600"> *</span>}
            </label>
        </div>
    );
};

export default FloatingInput;
