import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const makePayment = (payload) => ({
    type: actionTypes.MAKE_PAYMENT_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const makePaymentSuccess = (payload) => ({
    type: actionTypes.MAKE_PAYMENT_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const makePaymentFailure = (payload) => ({
    type: actionTypes.MAKE_PAYMENT_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const makePaymentReset = () => ({
    type: actionTypes.MAKE_PAYMENT_RESET,
});

// Action creator for resetting all OTP generation reducer
export const makePaymentResetAll = () => ({
    type: actionTypes.MAKE_PAYMENT_RESET_ALL,
}); 