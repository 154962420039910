import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_order_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { OrderCreateFailure, OrderCreateSuccess } from '../../actions/orderManagementActions/orderCreateActions';
import { setVerifiedPhoneNo } from '../../actions/utilsActions/utilsActions';

function* OrderCreate({ payload }) {
    try {
        // Make an API call to generate OTP
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_order_management_service,
            endPoint: endpoints.OrderCreate,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("ORDER_CREATE_SUCCESS status :", response.status);
        console.log("ORDER_CREATE_SUCCESS data :", response.data);
        console.log("ORDER_CREATE_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        // Dispatch success action with the response data
        yield put(OrderCreateSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message ? response?.message : '',
        }));
    } catch (error) {
        console.log('====================================');
        console.log("ORDER_CREATE_FAILURE error :", error?.response);
        console.log("ORDER_CREATE_FAILURE error :", error?.response?.data);
        console.log('====================================');
        // Dispatch failure action with the error message
        yield put(OrderCreateFailure({
            status: error?.response.status,
            message: error?.response?.statusText
        }));
        if(error?.response.status == 403){
            yield put(setVerifiedPhoneNo({
                number: '',
                isVerified: false
            }))
        }
    }
}

function* orderCreateSaga() {
    yield takeLatest(actionTypes.ORDER_CREATE_REQUEST, OrderCreate);
}

export default orderCreateSaga;