import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const strapiFounder = (payload) => ({
    type: actionTypes.STRAPI_FOUNDER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const strapiFounderSuccess = (payload) => ({
    type: actionTypes.STRAPI_FOUNDER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const strapiFounderFailure = (payload) => ({
    type: actionTypes.STRAPI_FOUNDER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const strapiFounderReset = () => ({
    type: actionTypes.STRAPI_FOUNDER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const strapiFounderResetAll = () => ({
    type: actionTypes.STRAPI_FOUNDER_RESET_ALL,
}); 