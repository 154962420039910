import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { communitySubCategoriesFailure, communitySubCategoriesSuccess } from '../../actions/StrapiServiceActions/communitySubCategoriesAction';

function* communitySubCategories({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.communitySubCategories + `?category.slug=community`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("COMMUNITY_SUB_CATEGORIES_SUCCESS response :", response);
        console.log("COMMUNITY_SUB_CATEGORIES_SUCCESS status :", response?.status);
        console.log("COMMUNITY_SUB_CATEGORIES_SUCCESS data :", response?.data);
        console.log("COMMUNITY_SUB_CATEGORIES_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            communitySubCategoriesSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("COMMUNITY_SUB_CATEGORIES_FAILURE status :", error?.response?.status);
        console.log("COMMUNITY_SUB_CATEGORIES_FAILURE data:", error?.response?.data);
        console.log("COMMUNITY_SUB_CATEGORIES_FAILURE error :", error);
        console.log('====================================');
        yield put(communitySubCategoriesFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* communitySubCategoriesSaga() {
    yield takeLatest(actionTypes.COMMUNITY_SUB_CATEGORIES_REQUEST, communitySubCategories);
}

export default communitySubCategoriesSaga;