import React from 'react'
import { IoIosTimer } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { NearbyGymCardImg } from '../../../static/constants/imgConstants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NearbyGymCard = ({ title, icon, heading, workoutType, students, time, image, id }) => {
    const Navigate = useNavigate()

    const getSubscriptionCategoryNameReducerData = useSelector((state) => state.getSubscriptionCategoryNameReducer?.data);


    const scrollToPackages = () => {
        // Update the URL based on the title
        const newUrl = title === "premium" ? "fit-prime" : "fit-champ";
        Navigate(`/fit-center/${newUrl}#packages`, { replace: true });

        // Scroll to the packages section
        const packagesSection = document.getElementById('packages');
        if (packagesSection) {
            packagesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='relative bg-black mx-auto mb-[4%] w-[90%] h-[52.5rem]  rounded-[2.5rem]'>
            <div className="w-full h-[60%] rounded-[2.5rem]">
                <img src={image ? image : NearbyGymCardImg.cardImg2} alt="" className="w-full h-full" />
            </div>
            <div className="absolute top-[20rem] sm:top-[20rem] lg:top-80 mt-10 left-8 flex items-center gap-5">
                <div className="w-[3.4rem] h-[3rem]">
                    <img src={icon} alt="" className="w-full h-full" />
                </div>
                <div className="text-white montserrat-bold text-[2.4rem]">
                    {title == "premium" ? getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "PREMIUM" }).map((items) => { return (items?.subscriptionCategoryShowText) }) : getSubscriptionCategoryNameReducerData?.filter((item) => { return item?.subscriptionCategoryCode == "CHAMPION" }).map((items) => { return (items?.subscriptionCategoryShowText) })}
                </div>
            </div>
            <div className="flex flex-col justify-between items-center w-full h-[40%]">
                <div className="flex justify-between items-center w-full p-5 bg-black">
                    <div className="text-[#FFFFFFE5]/90 open-sans-semibold text-[2rem]">
                        {heading}
                    </div>
                    <div className="flex justify-between items-center">
                        <IoIosTimer color='white' size={26} className='mr-3' />
                        <div className="text-[#FFFFFFE5]/90 text-[1.4rem] outfit-medium">
                            {time}
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center w-full mb-2">
                    <div className="flex justify-between items-center p-2">
                        <GoDotFill color='#FAFF00' size={16} className='mx-2' />
                        <div className="text-[#FFFFFFE5]/90 outfit-medium text-[1.6rem]">
                            {workoutType}
                        </div>
                    </div>
                    <div className="flex justify-center items-center p-2">
                        <img src={NearbyGymCardImg.students} alt="students" className='h-[2.5rem] w-[2.5rem]' />
                        <div className="outfit-medium text-[#FFFFFFE5]/90 text-[1.4rem] mx-3">
                            {students} <span className="">Students</span>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center w-full p-6 py-10 bg-[#4A494980]/50">
                    <button className="p-3 bg-[#FAFF00] rounded-[2.2rem] open-sans-semibold text-[1.6rem] px-14" onClick={() => Navigate(`/fit-center/${title}/gym/${id}`)}>
                        Explore Now
                    </button>
                    <button
                        className="p-3 bg-[#FAFF00] rounded-[2.2rem] open-sans-semibold text-[1.6rem] px-20"
                        // onClick={() => Navigate(`/fit-center/${title == "premium" ? "fit-prime" : "fit-champ"}`)}
                        onClick={() => scrollToPackages()}
                    >
                        Buy Now
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NearbyGymCard