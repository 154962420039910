import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_order_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { allOrdersFailure, allOrdersSuccess } from '../../actions/orderManagementActions/allOrdersAction';

function* allOrders({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_order_management_service,
            endPoint: endpoints.AllOrders + `?searchkey=customerId&searchvalue=${payload.userId}&sortfield=orderDate&pagesize=1000&pagenumber=1&order=1`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("ALL_ORDERS_SUCCESS status :", response.status);
        console.log("ALL_ORDERS_SUCCESS data :", response.data);
        console.log("ALL_ORDERS_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        yield put(allOrdersSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.data.message,
        }));
    } catch (error) {
        console.log('====================================');
        console.log("ALL_ORDERS_FAILURE error :", error.response?.status);
        console.log("ALL_ORDERS_FAILURE error :", error.message);
        console.log('====================================');
        yield put(allOrdersFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* allOrdersSaga() {
    yield takeLatest(actionTypes.ALL_ORDERS_REQUEST, allOrders);
}

export default allOrdersSaga;