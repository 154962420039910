import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavbarImg } from '../../../static/constants/imgConstants'
import { CiFacebook } from 'react-icons/ci'
import { PiInstagramLogoLight, PiLinkedinLogoLight } from 'react-icons/pi'
import { RxTwitterLogo } from 'react-icons/rx'
import { StatesAndCities } from "../../../static/constants/StatesAndCities"
import { useDispatch, useSelector } from 'react-redux'
import { customerProfile, customerProfileReset, customerProfileResetAll } from '../../../store/actions/CustomerManagementServiceActions/customerProfileAction'
import { Oval } from 'react-loader-spinner'
import { uploadProfilePic, uploadProfilePicReset } from '../../../store/actions/FileManagementServiceActions/uploadProfilePicAction'
import { useToast } from "../../../hooks/useToast";
import { updateProfile, updateProfileReset } from '../../../store/actions/CustomerManagementServiceActions/updateProfileAction'
import InputTag from './InputTag'
import { logout, setAccessToken, setEmail, setName, setUserId } from "../../../store/actions/utilsActions/utilsActions"
import moment from 'moment'
import { DayPicker } from 'react-day-picker';
import "react-day-picker/dist/style.css";
import { useNavigate } from 'react-router-dom'

const ProfileInfo = ({ setIsUserIconClicked }) => {

    const dispatch = useDispatch();
    const Toast = useToast()
    const navigate = useNavigate();
    const divRef = useRef(null);

    const utilReducers = useSelector((state) => state.utilReducers);
    const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);
    const CustomerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);
    const CustomerProfileReducerMessage = useSelector((state) => state.customerProfileReducer?.message);
    const CustomerProfileReducerLoading = useSelector((state) => state.customerProfileReducer?.loading);
    const uploadProfilePicReducerStatus = useSelector((state) => state.uploadProfilePicReducer?.status);
    const uploadProfilePicReducerData = useSelector((state) => state.uploadProfilePicReducer?.data);
    const uploadProfilePicReducerLoading = useSelector((state) => state.uploadProfilePicReducer?.loading);
    const updateProfileReducerStatus = useSelector((state) => state.updateProfileReducer?.status);
    const updateProfileReducerLoading = useSelector((state) => state.updateProfileReducer?.loading);

    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameRegxError, setFirstNameRegxError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameRegxError, setLastNameRegxError] = useState(false);
    const [dob, setDob] = useState(new Date());
    const [gender, setGender] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberLengthError, setPhoneNumberLengthError] = useState(false);
    const [email, setEmailAddress] = useState("");
    const [emailError, setEmailAddressError] = useState(false);
    const [emailInvalidError, setEmailInvalidError] = useState(false);
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [pincodeLengthError, setPincodeLengthError] = useState(false);
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [id, setId] = useState("");
    const [setProfilePicUrl, setSetProfilePicUrl] = useState("");

    const [showCalendar, setShowCalendar] = useState(false);

    useMemo(() => {
        if (!firstName) {
            // Toast("First Name is Mandatory")
            setFirstNameError(true);
        } else {
            setFirstNameError(false);
        }

        if (!/^[a-zA-Z]*$/.test(firstName)) {
            setFirstNameRegxError(true);
        } else {
            setFirstNameRegxError(false);
        }

        if (!lastName) {
            // Toast("Last Name is Mandatory")
            setLastNameError(true);
        } else {
            setLastNameError(false);
        }

        if (!/^[a-zA-Z]*$/.test(lastName)) {
            setLastNameRegxError(true);
        } else {
            setLastNameRegxError(false);
        }

        if (!phoneNumber) {
            // Toast("Phone Number is Mandatory")
            setPhoneNumberLengthError(false);
            setPhoneNumberError(true);
        } else if (phoneNumber?.length < 10) {
            // Toast("Phone Number is Mandatory")
            setPhoneNumberError(false);
            setPhoneNumberLengthError(true);
        } else {
            setPhoneNumberLengthError(false);
            setPhoneNumberError(false);
        }

        if (!email) {
            // Toast("Email is Mandatory")
            setEmailInvalidError(false);
            setEmailAddressError(true);
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            // Toast("Email is Mandatory")
            setEmailAddressError(false);
            setEmailInvalidError(true);
        } else {
            setEmailInvalidError(false);
            setEmailAddressError(false);
        }

        if (0 < pincode?.length && pincode?.length < 6) {
            // Toast("Email is Mandatory")
            setPincodeLengthError(true);
        } else {
            setPincodeLengthError(false);
        }
    }, [firstName, lastName, phoneNumber, email, pincode])

    /* useCallback(
        () => {
            if (!firstName && /^[a-zA-Z]*$/.test(firstName)) {
                // Toast("First Name is Mandatory")
                setFirstNameError(true);
                return
            } else {
                setFirstNameError(false);
            } if (!lastName && /^[a-zA-Z]*$/.test(lastName)) {
                // Toast("Last Name is Mandatory")
                setLastNameError(true);
                return
            } else {
                setLastNameError(false);
            }
            if (!phoneNumber) {
                // Toast("Phone Number is Mandatory")
                setPhoneNumberError(true);
                return
            } else {
                setPhoneNumberError(false);
            }
            if (!email) {
                // Toast("Email is Mandatory")
                setEmailAddressError(true);
                return
            } else {
                setEmailAddressError(false);
            }
        },
        [firstName, lastName, phoneNumber, email],
    ) */


    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append("File", file);
        formData.append("Section", "PROFILE");
        formData.append("Name", file?.name);
        formData.append("RefType", "3");
        formData.append("RefId", utilReducers?.userId);
        formData.append("IsPubliclyVisible", true);
        formData.append("Title", file?.name);
        formData.append("Description", "PHOTO");

        // console.log("file :", file);
        // console.log("formData :", formData);
        dispatch(uploadProfilePic(formData))
    }

    const handleUpdate = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\d{10}$/;

        if (!firstName) {
            // Toast("First Name is Mandatory")
            setFirstNameError(true);
            return
        } else {
            setFirstNameError(false);
        }
        if (!lastName) {
            // Toast("Last Name is Mandatory")
            setLastNameError(true);
            return
        } else {
            setLastNameError(false);
        }
        if (!phoneNumber) {
            // Toast("Phone Number is Mandatory")
            setPhoneNumberError(true);
            return
        } else {
            setPhoneNumberError(false);
        }
        if (!email) {
            // Toast("Email is Mandatory")
            setEmailAddressError(true);
            return
        } else {
            setEmailAddressError(false);
        }

        if (!/^[a-zA-Z]*$/.test(firstName)) {
            setFirstNameRegxError(true);
            return
        } else {
            setFirstNameRegxError(false);
        }

        if (!/^[a-zA-Z]*$/.test(lastName)) {
            setLastNameRegxError(true);
            return
        } else {
            setLastNameRegxError(false);
        }

        if (emailPattern.test(email) && phonePattern.test(phoneNumber)) {
            dispatch(updateProfile({
                "id": id,
                "userId": utilReducers?.userId,
                "memberCode": null,
                "firstName": firstName,
                "lastName": lastName,
                "dob": dob,
                "email": utilReducers?.email ? utilReducers?.email : email,
                "mobileNumber": {
                    "countryCode": "91",
                    "number": phoneNumber
                },
                "gender": gender === "Female" ? 2 : 1,
                "socialMediaAccounts": [
                    {
                        "socialMediaType": "Instagram",
                        "profileLink": "string"
                    }
                ],
                "profilePicURL": uploadProfilePicReducerData?.uri ? uploadProfilePicReducerData?.uri : null,
                "city": city,
                "address": address,
                "state": state,
                "pincode": pincode,
                "taggedVenueId": "",
                "isActive": true,
                "memberSince": null,
                "subscriptionStatus": "Paused",
                "onboardingFitnessDetails": [
                    {
                        "name": "string",
                        "value": [
                            "string"
                        ]
                    }
                ],
                "createdBy": null,
                "createdOn": "0001-01-01T00:00:00Z",
                "lastUpdatedBy": "669a522d7207799b51a38db1",
                "lastUpdatedOn": new Date(),
                "phone": "7571047199"
            }));
        } else {
            Toast("Please enter a valid email or mobile number");
        }

    }

    // Handle click events
    const handleClickOutside = (event) => {
        // Check if the click was outside the div
        if (divRef.current && !divRef.current.contains(event.target)) {
            setShowCalendar(false); // Close the div if the click is outside
        }
    };

    useEffect(() => {
        // Add click event listener to document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(customerProfileResetAll());
        dispatch(customerProfile(utilReducers?.userId));
    }, [])

    useEffect(() => {
        if (CustomerProfileReducerStatus == 200) {
            CustomerProfileReducerData.map((items) => {
                console.log("user name", items?.firstName + " " + items?.lastName);
                dispatch(setName(items?.firstName + " " + items?.lastName))
                setId(items.id)
                setFirstName(items?.firstName);
                setLastName(items?.lastName);
                setSetProfilePicUrl(items.profilePicURL);
                setDob(items?.dob);
                setGender(items?.gender);
                setPhoneNumber(items?.mobileNumber?.number);
                setEmailAddress(items?.email);
                dispatch(setEmail(items?.email));
                setAddress(items?.address);
                setPincode(items?.pincode);
                setState(items?.state);
                setCity(items?.city);
            })
            dispatch(customerProfileReset());
        } else if (CustomerProfileReducerStatus == 403) {
            dispatch(logout());
            navigate("/");
        }
        else if (CustomerProfileReducerStatus) {
            dispatch(customerProfileReset());
        }
    }, [CustomerProfileReducerStatus])

    useEffect(() => {
        if (uploadProfilePicReducerStatus == 200) {
            Toast("File Uploaded Successfully");
            dispatch(uploadProfilePicReset());
        } else if (uploadProfilePicReducerStatus) {
            Toast(CustomerProfileReducerMessage);
            dispatch(uploadProfilePicReset());
        }
    }, [uploadProfilePicReducerStatus])


    useEffect(() => {
        if (updateProfileReducerStatus == 200) {
            Toast("Profile Updated Successfully")
            dispatch(customerProfile(utilReducers?.userId));
            dispatch(setEmail(email));
            dispatch(updateProfileReset());
        } else if (updateProfileReducerStatus) {
            Toast("Profile Not Updated! Please try again later")
            dispatch(customerProfile(utilReducers?.userId));
            dispatch(updateProfileReset());
        }
    }, [updateProfileReducerStatus]);


    console.log("utilReducers?.email :", utilReducers?.email);

    console.log("CustomerProfileReducerData :", CustomerProfileReducerData);
    // console.log("uploadProfilePicReducerData :", uploadProfilePicReducerData);

    return (!CustomerProfileReducerLoading && !uploadProfilePicReducerLoading && !updateProfileReducerLoading ?
        <div className='bg-[#1E1E1E] p-[2rem] h-full' onClick={() => setIsUserIconClicked(false)}>
            <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
                <div className="w-[25rem] lg:w-[20%]">
                    <div className="text-white text-[2rem]">
                        Profile
                    </div>
                    <div className="">
                        <div className="w-[20rem] h-[20rem] bg-[#616161D4]/80 rounded-full my-5 flex justify-center items-center">
                            <img src={uploadProfilePicReducerData?.uri ? uploadProfilePicReducerData?.uri : setProfilePicUrl ? setProfilePicUrl : NavbarImg.Exercise} alt="" className="w-full h-full" />
                        </div>
                        <div className="flex justify-center w-[20rem]">
                            <label htmlFor="file-upload" className="rounded-[2.5rem] open-sans-semibold p-5 px-[3rem] text-[1.6rem] text-black bg-[#FFF200] cursor-pointer">
                                Browse
                            </label>
                            <input id="file-upload" type="file" className="hidden" onChange={(e) => handleUpload(e.target.files[0])} />
                        </div>
                    </div>
                </div>
                <div className="w-[80%] relative">
                    <div className="w-full flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">First Name <span className="text-[#FF0000]">*</span></label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem] mb-[0.5rem]">
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="w-full bg-black outline-none text-[1.6rem] text-white" placeholder="First Name" />
                            </div>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{firstNameError ? "First Name is Mandatory" : firstNameRegxError ? "Characters and Numbers are not allowed" : ""}</span>
                        </div>
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Last Name <span className="text-[#FF0000]">*</span></label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem]">
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-full bg-black outline-none text-[1.6rem] text-white" placeholder="Last Name" />
                            </div>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{lastNameError ? "Last Name is Mandatory" : lastNameRegxError ? "Characters and Numbers are not allowed" : ""}</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Date Of Birth</label>
                            <div ref={divRef} className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.2rem]">
                                {/* <input type="date" value={dob ? moment(dob).format("yyyy mm DD") : ""} onChange={(e) => setDob(e.target.value)} className="w-full bg-black outline-none text-[1.6rem] text-white" /> */}
                                <div className="text-[1.6rem] text-white w-full bg-black" onClick={() => setShowCalendar(!showCalendar)}>
                                    {dob ? moment(dob).format("DD-M-YYYY") : "DD-MM-YYYY"}
                                </div>
                                {showCalendar ?
                                    <DayPicker
                                        mode="single"
                                        selected={new Date(dob)}
                                        onSelect={(val) => { setDob(val); setShowCalendar(false) }}
                                        className='bg-black text-white urbanist-medium text-[1.6rem] rounded-[1.3rem] date-picker absolute z-20'
                                        modifiersStyles={{
                                            selected: {
                                                backgroundColor: "#1E1E1E",
                                                color: "black",
                                            },
                                        }}
                                        fromYear={1950}
                                        toYear={new Date().getFullYear()}
                                        captionLayout="dropdown"
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Gender</label>
                            <div className="border border-white rounded-[1.5rem] bg-black text-white px-[1rem] py-[1.3rem]">
                                <select name="" id="" value={gender} onChange={(e) => setGender(e.target.value)} className="w-full bg-black outline-none text-[1.6rem]">
                                    <option value={0} className="" selected>Choose Option</option>
                                    <option value="Male" className="">Male</option>
                                    <option value="Female" className="">Female</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Phone Number <span className="text-[#FF0000]">*</span></label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem] mb-[0.5rem] flex justify-start items-center relative">
                                <div className="w-[10%] h-full bg-[#1E1E1E] absolute left-0 text-white text-[1.6rem] flex justify-center items-center">+91</div>
                                <input
                                    type="number"
                                    disabled={utilReducers?.phoneNumber?.number} value={phoneNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""));
                                        }
                                    }}
                                    className="w-full px-[6rem] bg-black outline-none text-[1.6rem] text-white"
                                    placeholder='Phone Number'
                                />
                            </div>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{phoneNumberError ? "Phone Number is Mandatory" : ""}</span>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{phoneNumberLengthError ? "Invalid Phone Number" : ""}</span>
                        </div>
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Email <span className="text-[#FF0000]">*</span></label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem]">
                                <input type="text" disabled={utilReducers?.email} value={email ? email : utilReducers?.email} onChange={(e) => setEmailAddress(e.target.value)} className="w-full bg-black outline-none text-[1.6rem] text-white" placeholder="user1@gmail.com" />
                            </div>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{emailError ? "Email is Mandatory" : ""}</span>
                            <span className="text-[#FF0000] text-[1.2rem] mx-2">{emailInvalidError ? "Invalid Email" : ""}</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Address Line 1</label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem]">
                                <input type="text" value={address} onChange={(e) => {setAddress(e.target.value)}}
                                    maxLength={50}
                                    className="w-full bg-black outline-none text-[1.6rem] text-white"
                                    placeholder="Address Line 1" />
                            </div>
                        </div>
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Pincode</label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem]">
                                <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)}
                                    maxLength={6}
                                    className="w-full bg-black outline-none text-[1.6rem] text-white"
                                    placeholder="470002" />
                            </div>
                            <span
                                className="text-[#FF0000] text-[1.2rem] mx-2">{pincodeLengthError ? "Pin Code must be 6 Digits" : ""}</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">State</label>
                            <div className="border border-white rounded-[1.5rem] bg-black text-white px-[1rem] py-[1.3rem]">
                                <select name="" id="" value={state} onChange={(e) => setState(e.target.value)} className="w-full bg-black outline-none text-[1.6rem]">
                                    <option value="" className="" selected>Select State</option>
                                    {StatesAndCities.states.map((states) => {
                                        return (
                                            <option value={states.name} className="">{states.name}</option>
                                        )
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="w-full lg:w-[48%] my-[1rem]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">City</label>
                            <div className="border border-white rounded-[1.5rem] bg-black text-white px-[1rem] py-[1.3rem]">
                                <select name="" id="" value={city} onChange={(e) => setCity(e.target.value)} className="w-full bg-black outline-none text-[1.6rem]" disabled={!state}>
                                    <option value="" className="" selected>Choose Option</option>
                                    {StatesAndCities.states.filter((item) => item.name === state)?.map((array) => array.districts?.map((cities) => {
                                        return (
                                            <option value={cities.name} className="">{cities.name}</option>
                                        )
                                    }))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="w-full my-[1rem]">
                        <div className="text-white text-[2rem]">Social Media Accounts</div>
                        <div className='flex justify-start items-center my-[1rem]'>
                            <a href='https://www.facebook.com/people/Fitzone/61561375006743/' target='_blank' className="">
                                <CiFacebook className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                            <a href="https://www.instagram.com/fitzone.life/" target='_blank' className="">
                                <PiInstagramLogoLight className='mr-[2rem]' size={25} color={'#fff'} />

                            </a>
                            <a href="https://www.linkedin.com/company/fitzone-life/about/?viewAsMember=true" target='_blank' className="">
                                <PiLinkedinLogoLight className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                            <a href="https://x.com/fitzone_life/" target='_blank' className="">
                                <RxTwitterLogo className='mr-[2rem]' size={25} color={'#fff'} />
                            </a>
                        </div>
                        <button
                            className="rounded-[2.5rem] open-sans-semibold p-5 px-[3rem] text-[1.6rem] text-black bg-[#FFF200]"
                            onClick={() => handleUpdate()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="flex justify-center items-center w-full h-[50rem]">
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#FAFF00"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}

export default ProfileInfo
