import React from 'react';
import Slider from "react-slick";
import CheckoutCategoriesCard from './CheckoutCategoryCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';

const CheckoutCategories = ({ categories }) => {

    const navigate = useNavigate()

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} />
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    console.log("CheckoutCategories categories", categories);


    return (
        <div className="bg-[#C43045] px-[4rem] my-[4rem]">
            <div className="container-lg py-[6rem] sm:py-[8rem] lg:py-[12rem]">
                <div className="text-white text-[6rem] md:text-[9rem] lg:text-[13.3rem] leading-[7rem] md:leading-[10.5rem] lg:leading-[15rem] urbanist-bold overflow-hidden text-center lg:text-left">
                    <span className="bordered-font text-transparent">Checkout <br className='block lg:hidden' /></span> our Categories
                </div>
                {categories?.length > 0 ?
                    <Slider {...settings} className="relative overflow-hidden my-[3rem]">
                        {categories?.map((category, index) => (
                            <div key={index} className="px-[2rem] flex justify-center items-center cursor-pointer" onClick={() => navigate(`/community/${category?.sub_category_name}`)}>
                                <CheckoutCategoriesCard
                                    image={category?.Image[0]?.url}
                                    title={category?.sub_category_name}
                                />
                            </div>
                        ))}
                    </Slider>
                    :
                    <div className="text-center text-white text-[3rem]">
                        No Categories Available
                    </div>
                }
            </div>
        </div>
    );
};

export default CheckoutCategories;
