import { actionTypes } from '../../types';

export const contactUs = (payload) => ({
    type: actionTypes.CONTACT_US_REQUEST,
    payload,
});

export const contactUsSuccess = (payload) => ({
    type: actionTypes.CONTACT_US_SUCCESS,
    payload,
});

export const contactUsFailure = (payload) => ({
    type: actionTypes.CONTACT_US_FAILURE,
    payload,
});

export const contactUsReset = () => ({
    type: actionTypes.CONTACT_US_RESET,
});

export const contactUsResetAll = () => ({
    type: actionTypes.CONTACT_US_RESET_ALL,
}); 