import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_reward_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { fitcoinBalanceFailure, fitcoinBalanceSuccess } from '../../actions/RewardManagementServiceActions/fitcoinBalanceAction';

function* fitcoinBalance({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_reward_management_service,
            endPoint: endpoints.fitcoinBalance + `${`?customerid=${payload.userId}&pagesize=10&pagenumber=1&order=1`}`,
            isAuth: true
        });

        console.log('====================================');
        console.log("FITCOIN_BALANCE_SUCCESS response :", response);
        console.log("FITCOIN_BALANCE_SUCCESS status :", response?.status);
        console.log("FITCOIN_BALANCE_SUCCESS data :", response?.data);
        console.log("FITCOIN_BALANCE_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            fitcoinBalanceSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("FITCOIN_BALANCE_FAILURE status :", error?.response?.status);
        console.log("FITCOIN_BALANCE_FAILURE data:", error?.response?.data);
        console.log("FITCOIN_BALANCE_FAILURE error :", error);
        console.log('====================================');
        yield put(fitcoinBalanceFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* fitcoinBalanceSaga() {
    yield takeLatest(actionTypes.FITCOIN_BALANCE_REQUEST, fitcoinBalance);
}

export default fitcoinBalanceSaga;