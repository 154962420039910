import ReactPlayer from 'react-player'
import { VideoPlayer4Img } from '../../../static/constants/imgConstants';
import { useState } from 'react';

const VideoPlayer4 = () => {
    const [hideFloatedTextBox, setHideFloatedTextBox] = useState(false);
    return (
        <div className='bg-[#1E1E1E] px-[4rem] flex justify-start items-start md:justify-center md:items-center py-[3rem] relative'>
            <div className={`flex flex-col absolute top-[35rem] md:top-[25rem] left-[4rem] md:left-[5rem] lg:left-[10rem] xl:left-[15rem] 2xl:left-[30rem] ${hideFloatedTextBox ? "hidden" : ""}`}>
                <div className="bg-[#FAFAFA] px-[1rem] py-[1.5rem] text-center rounded-[2rem]">
                    <div className="text-[#486077] my-[.3rem] urbanist-semibold text-[1.6rem]">Hatha Yoga</div>
                    <div className="text-[#4E4E4E] mb-[0.8rem] urbanist-regular text-[1.4rem]">45 min · 6 pm</div>
                    <div className="text-[#4E4E4E] urbanist-light text-[1.2rem] text-center w-[85%] ml-3">Body Mechanics Sector 56</div>
                    <div className="text-[#4E4E4E] mt-[2.8rem] urbanist-medium text-[1.2rem]">Free Trial!</div>
                </div>
                <div className="bg-[#FAFAFA] rounded-[2rem] my-[1.3rem] px-[1.3rem] py-[2.3rem]">
                    <div className="py-[1rem]">
                        <div className="urbanist-semibold text-[1.6rem]">
                            YOGA
                        </div>
                        <div className="urbanist-light text-[1.4rem] my-[0.3rem]">
                            Thursday, 6:00 pm
                        </div>
                        <div className="">
                            Body Mechanics
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start py-[3.2rem]">
                        <img src={VideoPlayer4Img.progressBar} alt="" className="w-[8rem] h-full" />
                        <img src={VideoPlayer4Img.ratingReview} alt="" className="w-[10.5rem] h-full" />
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <div className="text-[#b3b3b3] text-[6rem] text-start md:text-center my-[2rem] urbanist-semibold w-full">
                    Your Fitness Journey Starts Here
                </div>
                <div className="">
                    <ReactPlayer
                        url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
                        light={<img src={VideoPlayer4Img.videoPlayer4CoverImg} alt='Thumbnail' className='w-full h-[54.8rem]' />}
                        controls
                        width={"100%"}
                        playing={true}
                        height={500}
                        onStart={() => setHideFloatedTextBox(true)}
                    />
                </div>
                <div className={`w-full flex justify-center items-center my-5 ${hideFloatedTextBox ? "hidden" : ""}`}>
                    <a href="https://play.google.com/store/apps" target='_blank' className="">
                        <button className="mt-0 p-3 bg-[#FAFF00] rounded-full font-semibold text-[1.7rem] text-[#486077] px-8">Get Started</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer4