import React, { useEffect, useState } from 'react'
import "./BlogAndNews.css"
import BlogAndNewsCard from './BlogAndNewsCard'
import { BlogAndNewsImg } from '../../../static/constants/imgConstants'
import "swiper/css";
import Footer1 from '../Footer1/Footer1'
import { useDispatch, useSelector } from 'react-redux';
import { strapiArticlesReset } from '../../../store/actions/StrapiServiceActions/strapiArticlesAction';

const BlogAndNews = () => {

    const [screenSize] = useState(window.innerWidth);
    const dispatch = useDispatch();

    const strapiArticlesReducerData = useSelector((state) => state.strapiArticlesReducer?.data);
    const strapiArticlesReducerStatus = useSelector((state) => state.strapiArticlesReducer?.status);


    useEffect(() => {
        if (strapiArticlesReducerStatus == 200) {
            dispatch(strapiArticlesReset());
        } else if (strapiArticlesReducerStatus) {
            dispatch(strapiArticlesReset());
        }
    }, [strapiArticlesReducerStatus])

    return (
        <div className='blog-and-news px-[4rem]'>
            <div className="container-lg">
                <div className="montserrat-bold text-white text-[4rem] sm:text-[6.4rem] text-center my-10">
                    Blog & News
                </div>
                <div className="flex justify-center items-center">
                    <p className="outfit-medium text-[#FFFFFFCC]/80 text-[2rem] w-[80%] sm:w-[50%] sm:text-center text-justify">
                        Dive into expert tips, the latest trends, and motivating stories that will elevate your workout routine and wellness journey. Stay informed and empowered with fresh insights and practical advice to help you achieve your goals and live your best life.
                    </p>
                </div>
                <div className="flex justify-between items-center my-[10.2rem]">
                    {screenSize > 1400 ?
                        strapiArticlesReducerData.length > 0 ?
                            strapiArticlesReducerData.map((items) => {
                                return (
                                    <div className="flex justify-center items-center">
                                        <BlogAndNewsCard
                                            image={items.image?.url}
                                            title={items.title}
                                            // type={items.type}
                                            details={items.description}
                                        />
                                    </div>
                                )
                            })
                            :
                            <div className="text-white p-3 orbitron-bold text-[3rem] mx-auto text-center w-[80%] sm:w-[50%]">
                                No Blogs Available at this time
                            </div>
                        :
                        strapiArticlesReducerData.length > 0 ?
                            <swiper-container pagination="true">
                                {
                                    strapiArticlesReducerData.map((items) => {
                                        return (
                                            <swiper-slide>
                                                <div className="flex justify-center items-center">
                                                    <BlogAndNewsCard
                                                        image={items.image?.url}
                                                        title={items.title}
                                                        // type={items.type}
                                                        details={items.description}
                                                    />
                                                </div>
                                            </swiper-slide>
                                        )
                                    })
                                }
                            </swiper-container>
                            :
                            <div className="text-white orbitron-bold text-[4rem] mx-auto text-center w-[80%] sm:w-[50%]">
                                No Blogs Available at this time
                            </div>
                    }
                </div>
                {/* <div className="flex justify-center items-center">
                    <p className="outfit-medium text-[#FFFFFFCC]/80 text-[2rem] w-[80%] sm:w-[50%] sm:text-center text-justify">
                        Join us today and experience the transformative power of our fitness classes. Don't wait to start your
                        fitness journey. Take the first step towards a healthier, stronger you. Let's sweat, have fun, and make
                        fitness a way of life together!
                    </p>
                </div> */}
                <div className="my-[10rem]">
                    <div className="flex justify-center items-center">
                        <Footer1 />
                    </div>
                    <div className="text-[#FFFFFFB2]/70 text-center my-5 text-[1.6rem] outfit-medium">
                        &copy; FITZONE. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogAndNews