import React from "react";
import "./AboutUsLanding.css";

function AboutUsLanding() {
  return (
    <div className="about-us-landing w-full">
      <div className="container-lg flex flex-col text-white justify-end md:justify-end items-center md:items-start h-[80%] md:h-full">
        <div className="text-[6.4rem] md:text-[11rem] urbanist-semibold flex flex-col leading-none overflow-hidden w-[60%]">
          +5000
          <span className="text-[5.5rem] md:text-[12.4rem] overflow-hidden">
            Partner Gyms
          </span>
          <span className="text-[5rem] md:text-[11rem] overflow-hidden flex justify-between items-center mb-[2rem]">
            <div className="ml-5 text-transparent hidden md:block overflow-hidden">-</div>are in love with us
          </span>
        </div>
      </div>
    </div>
  );
}

export default AboutUsLanding;
