import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Membership from '../../components/main/Membership/Membership'
import NearbyGym from '../../components/common/NearbyGym/NearbyGym'
import VideoPlayer1 from '../../components/common/VideoPlayer1/VideoPlayer1'
import HomeFitness from '../../components/common/HomeFitness/HomeFitness'
import Estore from '../../components/common/Estore/Estore'
import VideoPlayer2 from '../../components/common/VideoPlayer2/VideoPlayer2'
import ClientFeedback from '../../components/common/ClientFeedback/ClientFeedback'
import DownloadApp from '../../components/common/DownloadApp/DownloadApp'
import VideoPlayer3 from '../../components/common/VideoPlayer3/VideoPlayer3'
import BlogAndNews from '../../components/common/BlogAndNews/BlogAndNews'
import TrainerOverview from '../../components/common/TrainerOverview/TrainerOverview'
import HomeLandingCarousel from '../../components/main/HomeLandingCarousel/HomeLandingCarousel'

import { customerProfile, customerProfileReset } from '../../store/actions/CustomerManagementServiceActions/customerProfileAction'
import { strapiToken, strapiTokenReset } from '../../store/actions/StrapiServiceActions/strapiTokenAction'
import { setJwtToken, setName, setUpdateProfile } from '../../store/actions/utilsActions/utilsActions'
import { strapiArticles } from '../../store/actions/StrapiServiceActions/strapiArticlesAction'
import { strapiClientFeedback } from '../../store/actions/StrapiServiceActions/strapiClientFeedbackAction'
import { venueStaff } from '../../store/actions/VenueStaffManagementServiceActions/venueStaffAction'
import { searchByLocation } from '../../store/actions/venueManagementServiceActions/searchByLocationActions'
import { offerSearch } from '../../store/actions/SubscriptionManagementActions/offerSearchAction'
import { subscriptionOffers } from '../../store/actions/SubscriptionManagementActions/subscriptionOffersAction'
import { getSubscriptionCategoryName } from '../../store/actions/SubscriptionManagementActions/getSubscriptionCategoryNameAction'
import UserProfilePopup from '../../components/common/UserProfilePopup/UserProfilePopup'
import { filehandler } from '../../store/actions/FileManagementServiceActions/filehandlerAction'

function Home() {
    const dispatch = useDispatch();
    const hasShownProfileToast = useRef(false);

    const [userProfilePopup, setUserProfilePopup] = useState(false);

    const utilReducers = useSelector((state) => state.utilReducers);
    const strapiTokenReducerData = useSelector((state) => state.strapiTokenReducer?.data);
    const strapiTokenReducerStatus = useSelector((state) => state.strapiTokenReducer?.status);
    const customerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);
    const customerProfileReducerStatus = useSelector((state) => state.customerProfileReducer?.status);
    const searchByLocationReducersData = useSelector((state) => state.searchByLocationReducers?.data);

    const subscriptionOffersReducersData = useSelector((state) => state.subscriptionOffersReducers?.data);
    const searchByLocationReducersStatus = useSelector((state) => state.searchByLocationReducers?.status);
    const searchByLocationReducersLoading = useSelector((state) => state.searchByLocationReducers?.loading);
    const getSubscriptionCategoryNameReducerData = useSelector((state) => state.getSubscriptionCategoryNameReducer?.data);

    const latLong = {
        lat: "12.887",
        long: "77.639"
    }

    useEffect(() => {
        dispatch(customerProfile(utilReducers?.userId))
        dispatch(venueStaff({ venueId: '' }));
        dispatch(strapiToken({
            "identifier": "api",
            "password": "123@fitzone"
        }))
        dispatch(searchByLocation({ lat: latLong.lat, long: latLong.long }));
        dispatch(offerSearch());
        dispatch(subscriptionOffers());
        dispatch(getSubscriptionCategoryName());
        dispatch(filehandler({ venueId: !searchByLocationReducersLoading && searchByLocationReducersStatus == 200 && searchByLocationReducersData?.venues?.length > 0 ? searchByLocationReducersData?.venues[0]?.id : "" }))
    }, [])

    useEffect(() => {
        if (utilReducers?.setUpdateProfile) {
            if (customerProfileReducerStatus == 200) {
                const customerProfile = customerProfileReducerData?.[0];

                if ((!customerProfile?.mobileNumber?.number || !customerProfile?.email) && !hasShownProfileToast.current) {
                    setUserProfilePopup(true);

                }
            }
        }
    }, [customerProfileReducerStatus, utilReducers?.setUpdateProfile])


    useEffect(() => {
        if (strapiTokenReducerStatus == 200) {
            dispatch(setJwtToken(strapiTokenReducerData?.jwt))
            dispatch(strapiArticles());
            dispatch(strapiClientFeedback());
            dispatch(strapiTokenReset());
        } else if (strapiTokenReducerStatus) {
            dispatch(strapiTokenReset());
        }
    }, [strapiTokenReducerStatus])

    useEffect(() => {
        if (customerProfileReducerStatus == 200) {
            let name = customerProfileReducerData.map((items) => { return items.firstName + " " + items.lastName })
            dispatch(setName(name))
            dispatch(customerProfileReset())
        } else if (customerProfileReducerStatus) {
            dispatch(customerProfileReset());
        }
    }, [customerProfileReducerStatus])

    // console.log("subscriptionOffersReducersData :", subscriptionOffersReducersData);


    console.log("utilReducers?.setUpdateProfile :", utilReducers?.setUpdateProfile);



    return (
        <div className=''>
            <div className='home-bg'>
                <HomeLandingCarousel />
                <Membership isHome />
                <NearbyGym />
            </div>
            <div className="bg-black">
                <VideoPlayer1 />
            </div>
            <div className='home-bg-2'>
                <HomeFitness />
                <Estore />
                <VideoPlayer2 />
                <TrainerOverview />
            </div>
            <ClientFeedback />
            <DownloadApp />
            <VideoPlayer3
                gymId={!searchByLocationReducersLoading && searchByLocationReducersStatus == 200 && searchByLocationReducersData?.venues?.length > 0 ? searchByLocationReducersData?.venues[0]?.id : ""}
                isHome
                showFooter
                showTagLine
            />
            <BlogAndNews />
            <UserProfilePopup show={userProfilePopup && utilReducers?.setUpdateProfile} onClose={() => { setUserProfilePopup(false) }} setUserProfilePopup={setUserProfilePopup} />
        </div>
    )
}

export default Home
