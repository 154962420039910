import { actionTypes } from '../../types';

export const updateProfile = (payload) => ({
    type: actionTypes.UPDATE_PROFILE_REQUEST,
    payload,
});

export const updateProfileSuccess = (payload) => ({
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    payload,
});

export const updateProfileFailure = (payload) => ({
    type: actionTypes.UPDATE_PROFILE_FAILURE,
    payload,
});

export const updateProfileReset = () => ({
    type: actionTypes.UPDATE_PROFILE_RESET,
});

export const updateProfileResetAll = () => ({
    type: actionTypes.UPDATE_PROFILE_RESET_ALL,
}); 