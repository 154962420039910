export const migBoxData = [
    {
        title: 'Track your fitness journey',
        info: 'Stay motivated and track progress with advanced tools'
    },
    {
        title: 'Fitness assessment',
        info: 'Begin right with a comprehensive fitness assessment'
    },
    {
        title: 'Personalised training',
        info: 'Experience personalized workout plans guided by expert trainers'
    },
    {
        title: 'Group sessions',
        info: 'Join dynamic group sessions with experienced trainers for community-driven fitness.'
    },
    {
        title: 'Earn badges',
        info: 'Workout daily and earn badges after completing every goal'
    },
    {
        title: 'Fitness store',
        info: 'Optimize results with one-on-one sessions from expert trainers'
    },
    {
        title: 'One-on-one sessions',
        info: 'Optimize results with one-on-one sessions from expert trainers'
    },
    {
        title: 'Choose your theme',
        info: 'Personalize your fitness experience with a themed interface'
    },
    {
        title: 'Fitness blogs',
        info: 'Access a wealth of fitness knowledge through our blog'
    },
]