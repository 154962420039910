import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_file_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { getLeaderBoardFileFailure, getLeaderBoardFileSuccess } from '../../actions/FileManagementServiceActions/getLeaderBoardFileAction';

function* getLeaderBoardFile({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_file_management_service,
            endPoint: endpoints.getLeaderBoardFile,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("GET_LEADER_BOARD_FILE_SUCCESS status :", response.status);
        console.log("GET_LEADER_BOARD_FILE_SUCCESS data :", response.data);
        console.log("GET_LEADER_BOARD_FILE_SUCCESS message :", response.data.statusmessage);
        console.log('====================================');
        yield put(getLeaderBoardFileSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.status
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("GET_LEADER_BOARD_FILE_FAILURE error :", error.response?.status);
        console.log("GET_LEADER_BOARD_FILE_FAILURE message :", error.message.statusmessage);
        console.log("GET_LEADER_BOARD_FILE_FAILURE error :", error);
        console.log('====================================');
        yield put(getLeaderBoardFileFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* getLeaderBoardFileSaga() {
    yield takeLatest(actionTypes.GET_LEADER_BOARD_FILE_REQUEST, getLeaderBoardFile);
}

export default getLeaderBoardFileSaga;