import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_file_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { filehandlerFailure, filehandlerSuccess } from '../../actions/FileManagementServiceActions/filehandlerAction';

function* filehandler({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_file_management_service,
            endPoint: endpoints.filehandler + `?RefId=${payload.venueId}&RefType=1`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("FILEHNADLER_SUCCESS status :", response.status);
        console.log("FILEHNADLER_SUCCESS data :", response.data);
        console.log("FILEHNADLER_SUCCESS message :", response.data.statusmessage);
        console.log('====================================');
        yield put(filehandlerSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.status
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("FILEHNADLER_FAILURE error :", error.response?.status);
        console.log("FILEHNADLER_FAILURE message :", error.message.statusmessage);
        console.log("FILEHNADLER_FAILURE error :", error);
        console.log('====================================');
        yield put(filehandlerFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* filehandlerSaga() {
    yield takeLatest(actionTypes.FILEHNADLER_REQUEST, filehandler);
}

export default filehandlerSaga;