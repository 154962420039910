import React from 'react';
import { CommunityImg } from "../../../static/constants/imgConstants";
import { useNavigate } from 'react-router-dom';
import { base_url_strapi_service } from '../../../store/constants';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const CommunityLanding = ({ CommunityLandingData }) => {
    const Navigate = useNavigate()

    const communityReducerLoading = useSelector((state) => state.communityReducer?.loading);

    console.log("CommunityLandingData :", CommunityLandingData);


    return (
        <div className="px-[4rem] my-[10rem]">
            {!communityReducerLoading ?
                <div className="container-lg flex flex-col lg:flex-row items-start justify-center lg:justify-between">
                    <div className="w-full lg:w-[55%] mb-8 lg:mb-0  text-center lg:text-left urbanist-extrabold">
                        <div className="text-[6rem] md:text-[9rem] lg:text-[13.3rem] leading-[7rem] md:leading-[10.5rem] lg:leading-[15rem] overflow-hidden">
                            <span className='bordered-font text-transparent'>{CommunityLandingData?.TitlePrimary}</span>
                        </div>
                        <span className=" text-[6rem] md:text-[9rem] lg:text-[13.3rem] leading-[7rem] md:leading-[10.5rem] lg:leading-[15rem] text-[#FAFF00]">
                            {CommunityLandingData?.TitleSecondary}
                        </span>
                        <p className="urbanist-medium text-[2rem] w-full sm:text-[3rem] md:text-[3.5rem] lg:w-full lg:text-[4rem] text-white my-[1rem] md:my-[2rem] lg:my-[3rem]">
                            {CommunityLandingData?.description}
                        </p>
                        <button
                            className='hidden lg:block bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'
                            onClick={() => { Navigate(`/community/blogs/${CommunityLandingData?.sub_category?.slug}`, { state: { data: CommunityLandingData } }) }}
                        >
                            Read Now
                        </button>
                    </div>
                    <div className="w-full lg:w-[40%] h-full flex justify-center relative">
                        <img
                            className="w-[35.5rem] h-[37.6rem] md:w-[70%] lg:w-[73.5rem] lg:h-[69.6rem] object-cover rounded-[1rem]"
                            src={base_url_strapi_service + CommunityLandingData?.image?.url}
                            alt="App Banner"
                        />
                        <button
                            className='lg:hidden bg-[#FAFF00] absolute bottom-[1rem] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'
                            onClick={() => { Navigate(`/community/blogs/${CommunityLandingData?.sub_category?.slug}`, { state: { data: CommunityLandingData } }) }}
                        >
                            Read Now
                        </button>
                    </div>
                </div>
                :
                <div className="flex justify-center items-center w-full h-[40rem]">
                    <Oval
                        visible={true}
                        height="50"
                        width="50"
                        color="#000000"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            }
        </div>
    );
};

export default CommunityLanding;
