import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { strapiClientFeedbackFailure, strapiClientFeedbackSuccess } from '../../actions/StrapiServiceActions/strapiClientFeedbackAction';

function* strapiClientFeedback({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiArticles + `?category.slug=clients_feedback`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_CLIENT_FEEDBACK_SUCCESS response :", response);
        console.log("STRAPI_CLIENT_FEEDBACK_SUCCESS status :", response?.status);
        console.log("STRAPI_CLIENT_FEEDBACK_SUCCESS data :", response?.data);
        console.log("STRAPI_CLIENT_FEEDBACK_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            strapiClientFeedbackSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_CLIENT_FEEDBACK_FAILURE status :", error?.response?.status);
        console.log("STRAPI_CLIENT_FEEDBACK_FAILURE data:", error?.response?.data);
        console.log("STRAPI_CLIENT_FEEDBACK_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiClientFeedbackFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* strapiClientFeedbackSaga() {
    yield takeLatest(actionTypes.STRAPI_CLIENT_FEEDBACK_REQUEST, strapiClientFeedback);
}

export default strapiClientFeedbackSaga;