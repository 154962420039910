import React from 'react';
import { base_url_strapi_service } from '../../../store/constants';

function FitTeamCard({ member }) {
    return (
        <div className="w-full fit-team-card bg-[#1E1E1E] rounded-xl p-4 text-center">
            <div className=''>
                <img
                    src={`${base_url_strapi_service}${member.image.url}`}
                    alt={member.title}
                    className="w-full rounded-lg mb-4 h-full"
                />
            </div>
            <h3 className="text-[3rem] mb-2 text-left urbanist-extrabold">{member.title}</h3>
            <pre className="text-gray-400 text-[2rem] text-left urbanist-regular">{member.content}</pre>
        </div>
    );
}

export default FitTeamCard;