import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_user_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { getReferralCodeFailure, getReferralCodeSuccess } from '../../actions/authActions/getReferralCodeAction';

function* getReferralCode({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_user_management_service,
            endPoint: endpoints.getReferralCode + `?userId=${payload.userId}`,
            isAuth: true,
        });
        console.log('====================================');
        console.log("GET_REFERRAL_CODE_SUCCESS status :", response.status);
        console.log("GET_REFERRAL_CODE_SUCCESS data :", response.data);
        console.log("GET_REFERRAL_CODE_SUCCESS message :", response.data.statusmessage);
        console.log('====================================');
        yield put(getReferralCodeSuccess({
            status: response?.status,
            data: response?.data
        }));
    } catch (error) {
        console.log('====================================');
        console.log("GET_REFERRAL_CODE_FAILURE status :", error.response?.status);
        console.log("GET_REFERRAL_CODE_FAILURE error :", error.response);
        console.log("GET_REFERRAL_CODE_FAILURE message :", error.response.statusmessage);
        console.log('====================================');
        yield put(getReferralCodeFailure({
            status: error.response?.status,
            message: error.message
        }));
    }
}

function* getReferralCodeSaga() {
    yield takeLatest(actionTypes.GET_REFERRAL_CODE_REQUEST, getReferralCode);
}

export default getReferralCodeSaga;