import { actionTypes } from '../../types';

export const trainerRating = (payload) => ({
    type: actionTypes.TRAINER_RATING_REQUEST,
    payload,
});

export const trainerRatingSuccess = (payload) => ({
    type: actionTypes.TRAINER_RATING_SUCCESS,
    payload,
});

export const trainerRatingFailure = (payload) => ({
    type: actionTypes.TRAINER_RATING_FAILURE,
    payload,
});

export const trainerRatingReset = () => ({
    type: actionTypes.TRAINER_RATING_RESET,
});

export const trainerRatingResetAll = () => ({
    type: actionTypes.TRAINER_RATING_RESET_ALL,
}); 