import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_order_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { OrderTransactionStatusFailure, OrderTransactionStatusSuccess } from '../../actions/orderManagementActions/orderTransactionStatusActions';

function* OrderTransactionStatus({ payload, callback }) {
    try {
        // Make an API call to generate OTP
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_order_management_service,
            endPoint: endpoints.OrderTransactionStatus,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("ORDER_TRANSACTION_STATUS_SUCCESS status :", response.status);
        console.log("ORDER_TRANSACTION_STATUS_SUCCESS data :", response.data);
        console.log("ORDER_TRANSACTION_STATUS_SUCCESS message :", response?.data?.message);
        console.log('====================================');
        // Dispatch success action with the response data
        yield put(OrderTransactionStatusSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message ? response?.message : '',
        }));
        if (callback) {
            callback(
                response?.status,
                response?.data,
                response?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("ORDER_TRANSACTION_STATUS_FAILURE error :", error?.response);
        console.log("ORDER_TRANSACTION_STATUS_FAILURE error :", error?.response?.data);
        console.log('====================================');
        // Dispatch failure action with the error message
        yield put(OrderTransactionStatusFailure({
            status: error?.response?.data?.statuscode,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* orderTransactionStatusSaga() {
    yield takeLatest(actionTypes.ORDER_TRANSACTION_STATUS_REQUEST, OrderTransactionStatus);
}

export default orderTransactionStatusSaga;