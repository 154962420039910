import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReferralCode, getReferralCodeReset } from '../../../store/actions/authActions/getReferralCodeAction';
import { Oval } from 'react-loader-spinner';
import { referFriend, referFriendReset } from '../../../store/actions/authActions/referFriendAction';
import { useToast } from '../../../hooks/useToast';
import { fitcoinhistory, fitcoinhistoryReset } from '../../../store/actions/RewardManagementServiceActions/fitcoinhistoryAction';
import moment from 'moment';

const ReferalsCoupons = () => {

    const dispatch = useDispatch()
    const Toast = useToast()
    const hasShownProfileToast = useRef(false);

    const [code, setCode] = useState("GAI123")
    const [referMail, setReferMail] = useState("");

    const utilReducers = useSelector((state) => state.utilReducers);
    const getReferralCodeReducerData = useSelector((state) => state.getReferralCodeReducer?.data);
    const getReferralCodeReducerStatus = useSelector((state) => state.getReferralCodeReducer?.status);
    const getReferralCodeReducerLoading = useSelector((state) => state.getReferralCodeReducer?.loading);

    const referFriendReducerStatus = useSelector((state) => state.referFriendReducer?.status);
    const referFriendReducerLoading = useSelector((state) => state.referFriendReducer?.loading);
    const referFriendReducerMessage = useSelector((state) => state.referFriendReducer?.message);

    const fitcoinhistoryReduecerStatus = useSelector((state) => state.fitcoinhistoryReduecer?.status);
    const fitcoinhistoryReduecerLoading = useSelector((state) => state.fitcoinhistoryReduecer?.loading);
    const fitcoinhistoryReduecerData = useSelector((state) => state.fitcoinhistoryReduecer?.data);

    const CustomerProfileReducerData = useSelector((state) => state.customerProfileReducer?.data);

    console.log("utilReducers?.setName :", utilReducers?.setName)
    const handleSend = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\d{10}$/;
        if (emailPattern.test(referMail)) {
            dispatch(referFriend({
                "email": referMail,
                "referalCode": code,
                "referrerName": utilReducers?.setName !== "" ? utilReducers?.setName : ""
            }));
        } else if (phonePattern.test(referMail)) {
            dispatch(referFriend({
                "mobileNumber": {
                    "countryCode": "+91",
                    "number": referMail
                },
                "referalCode": code,
                "referrerName": utilReducers?.setName !== "" ? utilReducers?.setName : ""
            }))
        }
        else {
            Toast("Please enter a valid email or mobile number");
        }
    }

    useEffect(() => {
        const customerProfile = CustomerProfileReducerData?.[0];
        dispatch(getReferralCode({ userId: utilReducers.userId }));
        dispatch(fitcoinhistory({ userId: utilReducers.userId }))

        if ((!customerProfile?.mobileNumber?.number || !customerProfile?.email) && !hasShownProfileToast.current) {
            alert("Update your profile first!");
            hasShownProfileToast.current = true;
            return;
        }
    }, [CustomerProfileReducerData, utilReducers?.userId, dispatch])

    useEffect(() => {
        if (getReferralCodeReducerStatus == 200) {
            setCode(getReferralCodeReducerData);
            dispatch(getReferralCodeReset());
        } else if (getReferralCodeReducerStatus) {
            dispatch(getReferralCodeReset());
        }
    }, [getReferralCodeReducerStatus])

    useEffect(() => {
        if (referFriendReducerStatus == 200) {
            Toast(referFriendReducerMessage ? referFriendReducerMessage : "Refer to friend Successfully");
            setReferMail("")
            dispatch(referFriendReset());
        } else if (referFriendReducerStatus) {
            Toast(referFriendReducerMessage ? referFriendReducerMessage : "Refer friend error")
            dispatch(referFriendReset());
        }
    }, [referFriendReducerStatus])

    useEffect(() => {
        if (fitcoinhistoryReduecerStatus == 200) {
            dispatch(fitcoinhistoryReset())
        } else if (fitcoinhistoryReduecerStatus) {
            dispatch(fitcoinhistoryReset())
        }
    }, [fitcoinhistoryReduecerStatus])

    console.log("fitcoinhistoryReduecerData :", fitcoinhistoryReduecerData);

    return (
        <div className='bg-[#1E1E1E]'>
            {!getReferralCodeReducerLoading ?
                <div className="container-lg px-[4rem]">
                    <div className="w-full my-[2rem] flex justify-start items-end">
                        <div className="w-[60%] sm:w-[50%]">
                            <label htmlFor="" className="text-white text-[1.6rem] mx-2">Refer a Friend</label>
                            <div className="border border-white rounded-[1.5rem] bg-black px-[1rem] py-[1.3rem]">
                                <input
                                    type="text"
                                    className="w-full bg-black outline-none text-[1.6rem] text-white"
                                    placeholder="Refer a Friend"
                                    value={referMail}
                                    onChange={(e) => { setReferMail(e.target.value) }}
                                />
                            </div>
                        </div>
                        <button
                            className="rounded-[2.5rem] open-sans-semibold p-5 px-[3rem] text-[1.6rem] text-black bg-[#FFF200] ml-10"
                            onClick={() => handleSend()}
                        >
                            {!referFriendReducerLoading ?
                                "Submit"
                                :
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#000000"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            }
                        </button>
                    </div>
                    <div className="flex justify-start items-center font-[600] my-[2rem]">
                        <div className="text-white text-[1.6rem]">
                            CODE :
                        </div>
                        <div className="text-[#FFF200] text-[1.6rem] ml-3">
                            {code}
                        </div>
                    </div>
                    <div className='w-full h-full bg-black text-white rounded-[2rem] mt-[2rem] px-[4rem]'>
                        <h1 className="text-[3rem] font-bold my-5">My Referral</h1>
                    </div>
                    <table class="table-fixed w-full">
                        <thead>
                            <tr className=''>
                                <th className='text-[1.6rem] text-white py-[1rem]'>Title</th>
                                <th className='text-[1.6rem] text-white py-[1rem]'>Transaction Type</th>
                                <th className='text-[1.6rem] text-white py-[1rem]'>Date</th>
                                <th className='text-[1.6rem] text-white py-[1rem]'>Coins</th>
                            </tr>
                        </thead>
                        <tbody className='bg-[#1E1E1E]'>
                            {!fitcoinhistoryReduecerLoading ? fitcoinhistoryReduecerData?.length > 0 ?
                                fitcoinhistoryReduecerData?.filter((item) => { return item.transactionType == "redeem" }).length > 0 ?
                                    fitcoinhistoryReduecerData?.filter((item) => { return item.transactionType == "redeem" })?.map((items) => {
                                        return (
                                            <tr className='border-t-4 border-black bg-black my-[1rem]'>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                    {items?.eventSectionType}
                                                </td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                    {items?.transactionType}
                                                </td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                    {moment(items?.date).format("MMM Do YY")}
                                                </td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                    {items?.points}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr className="bg-black">
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className="text-[1.6rem] text-white py-[2rem] text-center w-full">No Data Available</td>
                                        <td className=""></td>
                                        <td className=""></td>
                                    </tr>
                                :
                                <tr className="bg-black">
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className="text-[1.6rem] text-white py-[2rem] text-center w-full">No Data Available</td>
                                    <td className=""></td>
                                    <td className=""></td>
                                </tr>
                                :
                                <tr className="bg-black">
                                    <td className=""></td>
                                    <td className=""></td>
                                    <td className="text-[1.6rem] text-white py-[2rem] text-center w-full">
                                        <Oval
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#FAFF00"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </td>
                                    <td className=""></td>
                                    <td className=""></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                :
                <div className="flex justify-center items-center w-full h-[50rem]">
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#FAFF00"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            }
        </div>
    );
}

export default ReferalsCoupons;
