import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_strapi_service, endpoints } from '../../constants';
import { AxiosStrapiService } from '../../services';
import { actionTypes } from '../../types';
import { strapiArticlesFailure, strapiArticlesSuccess } from '../../actions/StrapiServiceActions/strapiArticlesAction';

function* strapiArticles({ payload }) {
    try {
        const response = yield call(AxiosStrapiService, {
            method: 'get',
            base_url: base_url_strapi_service,
            endPoint: endpoints.StrapiArticles + `?category.slug=blog_and_news`,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_ARTICLES_SUCCESS response :", response);
        console.log("STRAPI_ARTICLES_SUCCESS status :", response?.status);
        console.log("STRAPI_ARTICLES_SUCCESS data :", response?.data);
        console.log("STRAPI_ARTICLES_SUCCESS message :", response?.message);
        console.log('====================================');
        yield put(
            strapiArticlesSuccess({
                status: response?.status,
                data: response?.data,
                message: response?.message
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_ARTICLES_FAILURE status :", error?.response?.status);
        console.log("STRAPI_ARTICLES_FAILURE data:", error?.response?.data);
        console.log("STRAPI_ARTICLES_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiArticlesFailure({
            status: error?.response?.status,
            message: error?.response?.message
        }));
    }
}

function* strapiArticlesSaga() {
    yield takeLatest(actionTypes.STRAPI_ARTICLES_REQUEST, strapiArticles);
}

export default strapiArticlesSaga;