import React from 'react'
import { PackageImgAndDetailsImg } from '../../../static/constants/imgConstants'
import { IoCheckmark } from "react-icons/io5";

const PackageImgAndDetails = ({ points, image }) => {

    const parsePoints = (htmlString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        const paragraphs = Array.from(tempDiv.getElementsByTagName('p')).map(p => p.innerHTML);
        return paragraphs;
    }

    const parsedPoints = parsePoints(points);

    return (
        <div className="w-full lg:w-[50%] flex flex-col justify-center items-center lg:block">
            <div className="h-[40.6rem] w-[50.8rem] rounded-[2.5rem] overflow-hidden">
                <img src={image ? image : PackageImgAndDetailsImg.PackageDetailsImg} alt="" className="h-full w-full" />
            </div>
            <div className="my-[2rem] text-start sm:text-center w-full">
                {parsedPoints.map((point, index) => (
                    <div key={index} className={`flex items-center my-5 urbanist-medium text-[#FFF6F6] text-[1.6rem]`}>
                        <IoCheckmark color='#FFF6F6' size={20} className='mr-4' />
                        <span dangerouslySetInnerHTML={{ __html: point }} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PackageImgAndDetails