import React from 'react'
import EstorePic from "../../../static/images/e-store-background2.png"
import "./Estore.css"

function Estore() {
    return (
        <div className='e-store px-[4rem]'>
            <div className="container-lg flex flex-col md:flex-row h-full overflow-hidden">
                <div className="mt-5 md:mt-0 flex flex-col justify-center items-start w-full sm:w-[84%] xl:w-[23%] h-full mx-auto md:mx-0">
                    <div className="bg-white inter-bold text-[3.6rem] rounded-[10px] p-5 px-7 mb-[2rem] xl:mb-[2.4rem]">
                        Best Buy
                    </div>
                    <div className="text-white outfit-regular text-[2rem] text-justify tracking-widest leading-[3.2rem] my-4">
                        Join us today and experience the transformative power of our fitness classes. Don't wait to start your fitness journey. Take the first step towards a healthier, stronger you. Let's sweat, have fun, and make fitness a way of life together!
                    </div>
                    <div className="text-white inter-bold text-[3.6rem] my-[2rem] xl:my-[2.4rem]">
                        Shop Now
                    </div>
                    <div className="text-[#ffffff]/90 montserrat-bold text-[6.5rem]">
                        Estore
                    </div>
                    <button className="open-sans-semibold text-[1.6rem] bg-[#FAFF00] p-2 rounded-[2.2rem] px-14 ml-3 my-[1.6rem] xl:my-[2rem]" onClick={() => window.open("https://shop.fit.zone/", '_blank', 'rel=noopener noreferrer')}>
                        BUY NOW
                    </button>
                </div>
                <div className="flex justify-center items-center overflow-hidden w-full md:w-[52%] lg:w-[75%] xl:w-[77%]">
                    <img className='w-full' src={EstorePic} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Estore
